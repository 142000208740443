// 3RD PARTY
import { useTranslation } from 'react-i18next';

// Sub Components
import PersonalInfoBlocks from '../personalInfoBlocks';

// Moment Library
import moment from 'moment';
import FORMATS from 'app/ui-core/utils/formats';
import { validateGender } from 'app/ui-core/utils/personalInfo';
import { GENDERS } from 'app/ui-core/utils/constants';
import useOnlyMobile from 'app/hooks/useOnlyMobile';

const PersonalInfoBlock = ({personalInfo, maritalStatuses}) => {
    const isOnlyMobile = useOnlyMobile();
    
    const { t } = useTranslation();

    const listGender = [
        { key: 1, label: t('formLabel.sexF'), value: GENDERS.female },
        { key: 2, label: t('formLabel.sexM'), value: GENDERS.male },
        { key: 3, label: t('formLabel.sexD'), value: GENDERS.unknown },
    ];

    const validateMaritalStatus = (maritalStatusUser: string) => {
        if(!maritalStatuses) return '';

        const englishData: string[] = maritalStatuses['en']

        if( t('register.lenguage') === 'en' ) return maritalStatusUser;

        const index = englishData?.findIndex(( value ) => ( value === maritalStatusUser ));

        return maritalStatuses['es']?.[index] ?? ''

    }

    return (
        <PersonalInfoBlocks infoBlock={{
            title: t('myAccount.personalDetails'),
            icon: 'fas fa-user-circle',
            iconTitle: t('imgNicons.userCircleIcon'),
            items: [
                {
                    label: t('formLabel.firstName')+':',
                    icon: `fas fa-user ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.userIcon'),
                    value: personalInfo?.firstName,
                    gray: isOnlyMobile
                },
                {
                    label: t('formLabel.lastName')+':',
                    icon: `fas fa-user ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.userIcon'),                    
                    value: personalInfo?.lastName,
                    gray: isOnlyMobile
                },
                {
                    label: t('formLabel.birthdate')+':',
                    icon: `fas fa-birthday-cake ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.birthdayCakeIcon'),
                    value: moment(personalInfo?.dateOfBirth).format(FORMATS.date),
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.sexAssigned')+':',
                    icon: `fas fa-transgender ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.femaleIcon'),
                    value: validateGender(personalInfo?.sex, listGender)?.label ?? '' ,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.maritalStatus')+':',
                    icon: `fas fa-rings-wedding ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.femaleIcon'),
                    value: validateMaritalStatus(personalInfo?.maritalStatus),
                    gray: isOnlyMobile
                },                                
                {
                    label: t('myAccount.email')+':',
                    icon: `fas fa-envelope ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.email'),
                    value: personalInfo?.email,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.homePhone')+':',
                    icon: `fas fa-phone-rotary ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.rotaryPhoneIcon'),
                    value: personalInfo?.homePhone,
                    gray: isOnlyMobile
                },                
                {
                    label: t('myAccount.mobilePhone')+':',
                    icon: `fas fa-mobile-alt ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.email'),
                    value: personalInfo?.mobile,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.address')+':',
                    icon: `fas fa-map-marker-alt ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.market'),
                    value: personalInfo?.address1,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.zip')+':',
                    icon: `fas fa-map-marked-alt ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.market'),
                    value: personalInfo?.zipCode,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.city')+':',
                    icon: `fas fa-city ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.cityIcon'),
                    value: personalInfo?.city,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.state')+':',
                    icon: `fas fa-flag-usa ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.flagUSAIcon'),
                    value: personalInfo?.state,
                    gray: isOnlyMobile
                },
            ]
        }} />
    )
}

export default PersonalInfoBlock;