import { useTranslation } from 'react-i18next';
import Anxiety from '../../../assets/icons/Anxiety.svg';
import stinking from '../../../assets/icons/stinking.svg';
import ask from '../../../assets/icons/ask.svg';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Container } from '@mui/material';
import styled from 'app/ui-core/styled-components';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import { CenteredWrapper, Column, Loader, Row } from 'app/components/templates';
import { Image, KeraltyButton, KeraltyText } from 'app/components/atoms';
import { useAppSelector } from 'adapter/hooks';
import chatSelectors from 'adapter/chat/chatSelectors';
import { SectionWidget } from 'app/components/molecules';
import theme from 'app/ui-core/utils/theme';
import { ActionLink, IconLinkRow } from 'app/components/templates/iconLinkRow';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { KeraltyModal } from 'app/components/molecules';
import { useMemo, useState } from 'react';
import ROUTES from 'app/ui-core/utils/routes';
import AppointmentsFormFHS from './AppointmentsFormFHS';
import { AppointmentsFHS } from './AppointmentsFHS';
import i18n from 'app/ui-core/i18n';
import StinkingThinking from './StinkingThinking';
import useIsMobile from 'app/hooks/useIsMobile';

const { MAIN, MENTAL_HEALTH: CURRENT, MENTAL_HEALTH } = ROUTES;

const RightContent = styled(Row)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 0;
  }
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 5vh;
    padding-inline: 15px;
  }
`;

const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
  }
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  height: auto;
  width: 28.5rem;
  margin: 3.8366rem 1.455rem;
  border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;
  align-items: flex-start;
  padding-top: 32px !important;
  display: flex;
  h2 {
    margin: 0rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
  }
`;

const OptionColumn = styled(Column)`
  padding-right: 2px;
  padding-left: 2px;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const CardsRow = styled(Row)`
  justify-content: center;
  padding: 0rem;
`;


const SelfManagement = () => {

    const { t } = useTranslation();
    const isLoading = useAppSelector(chatSelectors.selectIsLoading);
    const { goBack, push } = useHistory();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { subTab, subSubTab } = useParams();
    const lang = i18n.language?.includes('en') ? 'en' : 'es';
    const isMobile = useIsMobile();

    const goToAnxiety = () => {
      push(`/${MAIN.mentalHealth}/${MENTAL_HEALTH.anxiety}`);
    };

    const goToStinking = () => {
       push(`/${MAIN.mentalHealth}/${CURRENT.selfmanagement}/${CURRENT.stinkingThinking}`);
    };

    const title = useMemo(
      () =>
        t(
          `${!subTab
            ? t('mentalHealth.selfmanagement')
            : ''
          }`
        ),
      [t, subTab]
    );


    return (
        <>
          {isLoading && <Loader />}
    
          <RightContent
            fullHeight
            style={{
              margin:
                !subTab || (subTab === CURRENT.appointmentsFHS && subSubTab === undefined)
                  ? '1rem 0 5rem 0'
                  : '0 0 5rem 0',
            }}
          >
            <div style={{ marginLeft: '2%', width: '100%' }}>
        <IconLinkRow
          onClick={goBack}
          color="accent"
          icon={arrowAltCircleLeftIcon}
          iconAlt={t('imgNicons.backArrow')}
          text={t('routes.back')}
        />
      </div>
            

        {title && (
          <KeraltyText type="title" align={ isMobile ? 'left' : 'center'} fontWeight={ isMobile ? '600' : '400'}>
            {title}
          </KeraltyText>
        )}
            
            
            {!subTab && (
              <>
                <CardsRow noMargin>
                  <Column lg={0} md={0} sm={12}>
                    <CenteredWrapper>
                      <Card onClick={goToAnxiety}>
                        <SectionWidget
                          img={Anxiety}
                          text={t('mentalHealth.anxiety')}
                          description={t('mentalHealth.anxietyText')}
                          textColor="#747474"
                          descriptionFontSize="12px"
                        />
                      </Card>
                    </CenteredWrapper>
                  </Column>
                  <OptionColumn lg={6} md={6} sm={0}>
                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <GetCareNowButton
                        color="light"
                        squareRadius={true}
                        onClick={goToAnxiety}
                        style={{ minHeight:  '16.75rem' }}
                      >
                        <span>
                          <Image
                            src={Anxiety}
                            width={theme.sizes.icon.extraBig}
                            height={theme.sizes.icon.extraBig}
                          />
                          <KeraltyText
                            type="title"
                            fontWeight={'400'}
                            style={{ fontWeight: 'normal', fontSize: '16px' }}
                            noTextTransform
                          >
                            {t('mentalHealth.anxiety')}
                          </KeraltyText>
                          <KeraltyText
                            type="paragraph2" fontWeight={'600'}
                            color={'#747474'}
                            style={{
                              color: '#747474',
                              fontSize: '12px',
                              lineHeight: '18px',
                              fontWeight: '400',
                            }}
                          >
                            {t('mentalHealth.anxietyText')}
                          </KeraltyText>
                        </span>
                      </GetCareNowButton>
                    </Row>
                  </OptionColumn>
    
                  <Column lg={0} md={0} sm={12} style={{ marginTop: 15, marginBottom: 20 }}>
                    <CenteredWrapper>
                      <Card onClick={goToStinking}>
                        <SectionWidget
                          img={stinking}
                          text={t('mentalHealth.stinkingCard')}
                          description={t('mentalHealth.stinkingCarTex')}
                          textColor="#747474"
                          descriptionFontSize="12px"
                        />
                      </Card>
                    </CenteredWrapper>
                  </Column>
                  <OptionColumn lg={6} md={6} sm={0}>
                    <Row>
                      <GetCareNowButton
                        color="light"
                        squareRadius={true}
                        onClick={goToStinking}
                        style={{ minHeight: '16.75rem' }}
                      >
                        <span>
                          <Image
                            src={stinking}
                            width={theme.sizes.icon.extraBig}
                            height={theme.sizes.icon.extraBig}
                          />
                          <KeraltyText
                            type="title"
                            fontWeight={'400'}
                            style={{ fontWeight: 'normal', fontSize: '16px' }}
                            noTextTransform
                          >
                            {t('mentalHealth.stinkingCard')}
                          </KeraltyText>
                          <KeraltyText
                            type="paragraph2" fontWeight={'600'}
                            color={'#747474'}
                            style={{
                              color: '#747474',
                              fontSize: '12px',
                              lineHeight: '18px',
                              fontWeight: '400',
                            }}
                          >
                            {t('mentalHealth.stinkingCarTex')}
                          </KeraltyText>
                        </span>
                      </GetCareNowButton>
                    </Row>
                  </OptionColumn>
                </CardsRow>
              </>
            )}
    
            {subTab === CURRENT.appointmentsFHS && subSubTab === undefined && <AppointmentsFHS />}
            {subTab === CURRENT.appointmentsFHS && subSubTab === CURRENT.appointmentsFormFHS && (
              <AppointmentsFormFHS />
            )}
            {subTab === CURRENT.stinkingThinking && subSubTab === undefined && <StinkingThinking />}
          </RightContent>
        </>
      );

}

export default SelfManagement;