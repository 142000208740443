import { useEffect, useState } from 'react'
import i18n from "i18next";
import { useTranslation } from 'react-i18next';

import appConfig from 'config';
import { convertTo12HourFormat, TimeString, validateDateByRange } from 'app/ui-core/utils/dates';
import { useAppSelector } from 'adapter/hooks';
import selectSystemSelectors from 'adapter/system/system.selector';
import useAuth from './useAuth';
import { DAYS_OF_WEEK } from 'app/ui-core/utils/constants';

const useGetCareNow = () => {

	const { currentServerDate } = useAppSelector(selectSystemSelectors.systemInfo);
	const { user } = useAuth();
	const { t } = useTranslation();

	const [enableVideoCall, setEnableVideoCall] = useState(true)

	const isEnableVideoCall = () => {
		const config = appConfig.CHAT_WITH_DOCTOR_HOUR;

		if(!config[user.state]) return true;

		return validateDateByRange({
			date: currentServerDate,
			dayRange: config[user.state].DAYS,
			hourRange: { minHour: config[user.state].INIT as TimeString, maxHour: config[user.state].END as TimeString }
		});
	};

	const setVideoCallText = (text: string): string => {
		const config = appConfig.CHAT_WITH_DOCTOR_HOUR[user.state];

		if(!config) return text;

		const days = (config.DAYS as string[]).map( day => DAYS_OF_WEEK[day] ).sort((a, b) => a - b);
		return text
			.replace("[days]", `${t(`formLabel.day${days[0]}`)} ${ i18n.language.includes('es') ? "a" : "thru" } ${t(`formLabel.day${days[ days.length - 1 ]}`)}`)
			.replace("[hours]", `${ convertTo12HourFormat(config.INIT) } (CT) ${ i18n.language.includes('es') ? "a" : "-" } ${ convertTo12HourFormat(config.END) } (CT)`);
	};

	useEffect(() => {

		const response = isEnableVideoCall();

		setEnableVideoCall(response)

	}, [currentServerDate])


  return {
	enableVideoCall,
	isEnableVideoCall,
	setVideoCallText
  }
}

export default useGetCareNow
