import AnxietySteps from "./components/AnxietySteps";
import TitleSection from "./components/TitleSection";
import { ReactComponent as physicalSymptomsIcon } from '../../../../assets/icons/pysical-symptoms.svg';
import { useTranslation } from "react-i18next";
import Chip from "./components/Chip";
import { KeraltyButton } from "app/components/atoms";

import { useHistory } from 'react-router-dom';
import ROUTES from "app/ui-core/utils/routes";
import { useEffect, useState } from "react";
import { useAnxiety } from "./hooks/useAnxiety";
import { AnxietyParameter, TypeAnxietyParameter } from "infrastructure/keraltyApi/models/mentalHealth";
import i18n from 'i18next';
import { Loader } from "app/components/templates";
import { useAppSelector } from "adapter/hooks";
import { idEcw } from "adapter/user/userSlice";
import MentalHealth from "infrastructure/keraltyApi/repositories/mentalHealth";
import { CustomSection } from "./Anxiety.styled";

const PhysicalSymptomsOptions = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const [physicalSymptoms, setPhysicalSymptoms] = useState<AnxietyParameter[]>([]);
  const { register, saveInfoInStore, setIsLoading, isLoading, getAnxietyParametersByType } = useAnxiety();
  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);
  const { authUid } = useAppSelector(state => state.user);
  const patientId = useAppSelector(idEcw);

	const { MENTAL_HEALTH } = ROUTES;

  const getData = async () => {
    setIsLoading(true)
    try{
      const data = await getAnxietyParametersByType(TypeAnxietyParameter.PHYSICAL_SYMPTOMS);
      setPhysicalSymptoms(data);
      setIsLoading(false);
    }catch(error){
      console.error(error);
      setIsLoading(false);
    }
  }

  const valueSelected = (text: string): void => {

    const valueFound = optionsSelected.find(option => option === text);

    if(!valueFound){
      setOptionsSelected([...optionsSelected, text]);
      return
    }

    const newOptions = optionsSelected.filter(option => option !== text);
    setOptionsSelected(newOptions);

  }

  const nextStep = async () => {
    if(optionsSelected.length === 0){
      return
    }

    const physicalSymptomsList = optionsSelected.reduce((accumulator, currentValue) => `${accumulator} ${ accumulator === '' ? accumulator : '|' } ${currentValue}`, '');

    const payload = {
      ...register,
      physicalSymptoms: physicalSymptomsList.trim(),
      authUid,
      patientId
    }

    setIsLoading(true)

    try {
      await MentalHealth.saveEntry(payload);
      setIsLoading(false);
      history.replace(`${MENTAL_HEALTH.cbt}`)
    }catch(error) {
      console.error(error);
      setIsLoading(false);
    }

  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <>
      {
        isLoading
        ? <Loader />
        : (
            <AnxietySteps>
              <div style={{ display: 'grid', placeItems: 'center', marginBottom: 20 }}>
                <KeraltyButton fontWeight={ '600' } fontSize="14px" width="27rem" withShadow withoutTextTransform>
                  { register.thinking }
                </KeraltyButton>
              </div>
              <TitleSection
                icon={physicalSymptomsIcon}
                title={t('mentalHealth.physicalSymptoms')}
                subtitle={t('mentalHealth.physicalSymptomsText')}
              />
                <CustomSection responsiveColums={2}>
                  {
                    physicalSymptoms.map( (item, index) => (
                        <div key={index} style={{ width: 'auto' }}>
                          <Chip
                            text={ i18n.language === 'es' ? item.contentEs : item.contentEn }
                            valueSelected={ valueSelected }
                          />
                        </div>
                      ))
                  }
                </CustomSection>
              <div style={{ marginTop: 70, marginBottom: 50, display: 'grid', placeItems: 'center' }}>
                  <KeraltyButton
                    onClick={nextStep}
                    fontWeight={ '600' }
                    withShadow
                    disabledColor="#00000040"
                    disabled={optionsSelected.length === 0 ? true : false }
                  >
                    {t('mentalHealth.submit')}
                  </KeraltyButton>
              </div>
            </AnxietySteps>
        )
      }
    </>
  )
}

export default PhysicalSymptomsOptions;
