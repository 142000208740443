import { useState, useEffect, useMemo, useCallback } from 'react';

// 3RD PARTY
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';

//Actions
import { getEmergencyContactData, getPersonalData, cleanError, getMaritalStatus, getPatientInfo } from '../../../../adapter/myAccount/myAccountSlice';
import { selectmyAccountEmergencyContactInfo, selectmyAccountPersonalInfo, selectmyAccountLoading } from '../../../../adapter/myAccount/myAccountSelector';

// UI
import { KeraltyText, Divider } from '../../atoms';
import { Container, Column, Row, Loader, IconLinkRow } from '../../templates';
import styled from '../../../ui-core/styled-components';
import PersonalInfoBlock from './personalInfoBlocks/personalInfoBlock';
import EmergencyContactBlock from './personalInfoBlocks/emergencyContactBlock';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { useHistory } from 'react-router-dom';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import ROUTES from '../../../ui-core/utils/routes';
import GoBack from '../../../assets/icons/goBack.svg';
import { isEmpty } from 'lodash';
import useOnlyMobile from 'app/hooks/useOnlyMobile';

const { MY_ACCOUNT, MAIN } = ROUTES;


const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  align-items: flex-start;
  background: transparent;
  height: 105%;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    height: auto;
  }
`;

const TitleContiner = styled(Column)`
  h2 {
    text-align: center;
  }
`;

const DeleteAccount = styled(Row)`
  margin-left: 200px;
  margin-left: 15%;
  margin-bottom:65px;
  margin-top:0px;
  flex-direction: column;
`;

const BlockInfoContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
  align-self: 'center';
  max-width: 90%;
`;

const CardsMobile = styled.div`
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    padding: 18px 48px;
    background-color: ${(props) => props.theme.colors.light};
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(1, 42, 88, 0.25);

    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;


const PersonalInfoTab = () => {
  const { t } = useTranslation();
  const { goBack, location } = useHistory();
  const history = useHistory();
  const { user } = useAuth();
  const personalInfo = useAppSelector(selectmyAccountPersonalInfo);
  const emergencyContactInfo = useAppSelector(selectmyAccountEmergencyContactInfo);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectmyAccountLoading);
  const [valuePatientInfo, setValuePatientInfo] = useState<any>();
  const [ValueMaritalStatus, setValueMaritalStatus] = useState<any>([]);
  const isOnlyMobile = useOnlyMobile();

  useEffect(() => {
    getDataPatientInfo()
    getDataMaritalStatus()
  }, []);

  const getDataMaritalStatus = async () => {
    try {
      let y = await dispatch(getMaritalStatus(user))
      setValueMaritalStatus(y.payload);
    } catch (error) {
    }
  }

  useEffect(() => {
    if (user.firstName) {
      user.idEcw && dispatch(getPersonalData(user));
      user.idEcw && dispatch(getEmergencyContactData(user.idEcw));
      return () => dispatch(cleanError());
    }
  }, [dispatch, user]);

  const getDataPatientInfo = async () => {
    try {
      let y = await dispatch(getPatientInfo({ 'authUid': user.authUid, 'state': user?.state }))
      setValuePatientInfo(y.payload)
    } catch (error) {
    }
  }

  const goDelete = (route) => {
    history.replace(route)
    // window.location.href = route
  };

  return (
    <RightContent noMargin={false} bg="light">
      <div style={{ marginLeft: isOnlyMobile ? '5vw' : '3vw', display: 'block' }}>
        <IconLinkRow
          onClick={goBack}
          color="accent"
          icon={GoBack}
          iconSize={1.5}
          iconAlt={t('imgNicons.backArrow')}
          text={t('routes.back')}
        />

        <Row>
          <TitleContiner lg={12}>
            <KeraltyText type={'title'} align={'left'}>
              {t('myAccount.personalInfo')}
            </KeraltyText>
          </TitleContiner>
        </Row>
      </div>
      <Container centered={'x'} noPadding>
        {isLoading && <Loader />}
        {!isLoading && (
          <BlockInfoContainer>
            {/* Personal Info Block */}
            <CardsMobile>
              <PersonalInfoBlock
                personalInfo={valuePatientInfo}
                maritalStatuses={ValueMaritalStatus}
              />
            </CardsMobile>
            {/* Emergency Contact Block */}
            {!isOnlyMobile && !isEmpty(emergencyContactInfo) && <Divider my={2} />}
            {!isEmpty(emergencyContactInfo) && <CardsMobile style={{ marginTop: isOnlyMobile ? 43 : 0 }}>
              <EmergencyContactBlock emergencyContactInfo={emergencyContactInfo} />
            </CardsMobile>}
          </BlockInfoContainer>
        )}
      </Container>

      <Row style={{ marginLeft: '-1%' }}>
        <DeleteAccount>
          <div style={{}}>
            <button
              style={{ color: '#055293', textDecoration: 'underline', fontSize: '16px', fontWeight: '600', border: 'none', padding: 0, background: 'none', fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif', cursor: 'pointer' }}
              type="button"
              onClick={(e) => {
                history.push({
                  pathname: location.pathname === '/my-account/personal-information' ?
                    `${MY_ACCOUNT.editAccount}` : `${MAIN.myAccount}/${MY_ACCOUNT.editAccount}`,
                  state: { 'data': valuePatientInfo, 'marital': ValueMaritalStatus }
                });
              }}
            >
              {t('myAccount.editAccount')}
            </button>
          </div>

          <button
            style={{ color: '#B50303', marginTop: '2%', textDecoration: 'underline', fontSize: '16px', fontWeight: '600', border: 'none', padding: 0, background: 'none', fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif', cursor: 'pointer' }}
            type="button"
            onClick={(e) => {
              if (location.pathname === '/my-account/personal-information') {
                goDelete(`${MY_ACCOUNT.deleteAccount}`);
              } else {
                goDelete(`${MAIN.myAccount}/${MY_ACCOUNT.deleteAccount}`);
              }
            }}
          >
            {t('myAccount.firstDelete')}
          </button>
        </DeleteAccount>
      </Row>
    </RightContent>
  );
};

export default PersonalInfoTab;
