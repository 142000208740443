import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';


import { Row, Column, IconLinkRow } from '../../templates';
import { KeraltyText } from '../../atoms';

import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';

import ROUTES from '../../../ui-core/utils/routes';

import { useAppSelector } from 'adapter/hooks';
import useAuth from '../../../hooks/useAuth';
import useIsMobile from '../../../hooks/useIsMobile'

import i18n from "i18next";
import ModalOnboarding from './ModalOnboarding';
import { useState } from 'react';
import theme from 'app/ui-core/utils/theme';

const { MAIN } = ROUTES;

const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 5vh;
		padding-inline: 10px;
	};
`;

const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
		margin-bottom: 0rem;
		paddingLeft: -2%
	}
`;


const CustomRow = styled(Row)`
	& > ${Column} {
		margin-top: 1rem ;
		margin-bottom: .5rem !important;
		margin-left:2.5rem ;

		margin-right:2.5rem ;
		@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			margin-left:1rem ;

		};
	}
`;
const styleText = {
	fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
	fontSize: '16px',
	color: theme.colors.dark,
	lineHeight: '1.58rem'
}







const AboutPage = () => {
	const { t } = useTranslation();
	const { goBack, push } = useHistory();
	const { user } = useAuth();
	const chatState = useAppSelector((state) => state.chat.symptomChecker);
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const isMobile = useIsMobile();
	const { authUid } = useAppSelector(state => state.user)
	const [showOnBoard, sethowOnBoard] = useState(false)


	const styleText2 = {
		fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
		fontSize: '16px',
		color: theme.colors.dark,
		fontWeight: 700
	}

	const isEnglish = () => {
		if (i18n.language.includes('en')) return true;
		else return false
	}


	const handleMain = () => {
		push(`/${MAIN.mentalHealth}`);
	};

	const AboutText = () => {
		const { t } = useTranslation();
		return (
			<CustomRow >
				{/* TITLE */}
				<Column lg={12}>
					<KeraltyText type="link" bold pb style={{ fontSize: 16 }} onClick={() => sethowOnBoard(true)} >
						{t('mentalHealth.revisit')}
					</KeraltyText>
				</Column>
				<Column lg={12}>
					<KeraltyText type="subTitle" bold pb>
						{t('mentalHealth.new')}
					</KeraltyText>
				</Column>
				<Column lg={12}>
					<KeraltyText align="left" type="info" color={'dark'}>
						{t('mentalHealth.welcome')}
					</KeraltyText>
				</Column>

				<Column lg={12}>
					<KeraltyText align="left" type="info" color={'dark'}>
						<text style={styleText} >
							{t('mentalHealth.our')}

							<text style={isEnglish() ? styleText2 : styleText}>
								{t('mentalHealth.our2')}
							</text>

							{' '}{t('mentalHealth.our3')}
						</text>
					</KeraltyText>
				</Column>
				<Column lg={12}>
					<KeraltyText align="left" type="info" color={'dark'}>
						{t('mentalHealth.thank')}
					</KeraltyText>
				</Column>

			</CustomRow>
		);
	};



	return (
		<RightContent fullHeight>
			{showOnBoard &&
				<ModalOnboarding
					skip={() => { sethowOnBoard(false); handleMain() }}
					start={() => { sethowOnBoard(false); handleMain() }}
				/>
			}
			<div style={{ marginLeft:!isMobile? '1%':'3%', width: '100%' }}>
			<IconLinkRow
				onClick={goBack}
				color="accent"
				icon={arrowAltCircleLeftIcon}
				iconAlt={t('imgNicons.backArrow')}
				text={t('routes.back')}
			/>
			</div>
			<Row>
				<TitleContiner lg={12}>
					<KeraltyText type="title">{t('mentalHealth.about')}</KeraltyText>
				</TitleContiner>
			</Row>
			<Row>
				<AboutText />
			</Row>

		</RightContent>
	);
};

export default AboutPage;
