import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';
import Loader from '../../templates/loader';
import { Row, Column, IconLinkRow, Card, SpaceWrapper, Container } from '../../templates';
import { KeraltyButton, KeraltyText, Image, KeraltyIcon } from '../../atoms';
import { KeraltyModal } from 'app/components/molecules';
import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { useEffect, useState } from 'react';
import MentalHealth from 'infrastructure/keraltyApi/repositories/mentalHealth';
import { ReactComponent as VideoIcon } from '../../../assets/icons/ER_Videoicon.svg';
import { ReactComponent as AudioIcon } from '../../../assets/icons/ER_AudioIcon.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/ER_ImageIcon.svg';
import { ReactComponent as PdfIcon } from '../../../assets/icons/ER_PDFIcon.svg';
import { ReactComponent as Ray } from '../../../assets/icons/ray.svg';


const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		// margin-top: 5vh;
		padding-inline: 10px;
	};
`;

const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
		margin-bottom: 0rem;
		paddingLeft: -2%
	}
`;

const CustomRow = styled(Row)`
	& > ${Column} {
		margin-top: 1rem ;
		margin-bottom: .5rem !important;
		margin-left:2.5rem ;

		margin-right:2.5rem ;
		@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			margin-left:1rem ;

		};
	}
`;

const LeftColumn = styled(Column)`
  justify-content: flex-end;
  padding: 0rem;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    justify-content: center;
  }
`;

const RightColumn = styled(Column)`
  justify-content: flex-start;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    justify-content: center;
  }
`;

const StyledCard = styled(Card)`
	cursor: auto;
  flex-wrap: wrap;
//   width: calc(100% - 9rem);
	width: 315px;
	min-height: 148px;

  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    // width: calc(100% - 3.6rem);
	width: 100%;
  }
`;

const Icon = styled(Image)`
  margin-right: 1.5rem;
  margin-bottom: 20px;
`;

const LabelText = styled(KeraltyText)`
//   margin-left: 0.5rem;
  padding-top: 6px;
  overflow: hidden;
//   min-height: 50px;
  max-height: 50px;
  text-overflow: ellipsis;
  word-break: break-word;
`;


const DownloadButton = styled(Column)`
	// letter-spacing: 1px;
	display: block !important;
  	background: #3CA70D;
	border-radius: 29px;
	color: #FFFFFF;
	width: 156px;
	height: 35.49px;
	text-align: center !important;
	cursor: pointer;
	font-weight: 700;
	font-size: 16px;
	line-height: 35px;
	// @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	// 	width: 286px;
	// };
	// @media (max-width: 433px) {
	// 	width: 230px;
	// };
	// @media (max-width: 803px){
	// 	width: 240px;
	// 	margin: 10px
	// };
`;

const ContainModal = styled(Column)`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  overflow: auto;
  z-index: 99;
  background-color: #00000058;
  width: 100%;
  padding: 0;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

	}
`;
const Modal = styled(Column)<{isMobile: boolean}>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 10px;
  width: ${({ isMobile }) => isMobile ? '80%' : 'fit-content'};
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 	0%;
  margin-top: ${({ isMobile }) => isMobile ? '60%' : '15%'};
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

	}
`;

const ContainerCancelButton = styled(Column)`
	display: flex;
	text-align: center;
	justify-content: center;
`;
const ContainerFilterButton = styled(Column)`
	display: flex;
	text-align: center;
	justify-content: center;
	align-content: center;
	margin: 0;
`;


const Button = styled(KeraltyButton)`
	letter-spacing: 1px;
	align-self: center;
	margin-top: 10px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		padding: 0px;
	}
`;

const IMG_TYPES = ['png', 'jpg', 'jpeg', 'heic']
const VIDEO_TYPES = ['mp4', 'mpeg', 'mpg', 'heif']
const AUDIO_TYPES = ['mp3', 'wmv', 'wav', 'aac']
const DOC_TYPES = ['pdf']

const OtherResources = () => {

	const { t } = useTranslation();
    const { goBack, push } = useHistory();
    const [eduresoucesData, setEduresoucesData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
	const isMobile = useIsMobile();
	const [isLoading, setIsLoading] = useState(false);
	const [openPopUp, setOpenPopUp] = useState(false);
	const [itemToDownload, setItemToDownload] = useState<any>();
	const [modal, setModal] = useState(false);
	const [showDisclaimer, setshowDisclaimer] = useState(true);


    useEffect(() => {
        getEduResouces();
      }, []);

    const getEduResouces = async () => {
        return await MentalHealth.getAllBaseInfo()
        .then((result) => {
            setEduresoucesData(result);
        })
        .finally(() => setLoading(false));
    };

	const getIcon = ( type: any) => {
		if( IMG_TYPES.includes(type) ) return <ImageIcon style={{ marginTop: 10 }} />;
		if( VIDEO_TYPES.includes(type) ) return <VideoIcon style={{ marginLeft: 5 }}/>;
		if( AUDIO_TYPES.includes(type) ) return <AudioIcon style={{ marginLeft: 5 }} />;
		if( DOC_TYPES.includes(type) ) return <PdfIcon style={{ marginLeft: 5 }} />;
	}

	const downloadFile = (item) => {
		MentalHealth.downloadResourse(item.uuid)
			.then((res) => {
				if (res) {
					const linkSource = 'data:application/' + item.fileType + `;base64,${res}`;
					let downloadLink = document.createElement("a");
					downloadLink.href = linkSource;
					downloadLink.download = item.name;
					downloadLink.click();
					setIsLoading(false)
				}
			}
			).catch((e) => {
				setOpenPopUp(true)
			})
			;
	}

	const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
		contentType = contentType || '';

		const byteCharacters = atob(b64Data);
		let byteArrays: any = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			let byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			let byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	const DownloadPdfModal = () => (
		<ContainModal>
			<Modal isMobile={isMobile}>
				{isMobile
					? (
						<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px'  }}>
							<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
								{t('myHealth.saved')}
							</KeraltyText>
						</div>
					)
					: (
						<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' , width:'60%' }}>
							<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
								{t('myHealth.saved')}
							</KeraltyText>
						</div>
					)
				}
				<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
					<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px'  fontFamily='Nova' width='20px' >
						{t('myHealth.want')}
					</KeraltyText>
				</div>
          		{isMobile
					? (
						<Row>
							<ContainerFilterButton lg={12} md={6} sm={6} >
								<Button smFull type={'submit'}
									onClick={() => {
										downloadFile(itemToDownload);
										setModal(false);
										setIsLoading(true)
									}}
								>
									{t('myHealth.yes')}
								</Button>
							</ContainerFilterButton>

							<ContainerCancelButton  lg={0} md={6} sm={6}>
								<Button
									outline
									smFull
									type={'text'}
									onClick={() => {
										setModal(false);
									}}
								>
									{t('myHealth.no')}
								</Button>
							</ContainerCancelButton>
						</Row>
					)
            		: (
					 	<div style={{ display: 'grid', placeItems: 'center' }}>
							<Row>
								<ContainerFilterButton>
									<Button smFull type={'submit'}
										onClick={() => {
											downloadFile(itemToDownload);
											setModal(false)
											setIsLoading(true)
										}}
									>
										{t('myHealth.yes')}
									</Button>
								</ContainerFilterButton>

								<ContainerCancelButton>
									<Button
										outline
										smFull
										type={'text'}
										onClick={() => {
											setModal(false)
										}}
									>
										{t('myHealth.no')}
									</Button>
								</ContainerCancelButton>
							</Row>
						</div>
					)
				}
            </Modal>
     	</ContainModal>
	);

	const PopupError = () => {
		return (
			<div>
				<KeraltyModal size="extraLong"  >
					<Row noMargin>
						<SpaceWrapper mb={2} mt={3}>
							<Container centered={'x'} noPadding noMargin>
								<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova' style={{lineHeight: 1.1}}>{t('popupPdhError')}</KeraltyText>
							</Container>
						</SpaceWrapper>
					</Row>
					<Row noMargin style={{display:'flex', justifyContent:'center', marginBottom: 6}}>
						<ContainerFilterButton sm={4}>
							<Button smFull type={'submit'}
								onClick={(e) => {
									e.preventDefault();
									setOpenPopUp(false);
								}}
							>
								{t('ok')}
							</Button>
						</ContainerFilterButton>
					</Row>
				</KeraltyModal>
			</div>
		);
	}

	const getName = (name: string, fileType: string) => name.replaceAll(`.${fileType}`, '');

    return (
		<>
			{ modal && <DownloadPdfModal /> }
			{ openPopUp ? <PopupError /> : null }
			{isLoading && <Loader />}
			<RightContent fullHeight noMargin >
				<IconLinkRow
					onClick={goBack}
					color="accent"
					icon={arrowAltCircleLeftIcon}
					iconAlt={t('imgNicons.backArrow')}
					text={t('routes.back')}
				/>
				<Row>
					<TitleContiner lg={12}>
						<KeraltyText type="title" align={isMobile ? 'left' : 'center'} >{t('mentalHealth.otherResources')}</KeraltyText>
					</TitleContiner>
				</Row>
				<Column lg={12}>
					<KeraltyText type="subTitle" bold pb>
						{t('mentalHealth.pDFsandEbooks')}
					</KeraltyText>
				</Column>
				<Row>
					{
						eduresoucesData.length < 1 ? (<></>) : (
							eduresoucesData.map((item: any, index: number) => {
								const Wrapper = index % 2 ? RightColumn : LeftColumn;
								return (
									// <Wrapper lg={6} md={6} sm={12} noPadding>
										<StyledCard key={index}>
											<Row style={{ width:'35%', alignContent: 'start', marginTop: 30 }}>
												{getIcon(item.fileType)}
											</Row>
											<Row style={{ width:'65%', alignContent: 'center' }}>
												<LabelText color="#055293" type="info" fontSize="18px" align="left" bold style={{ paddingBottom: '10px'}}>
													{getName(item.name, item.fileType)}
												</LabelText>
												<div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gridGap: 5 }}>
													<div><Ray /></div>
													<div>
														<LabelText color="#5B5C5B" type="info" fontSize="14px">{item.description}</LabelText>
													</div>
												</div>
												<div style={{ justifyContent: 'center' , width: '100%', paddingTop: '10px' }} >
													<DownloadButton
														onClick={() => {
															setModal(true);
															setItemToDownload(item);
														}}
													>
														<span style={{marginTop: 30}}>
															{t('mentalHealth.download')}
														</span>
													</DownloadButton>
												</div>
											</Row>
										</StyledCard>
									// </Wrapper>
								) ;
							})
						)
					}
				</Row>
			</RightContent>
		</>
	);




}


export default OtherResources;
