import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { KeraltyText, KeraltyIcon } from '../atoms';
import { SpaceWrapper } from '../templates';

import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { closeSymptomChecker, openSymptomChecker, openVirtualAssistant, openHealtLibrary } from 'adapter/chat/chatSlice';
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';


import useAuth from '../../hooks/useAuth';
import useIsMobile from 'app/hooks/useIsMobile';

import styled from '../../ui-core/styled-components';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/es';
import { useTranslation } from 'react-i18next';
interface KeraltyLanguageSwitcherProps {
  activeHideButtons?: boolean;
  hideButtons?: boolean;
}

const MainContainer = styled(KeraltyText)`
  font-size: 1.33rem;
  line-height: 1.62rem;
  display: flex;

  & > span {
    margin-right: 0.2rem;
  }
`;

const Text = styled(KeraltyText)`
  font-size: 1.33rem;
  line-height: 1.62rem;
  & > span {
    margin-right: 0.2rem;
  }
`;

const ClickeableText = styled(KeraltyText)`
  font-size: 1.33rem;
  line-height: 1.62rem;
  cursor: pointer;

  & > span {
    margin-right: 0.2rem;
  }
`;

const GlobeIcon = styled(KeraltyIcon)<KeraltyLanguageSwitcherProps>`
  ${({ activeHideButtons }) => `
        &:hover {
            ${activeHideButtons ? 'cursor: pointer;' : ''}
        }`}
`;

const KeraltyLanguageSwitcher = ({
  activeHideButtons,
  hideButtons,
}: KeraltyLanguageSwitcherProps) => {
  const [lang, setLang] = useState('');
  const [showButtons, setShowButtons] = useState(hideButtons ? false : true);
  const { user } = useAuth();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const langList: string[] = ['en', 'es'];
  const dispatch = useAppDispatch();
  const symptomCheckState = useAppSelector((state) => state.chat.symptomChecker);
  const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
  const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { sanitasState, sanitasProps } = sanitasChatState;
  const location = useLocation();

  const changeLang = (attrLang: string) => {
    i18n.changeLanguage(attrLang);
    setLang(attrLang);
    const lng = i18n.language.includes('es') ? 'es' : 'en';

    if (symptomCheckState.isOpen) {
      dispatch(closeSymptomChecker());
      setTimeout(() => {
        if (isVAOpen) {
          dispatch(openVirtualAssistant());
        }
        if (isHLOpen) {
          dispatch(openHealtLibrary());
        } else {
          dispatch(openSymptomChecker({
            isOpen: true,
            isPublic: false,
            lng: lng,
            userInfo: {
              dob: user.birthdate,
              gender: user.sex,
              memberid: user.idEcw,
              organization_user_id: user.idEcw,
            }
          }));
        }
      }, 1000);
    }

    if (sanitasState.isShow && sanitasState.isSetted) {
      const tempProps = sanitasProps;
      dispatch(sanitasAdapterMethods.shutdown());
      setTimeout(() => {
        dispatch(sanitasAdapterMethods.setUp({
          ...tempProps,
          languageOverride: lng
        }));
        dispatch(sanitasAdapterMethods.show());
      }, 1000);
    }

    if (!location.pathname.includes('referrals-Details')) {
      window.location.reload();
    }

    //moment global format
    moment.locale(attrLang);
  };

  const handleShowButton = () => {
    if (activeHideButtons) {
      setShowButtons(!showButtons);
    }
  };

  const handleIsLangSelected = (lng) => lang && lang.includes(lng);

  useEffect(() => {
    if (i18n.language) {
      setLang(i18n.language);
      moment.locale(i18n.language);
    }
  }, []);

  return (
    <MainContainer>
      {(isMobile && !showButtons) &&
        <SpaceWrapper ml={1} mr={0.5} onClick={handleShowButton}>
          <Text>
            {t('home.languageSelector')}
          </Text>
        </SpaceWrapper>
      }
      <SpaceWrapper ml={1} mr={0.5} onClick={handleShowButton}>
        <GlobeIcon iconClass="fas fa-globe" size={window.innerWidth < 600 ?'medium':"common"} color={window.innerWidth < 600 ? 'light' : "primary"} />
      </SpaceWrapper>
      <Text>
        {showButtons
          ? langList.map((lngItem, i) => (
            <ClickeableText
              color={handleIsLangSelected(lngItem) ? window.innerWidth < 600 ? 'white' : 'accent' : 'black'}
              key={i}

              onClick={() => {
                changeLang(lngItem);
              }}
              bold={handleIsLangSelected(lngItem) ? true : false}
            >
              {lngItem.toUpperCase()}{' '}
              <Text color="black">{i < langList.length - 1 ? ' /' : null}</Text>
            </ClickeableText>
          ))
          : null}
      </Text>
    </MainContainer>
  );
};

export default KeraltyLanguageSwitcher;
