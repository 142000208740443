import { KeraltyText, KeraltyIcon, SvgIcon } from '../atoms';
import theme from '../../ui-core/utils/theme';
import styled from '../../ui-core/styled-components';
// ADAPTER
import { useAppSelector } from '../../../adapter/hooks';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import { useHistory } from 'react-router-dom';
import useIsMobile from 'app/hooks/useIsMobile'
import { height } from '@mui/system';
import i18n from 'i18next';

type DotProps = {
  color: keyof typeof theme.colors;
  position?: boolean;

};

interface DotChatLunchgerProps {
  label: string;
  icon?: string;
  right?: boolean;
  svg?: React.FC;
  position?: boolean;
  center?: boolean;
  color?: keyof typeof theme.colors;
  colordot?: keyof typeof theme.colors | null;
  colorlabel?: keyof typeof theme.colors | null;
  onClick?: () => void;
}

const DotComponent = styled.div<any>`
  position: ${({ position }) => (!position ? 'absolute' : 'relative')};
  bottom: ${({ position }) => (position ? '0px' : '55px')};
  margin-bottom: -15px;
  //bottom: 40px;
  right: ${({ right }) => (right ? '-60px' : '6%')};
  //right: 40px;
  flex-direction: column;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    //bottom: 20px;
    right: ${({ position }) => (position ? '0px' : '0px')};
  }
`;

const Label = styled(KeraltyText)`
  font-size: 14px;
`;

const Dot = styled.div<DotProps>`
  border-radius: 50%;
  width: 68px;
  height: 68px;
  display: block;
  background-color: ${({ color, theme: { colors } }) => colors[color]};
  justify-content: center;
  display: flex;
  align-self: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
`;
const styleText = {
  fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
  color: '#055293',
  fontSize: 20,
  fontWeight: 600,
  textDecorationLine: 'underline',
  paddingLeft: 7,
  display: 'table-cell',
	verticalAlign: 'middle'




};

const KeraltyDotChatTrigger = ({
  colordot,
  colorlabel,
  color,
  label,
  icon,
  svg,
  position,
  right,
  ...props
}: DotChatLunchgerProps) => {
  const intercomChatState = useAppSelector((state) => state.intercomChat);
  const { intercomState } = intercomChatState;
  const labelTitle = label ? (
    <Label color={colorlabel ? colorlabel : 'accent'} noMargin type={'title'}>
      {label}
    </Label>
  ) : null;
  const history = useHistory();
  const isMobile = useIsMobile();


 const showResponsive=()=>{
  if(history.location.pathname.includes('get-care')
   || history.location.pathname.includes('unblock-account')
   || history.location.pathname.includes('forgot-password')
   || history.location.pathname == '/register/public/basic'
   || history.location.pathname == '/register/public/complementary'
   || history.location.pathname == '/register/public/contact'
   || history.location.pathname == '/register/public/one-more'
   || history.location.pathname == '/register/public/code-sent'
   || history.location.pathname == '/register/public/second-sent'
   || history.location.pathname == '/register/public/verify'
   || history.location.pathname == '/register/sanitas/had-sanitas'
   || history.location.pathname == '/register/sanitas/account-type'
   || history.location.pathname == '/register/sanitas/account'
   || history.location.pathname == '/register/sanitas/confirm'
   || history.location.pathname == '/register/sanitas/contact'
   || history.location.pathname == '/register/sanitas/one-more'
   || history.location.pathname == '/register/sanitas/basic'
   || history.location.pathname == '/register/sanitas/verify'
   || history.location.pathname == '/register/sanitas/code-sent'
   || history.location.pathname == '/register/sanitas/second-sent'
   || history.location.pathname == '/patient-registration/responsible-party'
   || history.location.pathname == '/patient-registration/insurance'
   || history.location.pathname == '/patient-registration/consent'
   || history.location.pathname == '/my-account/my-insurance'
   )
  {return true} else{return false}
 }

  const dotContent = !intercomState.isShow ? (showResponsive() && isMobile ?

    <div>
      <div onClick={props.onClick}>
        {svg ? (
          <div style={{ flexDirection: "row", display: 'table', inlineSize: 'max-content' }}>
            <SvgIcon src={svg} />
            <text style={styleText}>{label}</text>
          </div>

        ) : null}
      </div>
    </div>
    :
    <div>
      {labelTitle}
      <Dot color={colordot ? colordot : 'accent'} onClick={props.onClick}>
        {svg ? (
          <SvgIcon src={svg} color={color ? color : 'accent'} />
        ) : (
          <KeraltyIcon iconClass={icon} size={'superBig'} color={color} title={label} />
        )}
      </Dot>
    </div>

  ) : null

  return <DotComponent right={right} position={position}>{dotContent}</DotComponent>;
};


export default KeraltyDotChatTrigger;
