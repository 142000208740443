import styled from '../../ui-core/styled-components';
import { KeraltyText } from '../atoms';
import KeraltyBreadcrumb, { KeraltyBreadcrumbProps } from '../molecules/keraltyBreadcrumb';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import CenteredWrapper from './centeredWrapper';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import useIsMobile from 'app/hooks/useIsMobile';

interface PageProps {
	className?: string;
	bigTitle?: boolean;
	title?: string;
	subtitleProps?: any;
	subtitle?: string;
	hideMobileTitle?: boolean;
	routes?: KeraltyBreadcrumbProps['routes'];
	onPrevious?: () => void;
	previousLabel?: string;
	footer?: any;
	children: any;
	w60?: boolean;
	sendCodeResponse?: { cause: string, tries: number, timer: number};
	isMobile?: boolean;
};

const PageStructure = ({
	className, bigTitle, title, subtitleProps, subtitle, routes, onPrevious, previousLabel, footer, children, w60, sendCodeResponse
}: PageProps) => {
	const isMobile = useIsMobile();
	const location = useLocation();
	const [hiddenBackOption, setHiddenBackOption] = useState<Boolean>(false)

	const urls = [
		'/register/sanitas/second-sent',
		'/register/public/second-sent'
	]

	const causeResponse = [
		'SUCCESS',
		'VERIFIED'
	]

	useEffect(() => {
		if(sendCodeResponse && causeResponse.includes(sendCodeResponse.cause) && urls.includes(location.pathname) ){
			setHiddenBackOption(true)
			return;
		}

		setHiddenBackOption(false)

	}, [sendCodeResponse, location.pathname])


	return (
		<div className={className}>
			{(routes || onPrevious) &&
				<div className="page-routes">
					{routes && <KeraltyBreadcrumb routes={routes} />}
					{onPrevious &&
						(
							!hiddenBackOption ? <KeraltyBreadcrumb routes={[{ label: previousLabel || '<', callback: () => onPrevious() }]} />
							: <></>
						)
					}
				</div>
			}
			{title &&
				<div className="page-title">
					<CenteredWrapper direction="horizontal">
						{bigTitle ?
							<KeraltyText type={'big'} color="primary" bold>{title}</KeraltyText>
							:
							<KeraltyText type={'title'}>{title}</KeraltyText>
						}
					</CenteredWrapper>
					{subtitle && <CenteredWrapper direction="horizontal">
						<KeraltyText type={'label'} {...subtitleProps} >{subtitle}</KeraltyText>
					</CenteredWrapper>}
				</div>
			}
			<div className="page-body">{children}</div>
				{footer && <div className={`page-footer ${isMobile ? 'bottomMobile' : ''}`}>{footer}
			</div>}
		</div>
	)
}

const Page = styled(PageStructure) <PageProps>`
	height: calc(100% - 4rem);
	width: calc(100% - 4rem);
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
        height: auto;
    }
	&.right-side-page-arc {
		@media (min-width: 320px) and (max-width: 599px) {
			// ARC HEADER MOBILE & RESPONSIVE
			height: calc(100% - 4rem - 161px);
		}
	}
	overflow-y: auto;
	overflow-x: hidden;
	padding: 2rem;
	position: relative;

	.page-routes {
		height:auto;
		width: 100%;
		display: inline-flex;
		justify-content: flex-end;
		flex-wrap:wrap;
		&>div{
			display: inline-flex;
		}
		&>div:first-child{flex-grow:1;}
	}

	${({ title, subtitle, routes, theme: { sizes } }) => title ? `
		overflow-y: auto;
		.page-title {
			height: ${subtitle ? '6rem' : '3rem'};
			margin-top: ${routes ? '0px' : '3.5rem'};
			h2, span {
				margin: 0px;
				&:nth-child(1) {
					${subtitle ? 'margin-bottom: .5rem !important;' : ''}
				}
			}
		}
	` : `
		.page-body {
			height: calc(100% - 7rem - ${sizes.header});
		}
	`}

	.page-footer {
		min-height: 5rem;
	}

	.bottomMobile{
		margin-bottom:3rem
	}

	${({ w60 }) => w60 ? `
		width: 60%;
	` : `
		''
	`}

	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		${({ hideMobileTitle }) => hideMobileTitle ? `
			.page-title {
				display: none;
			}
		` : ''}
	}
`;

export default Page;
