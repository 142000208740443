// UI
import { KeraltyIcon, KeraltyText } from '../../atoms';
import { Column, Row } from '../../templates';
import styled from '../../../ui-core/styled-components';
import SvgIcon from '../../atoms/SvgIcon';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import { useHistory, useLocation } from 'react-router-dom';

interface ColumnBlockProps {
	label: string;
	icon: string;
	iconLabel: string;
	value: string | number;
	svg?: React.FC;
	gray?: boolean
}

export interface PersonalInfoBlocksProps {
	infoBlock: {
		title: string;
		icon: string;
		iconTitle: string;
		items: ColumnBlockProps[];
	}
};

const InformationContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
  align-self: 'center';
  max-width: 100%;
`;

const TitleContainer = styled.span`
	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-left: -2rem;
	}
`;

const LabelText = styled(KeraltyText)`
  margin-left: 0.5rem;
`;

const PersonalDetails = styled(KeraltyText)`
  margin-left: 0.5rem;
  font-size: 1.65rem;
`;

const InfoBlock = styled(Row)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
	columns: 2 auto;
`;

const LeftItems = styled(Row)`
	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: -1rem;
	}
`;

const ValueContainer = styled(KeraltyText)`
	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-left: 0.5rem;
		display: flex;
	}

	`;

const PersonalInfoBlocks = ({ infoBlock }: PersonalInfoBlocksProps) => {
	const firstItems = Math.ceil(infoBlock.items.length / 2);
	const totalItems = infoBlock.items.length;

	return (
		<InformationContainer>
			<Row>
				<Column lg={11}>
					<TitleContainer>
						<KeraltyIcon
							iconClass={infoBlock.icon}
							size="common"
							title={infoBlock.iconTitle}
						/>
						<PersonalDetails color="primary">{infoBlock.title}</PersonalDetails>
					</TitleContainer>
				</Column>
			</Row>
			<Row noMargin>
				<Column lg={6} md={6} sm={12}>
					<Row noMargin>
						{
							infoBlock.items.slice(0, firstItems).map((dataRow, i) => <InfoBlock key={`info-block-${i}`}>
								<div style={{ flexDirection: 'row' }}>
									<div>
										{dataRow.svg ? <SvgIcon src={dataRow.svg} color={dataRow?.gray? '#5B5C5B':'primary'} /> : <KeraltyIcon
											iconClass={dataRow.icon}
											size="common"
											color="primary"
											title={dataRow.iconLabel}
										/>
										}
									</div>									
									<div>
										<LabelText type="info" bold style={{ color:  dataRow?.gray? '#5B5C5B': "#055293" }}>
											{dataRow.label}{' '}
										</LabelText>
										<ValueContainer color="dark" type="info">
											{dataRow.value}
										</ValueContainer>
									</div>
								</div>
							</InfoBlock>)
						}
					</Row>
				</Column>
				<Column lg={6} md={6} sm={12}>
					<LeftItems noMargin>
						{
							infoBlock.items.slice(firstItems, totalItems).map((dataRow, i) => <InfoBlock key={`info-block-${i}`}>
								<div style={{ flexDirection: 'row' }}>
									<div>
										{dataRow.svg ? <SvgIcon src={dataRow.svg} color={dataRow?.gray? '#5B5C5B':'primary'} /> : <KeraltyIcon
											iconClass={dataRow.icon}
											size="common"
											color="primary"
											title={dataRow.iconLabel}
										/>
										}
									</div>
									<div>
										<LabelText type="info" bold style={{ color: dataRow?.gray? '#5B5C5B': "#055293" }}>
											{dataRow.label}{' '}
										</LabelText>
										<ValueContainer color="dark" type="info">
											{dataRow.value}
										</ValueContainer>
									</div>
								</div>
							</InfoBlock>)
						}
					</LeftItems>
				</Column>
			</Row>
		</InformationContainer>
	)
}

export default PersonalInfoBlocks;
