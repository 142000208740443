const theme = {
  colors: {
    lightBg: '#f5f5f5',
    primary: '#055293',
    lightPrimary: '#0E79DE',
    accent: '#3CA70D',
    lightAccent: '#61B73B',
    accentContrast: '#0F880D',
    light: '#FFFFFF',
    darkLight: '#EBECEF',
    lightGrey: '#F9F9F9',
    lightGray: '#EEEDED',
    gray: '#C4C4C4',
    darkGray: '#7A7A7A',
    lightDark: '#ACACAD',
    dark: '#5B5C5B',
    danger: '#D74840',
    warn: '#EF8B2E',
    alert: '#EDC536',
    secundaryAlert: '#E7A304',
    success: '#B2C44A',
    disabled: '#E5E5E5',
    black: '#000000',
    transparent: 'transparent',
    orange:'#E7A304',
    gray1000: '#1A2128',
    blueTitle:'#065394',
    blueAction:'#077BBF',
    blueDark: '#002E58',
    TEXTCOLORLIGHT: '#212121',
    textColorDark: '#757575',
    textBlueCounter: '#022F58',
    blue307: '#0069A7',
    blue007: '#0071A3',
    blue997:'#0E5997',
    blueB98:'#115B98',
	white: '#FFFFFF',
  },
  sizes: {
    // JUST VALUE WITHOUT UNITS
    icon: {
      extraBig: 3.5,
	  superBig: 2.875,
      big: 2.5,
      medium: 2,
      common: 1.5,
      small: 1.12,
	  normal: 1,
    },
    header: '60px',
  },

  /* ******************** */
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLUE288: '#022F57',
  BLUE306: '#00b2e3',
  BLUE307: '#0069A7',
  BLUEDC1: '#055293',
  GREEN362: '#4C9C2E',
  GREEN376: '#80bc00',
  GREENDC1: '#3CA70D',
  TEXTCOLORLIGHT: '#212121',
  TEXTCOLORDARK: '#757575',
};

export default theme;
