import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import styled from '../../ui-core/styled-components';
import Row from './row';
import { StyledWrapper } from './wrapper';

const RowStyled = styled(Row)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    ${StyledWrapper} & {
      top: 30px;
    }
  }
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    ${StyledWrapper} & {
      top: 0;
    }
  }
`;

const TemplateStructure = ({ className }: any) => (
  <RowStyled className={className} noMargin>
    <div className="figs">
      <div></div>
      <div></div>
      <svg viewBox="0 0 375 95" xmlns="http://www.w3.org/2000/svg">
        <mask
          id="mask0_15649_69154"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="375"
          height="95"
        >
          <rect width="375" height="95" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_15649_69154)">
          <path d="M0 0H375V95C173.5 43.5 0 95 0 95V0Z" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M147.645 73.8622C166.317 72.646 185.867 72 206 72C337.444 72 444 99.5345 444 133.5C444 164.004 358.054 189.321 245.322 194.165C348.544 187.478 425 163.302 425 134.5C425 100.534 318.667 73 187.5 73C173.919 73 160.604 73.2952 147.645 73.8622Z"
            fill="#61B73B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M-67 133.5C-67 99.5345 39.3324 72 170.5 72C191.695 72 212.241 72.7189 231.795 74.0677C217.444 73.3667 202.637 73 187.5 73C56.3327 73 -50 100.534 -50 134.5C-50 162.977 24.7449 186.934 126.204 193.932C16.2052 188.559 -67 163.546 -67 133.5Z"
            fill="#0E79DE"
          />
        </g>
      </svg>
    </div>
  </RowStyled>
);

const Figures = styled(TemplateStructure)`
  position: fixed;
  z-index: 3;
  top: 0px;
  left: -50px;
  right: -50px;
  width: calc(100% + 100px);
  .figs {
    height: 100%;
    width: 100%;
    position: relative;
    height: 67px;
    display: block;
    div {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      &:nth-child(1) {
        left: 0;
        background-color: ${({ theme: { colors } }) => colors.lightAccent};
        transform: rotate(-0.7deg);
        z-index: 3;
      }
      &:nth-child(2) {
        right: 0;
        background-color: ${({ theme: { colors } }) => colors.lightPrimary};
        transform: rotate(0.7deg);
        z-index: 2;
      }
    }
    svg {
      display: none;
    }
  }
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    .figs {
      height: 65px;
      div {
        display: none;
        &:nth-child(1) {
          width: 50%;
          transform: rotate(-3.5deg);
        }
        &:nth-child(2) {
          transform: rotate(4deg);
        }
      }
      svg {
        display: block;
      }
    }
  }
`;

export default Figures;
