import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

import AnxietySteps from "./components/AnxietySteps";
import TitleSection from "./components/TitleSection";
import { ReactComponent as thinking } from '../../../../assets/icons/thinking.svg';
import { KeraltyButton, KeraltyError } from "app/components/atoms";
import ROUTES from "app/ui-core/utils/routes";
import Chip from "./components/Chip";
import { useAnxiety } from "./hooks/useAnxiety";
import { useEffect, useState } from "react";
import { AnxietyParameter, TypeAnxietyParameter } from "infrastructure/keraltyApi/models/mentalHealth";
import { Loader } from "app/components/templates";
import { CustomSection } from "./Anxiety.styled";
import useIsMobile from "app/hooks/useIsMobile";

const ThinkingOptions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const { getAnxietyParametersByType, setIsLoading, isLoading, saveInfoInStore, register } = useAnxiety();
  const [thinkings, setThinkings] = useState<AnxietyParameter[]>([]);
  const [optionSelected, setOptionSelected] = useState<string>()
  const [error, setError] = useState<boolean>(false)

	const { MENTAL_HEALTH } = ROUTES;

  const getData = async () => {
    setIsLoading(true)
    try{
      const data = await getAnxietyParametersByType(TypeAnxietyParameter.THINKING);
      setThinkings(data);
      setIsLoading(false);
    }catch(error){
      console.error(error);
      setIsLoading(false);
    }
  }

  const valueSelected = (text: string): void => {
    setOptionSelected(option => option === text ? undefined : text);
  }

  const nextStep = () => {
    if(!optionSelected){
      setError(true);
      return
    }

    saveInfoInStore({ thinking: optionSelected })
    history.replace(`${MENTAL_HEALTH.thinkingDetails}`)
  }

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if(register.thinking) setOptionSelected(register.thinking)
  }, [register])

  useEffect(() => {
    setError(false);
  }, [optionSelected])

  return (
    <>
      {
        isLoading
        ? ( <Loader /> )
        : (
          <AnxietySteps>
            <TitleSection
              icon={thinking}
              title={t('mentalHealth.thinking')}
              subtitle={t('mentalHealth.thinkingText')}
            />
            <div style={isMobile ? { marginLeft: 20, marginRight: 10 } : { marginLeft: 50, marginRight: 50 }}>
              <CustomSection colums={2}>
                  {
                      thinkings.map( (item, index) => (
                          <div key={index}>
                            <Chip
                              text={ i18n.language === 'es' ? item.contentEs : item.contentEn }
                              valueSelected={ valueSelected }
                              currentValue={ optionSelected }
                            />
                          </div>
                      ))
                  }
              </CustomSection>
            </div>
            {
              error
              && (
                <div style={{ display: 'grid', placeItems: 'center', marginTop: 40, marginBottom: 40 }}>
                  <KeraltyError error={t('mentalHealth.thinkingError')} />
                </div>
              )
            }
            <div style={{ marginTop: 40, marginBottom: 50, display: 'grid', placeItems: 'center' }}>
                <KeraltyButton
                  onClick={ nextStep }
                  fontWeight={ '600' }
                  withShadow
                >
                  {t('formLabel.enter')}
                </KeraltyButton>
            </div>
          </AnxietySteps>
        )
      }
    </>
  )
}

export default ThinkingOptions;
