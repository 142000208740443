import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";

import { IconLinkRow, Row } from "app/components/templates";
import { CustomSection, RightContent, TitleContiner } from "./Anxiety.styled";
import { KeraltyButton, KeraltyText } from "app/components/atoms";
import { useAppSelector } from "adapter/hooks";
import { UnderstandingYourAnxietyState } from "adapter/understandingAnxiety/understandingAnxiety.state";
import { getDateAsText } from "app/ui-core/utils/dates";
import ROUTES from "app/ui-core/utils/routes";
import TitleSection from "./components/TitleSection";
import DescriptionCard from "./components/DescriptionCard";

import { ReactComponent as pysicalSymptoms } from '../../../../assets/icons/pysical-symptoms.svg';
import { ReactComponent as thinking } from '../../../../assets/icons/thinking.svg';
import { ReactComponent as actions } from '../../../../assets/icons/actions.svg';
import { ReactComponent as feeling } from '../../../../assets/icons/feeling.svg';
import arrowAltCircleLeftIcon from '../../../../assets/icons/arrow-alt-circle-left.svg';
import styled from "app/ui-core/styled-components";
import BREAKPOINTS from "app/ui-core/utils/breakpoints";
import useIsMobile from "app/hooks/useIsMobile";

const Section = styled.div`
    display: grid;
    margin: 0px 0px 0px 50px;

	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin: 0px 20px 0px 30px;
	};
`

const AnxietyDetails = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const { MENTAL_HEALTH } = ROUTES;
  const { registerSelected } = useAppSelector<UnderstandingYourAnxietyState>(state => state.understandingYourAnxiety);
  const date = getDateAsText(registerSelected?.dateTime, "long")

  useEffect(() => {
    if(!registerSelected) history.replace(`${MENTAL_HEALTH.anxiety}`)
  }, [registerSelected])

  return (
    <RightContent fullHeight top='10'>
        <IconLinkRow
		    onClick={() => history.replace(`${MENTAL_HEALTH.anxiety}`)}
			color="accent"
			icon={arrowAltCircleLeftIcon}
			iconAlt={t('imgNicons.backArrow')}
			text={t('routes.back')}
		/>
        <Row style={{ paddingBottom: 20,  }}>
			<TitleContiner lg={12}>
				<KeraltyText align={isMobile ? 'left' : 'center'} type="title" fontWeight={isMobile ? '600' : '400'}>{t('mentalHealth.understandingYourAnxiety')}</KeraltyText>
			</TitleContiner>
		</Row>
        <div style={{ display: 'grid', placeItems: 'center' }}>
            <section>
                <div style={{ paddingBottom: 20 }}>
                    <TitleContiner lg={12}>
                        <KeraltyText type="subTitle" fontWeight={'900'} fontSize="18px" align="left">
                            { date.toUpperCase() }
                        </KeraltyText>
                    </TitleContiner>
                </div>
                <div style={{ paddingBottom: 50 }}>
                    <TitleSection
                        icon={thinking}
                        title={t('mentalHealth.thinking')}
                        subtitle={t('mentalHealth.thinkingText')}
                    />

                    <div style={{ display: 'grid', placeItems: 'center', marginTop: '10px', marginBottom: '40px' }}>
                        <KeraltyButton fontWeight={ '600' } fontSize="14px" width="284px" withShadow withoutTextTransform>
                            { registerSelected?.thinking }
                        </KeraltyButton>
                    </div>
                    <Section>
                        <TitleContiner lg={12} style={{marginTop: 10, marginBottom: 10}}>
                            <KeraltyText type="subTitle" fontWeight={isMobile ? '400' : '600'} fontSize="14px" align="left" color="#212121">{t('mentalHealth.thinkingDetails')}</KeraltyText>
                        </TitleContiner>
                        <DescriptionCard>
                            { registerSelected?.moreAbout }
                        </DescriptionCard>
                    </Section>
                </div>
                <div style={{ paddingBottom: 50 }}>
                    <TitleSection
                        icon={actions}
                        title={t('mentalHealth.actions')}
                        subtitle={t('mentalHealth.actionsText')}
                    />
                    <br />
                    <Section>
                        <DescriptionCard>
                            { registerSelected?.actions }
                        </DescriptionCard>
                    </Section>
                </div>
                <div style={{ paddingBottom: 50 }}>
                    <TitleSection
                        icon={feeling}
                        title={t('mentalHealth.feeling')}
                        subtitle={t('mentalHealth.feelingText')}
                    />
                    <div style={{ marginLeft: 40 }}>
                        <CustomSection colums={2}>
                            {
                                registerSelected?.feeling.split('|').filter(item => item.trim()).map( (item, index) => (
                                    <div key={index}>
                                        <KeraltyButton fontWeight={ '600' } fontSize="14px" withShadow withoutTextTransform>
                                            {item}
                                        </KeraltyButton>
                                    </div>

                                ))
                            }
                        </CustomSection>
                    </div>
                </div>
                <div style={{ paddingBottom: 20 }}>
                    <TitleSection
                        icon={pysicalSymptoms}
                        title={t('mentalHealth.physicalSymptoms')}
                        subtitle={t('mentalHealth.physicalSymptomsText')}
                    />
                    <div style={{ marginLeft: 40 }}>
                        <CustomSection colums={2}>
                            {
                                registerSelected?.physicalSymptoms.split('|').filter(item => item.trim()).map( (item, index) => (
                                    <div>
                                        <KeraltyButton key={index} fontWeight={ '600' } fontSize="14px" withShadow withoutTextTransform>
                                            {item}
                                        </KeraltyButton>
                                    </div>
                                ))
                            }
                        </CustomSection>
                    </div>
                </div>
            </section>
        </div>
        <Row style={{ paddingBottom: 20 }}>
            <KeraltyText type="info" color="#757575" fontWeight={'400'} fontSize="18px">{t('mentalHealth.endOfEntry')}</KeraltyText>
        </Row>
	</RightContent>
  )
}

export default AnxietyDetails;
