// 3RD PARTY
import { useTranslation } from 'react-i18next';

import { ReactComponent as PeopleArrowsSvg } from '../../../../assets/icons/people-arrows.svg';
import useOnlyMobile from 'app/hooks/useOnlyMobile';
// Sub Components
import PersonalInfoBlocks from '../personalInfoBlocks';
import { EmergencyContanctInfo } from '../../../../../domain/entities/emergencyContanctInfo';
import isEmpty from 'lodash/isEmpty';
interface EmergencyContactProps {
    emergencyContactInfo: EmergencyContanctInfo
}

const EmergencyContactBlock = ({emergencyContactInfo}: EmergencyContactProps) => {
    const { t } = useTranslation();
    const isOnlyMobile = useOnlyMobile();
    if (isEmpty(emergencyContactInfo)) {
        return <></>
    }
    return (
        <PersonalInfoBlocks infoBlock={{
            title: t('myAccount.emergencyContact'),
            icon: `fas fa-user ${isOnlyMobile && 'gray'}`,
            iconTitle: t('imgNicons.userCircleIcon'),
            items: [
                {
                    label: t('formLabel.firstName')+':',
                    icon: `fas fa-user ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.userIcon'),
                    value: emergencyContactInfo.firstName,
                    gray: isOnlyMobile
                },
                {
                    iconLabel: t('imgNicons.userIcon'),
                    icon: `fas fa-user ${isOnlyMobile && 'gray'}`,
                    label: t('formLabel.lastName')+':',
                    value: emergencyContactInfo.lastName,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.relationship')+':',
                    icon: `fas fa-people-arrows ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.relationshipIcon'),
                    value: emergencyContactInfo.relation,
                    gray: isOnlyMobile,
                    svg: PeopleArrowsSvg
                },
                {
                    label: t('myAccount.address1')+':',
                    icon: `fas fa-map-marker-alt ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.addressIcon'),
                    value: emergencyContactInfo.address1,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.address2')+':',
                    icon: `fas fa-map-marker-alt ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.addressIcon'),
                    value: emergencyContactInfo.address2,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.city')+':',
                    icon: `fas fa-city ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.cityIcon'),
                    value: emergencyContactInfo.city,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.state')+':',
                    icon: `fas fa-map-marked-alt ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.flagUSAIcon'),
                    value: emergencyContactInfo.state,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.zip')+':',
                    icon: `fas fa-map-marked-alt ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.mapMarkerIcon'),
                    value: emergencyContactInfo.zipCode,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.cellPhone')+':',
                    icon: `fas fa-mobile-alt ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.mobileIcon'),
                    value: emergencyContactInfo.cellPhone,
                    gray: isOnlyMobile
                },
                {
                    label: t('myAccount.workPhone')+':',
                    icon: `fas fa-phone-rotary ${isOnlyMobile && 'gray'}`,
                    iconLabel: t('imgNicons.rotaryPhoneIcon'),
                    value: emergencyContactInfo.workPhone,
                    gray: isOnlyMobile
                }
            ]
        }} />
    )
}

export default EmergencyContactBlock;