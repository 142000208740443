// 3RD PARTY
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Image, KeraltyIcon, KeraltyText } from '../../../components/atoms';
import { KeraltyLongCard } from '../../../components/molecules';
import { Row, Column } from '../../../components/templates';
import useIsMobile from '../../../hooks/useIsMobile';
import { SpaceWrapper } from 'app/components/templates';
import locationIcon from 'app/assets/icons/locationicon-filled.svg';
import { openSafeUrl } from 'app/ui-core/utils/url';
const url = 'https://www.mysanitas.com/en/fl#state-locations';

const LinkLocations = styled.a`
  text-decoration: none;
  display: inline-block;
  width: 100%;
`;

const LongCard = styled(KeraltyLongCard)`
  margin-top: 1rem;
  padding: 5px 2rem;
  display: flex;
  align-items: center !important;
  box-shadow: 0px 4px 4px rgba(1, 42, 88, 0.25);
  width: auto;
`;

const MaintContainer = styled(Column)`
 padding: 0 20px;
 margin-bottom: 50px;
`;

const LocationNServices = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  if (!isMobile) {
    return null;
  }
  return (
    <MaintContainer noPadding>
      <KeraltyText type={'label'} bold>
        {t('home.findCenter')}
      </KeraltyText>
      <LongCard onClick={() => openSafeUrl(url)}>
        <Image
          src={locationIcon}
          size={4}
          style={{ marginRight: '25px', marginInlineStart: '5px' }}
          alt={t('imgNicons.mapMarkedIcon')}
        />
        <Row>
          <KeraltyText type="label" bold={true} style={{textAlign: 'left', fontSize: '16px'}}>
            {t('routes.location')}
          </KeraltyText>
          <KeraltyText
            type="info"
            color="#5B5C5B"
            style={{ textAlign: 'left', lineHeight: 1.3, marginTop: 5, color: "#5B5C5B", fontSize: 12 }}
            fontSize={'12px'}
            bold
            adaptable={false}
          >
            {t('routes.locationDescription')}
          </KeraltyText>
        </Row>

      </LongCard>
    </MaintContainer>
  );
};

export default LocationNServices;
