import { KeraltyIcon, KeraltyText } from "app/components/atoms";
import { KeraltyModal } from "app/components/molecules";
import { Container, Row, SpaceWrapper } from "app/components/templates";
import { useTwoFactorAuthentication } from "app/hooks/useTwoFactorAuthentication";
import ROUTES from "app/ui-core/utils/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { DoneButton, styleButton, styleDivFirst, styleIcon, styleText } from "./styles";
import { TwoFactorAuthenticationSteps } from "adapter/twoFactorAuthentication/twoFactorAuthentication.state";

const { MAIN, REGISTER: CURRENT } = ROUTES;

const CodeSent = ({ resendCode = false }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { infoTwoFactor } = useTwoFactorAuthentication();

    const handleClick = () => history.replace(`/${MAIN.authentication}/${CURRENT.sendCode}`)

    if (window.innerWidth < 1024) {

        return (
            <KeraltyModal size="long">
                <Row noMargin>
                    <div style={{ marginLeft: '34%' }} >
                        <KeraltyIcon iconClass="fas fa-comment-alt-check" color="accent" size="medium" style={styleIcon} rounded />
                    </div>
                    <SpaceWrapper mb={1}>
                        <Container centered={'x'} noPadding noMargin>
                            {
                                infoTwoFactor.sendCodeBy === 'email' ?
                                (
                                    <KeraltyText type={'label'} align={'center'} style={styleText}>
                                        { infoTwoFactor.currentStep === TwoFactorAuthenticationSteps.resendCode ? t('message.twoFactorCodeReSendByEmail') : t('message.twoFactorCodeSentByEmail')}
                                        <br />
                                        {infoTwoFactor.currentStep !== TwoFactorAuthenticationSteps.resendCode && t('message.validateToContinue')}
                                    </KeraltyText>
                                ) :
                                (
                                    <KeraltyText type={'label'} align={'center'} style={styleText}>
                                        { infoTwoFactor.currentStep === TwoFactorAuthenticationSteps.resendCode ? t('message.twoFactorCodeResendByPhone') : t('message.twoFactorCodeSentByPhone')}
                                        <br />
                                        {infoTwoFactor.currentStep !== TwoFactorAuthenticationSteps.resendCode && t('message.validateToContinue')}
                                    </KeraltyText>
                                )
                            }
                        </Container>
                    </SpaceWrapper>

                </Row>
                <Row noMargin>
                    <div style={styleDivFirst}>
                        <button
                            style={styleButton}
                            type="button"
                            onClick={handleClick}
                        >
                            {t('myAccount.textAccept')}
                        </button>
                    </div>
                </Row>
            </KeraltyModal>
        )
    }
    else {

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '2.5rem' }}>
                    <KeraltyIcon
                        iconClass={'fas fa-comment-alt-check'}
                        size="big"
                        color="accent"
                        title={t('imgNicons.paperPlaneIcon')}
                        rounded
                    />
                </div>
                {
                    infoTwoFactor.sendCodeBy === 'email' ?
                    (
                        <KeraltyText type="subTitle" width='550px'>
                            { infoTwoFactor.currentStep === TwoFactorAuthenticationSteps.resendCode ? t('message.twoFactorCodeReSendByEmail') :  t('message.twoFactorCodeSentByEmail')}
                            <br />
                            {infoTwoFactor.currentStep !== TwoFactorAuthenticationSteps.resendCode && t('message.validateToContinue')}
                        </KeraltyText>
                    ) :
                    (
                        <KeraltyText type="subTitle" width='550px'>
                            { infoTwoFactor.currentStep === TwoFactorAuthenticationSteps.resendCode ? t('message.twoFactorCodeResendByPhone') : t('message.twoFactorCodeSentByPhone')}
                            <br />
                            {infoTwoFactor.currentStep !== TwoFactorAuthenticationSteps.resendCode && t('message.validateToContinue')}
                        </KeraltyText>
                    )
                }

                <div style={{ marginTop: '35px', display: 'flex', justifyContent: 'center' }}>
                    <DoneButton onClick={handleClick}>
                        { t('myAccount.textAccept') }
                    </DoneButton>
                </div>
            </div >
        )
    }
}

export default CodeSent;
