import { useTranslation } from 'react-i18next';
import { VisitSummaryInfo } from '../../../../domain/entities/visitSummaryInfo';
import { useState } from 'react';
import PaginateItems from '../../molecules/paginateItems';
import pagesIcon from '../../../assets/icons/pages.svg';
import { KeraltyIcon, Image, KeraltyText, KeraltyButton } from '../../../components/atoms';
import { IconLinkRow } from '../../../components/templates';
import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import EmptyIcon from '../../atoms/emptyIcon';
import { Column, Row } from '../../templates';
import useIsMobile from '../../../hooks/useIsMobile';
import moment from 'moment';
import Archive from '../../../assets/icons/visitArch.svg';
import FORMATS from 'app/ui-core/utils/formats';
import i18n from 'i18next';
import PrivateHttpClient from '../../../../infrastructure/keraltyApi/privateHttpClient';
import ImgModal3 from '../../../assets/icons/exclamation-circle.svg';
import useAuth from '../../../hooks/useAuth';
import theme from 'app/ui-core/utils/theme';
interface VisitSummaryCardsProps {
  data: VisitSummaryInfo[] | null;
  isEmpty?: boolean;
  initPage?: boolean;
}


const VisitSummaryCard = styled.div`
  border-bottom: 2px rgba(0, 0, 0, 0.2) solid;
  border-left: 1px rgba(0, 0, 0, 0.2) solid;
  border-right: 1px rgba(0, 0, 0, 0.2) solid;
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  justify-content: center;
  padding: 15px;
  -webkit-box-shadow: 0px 6px 6px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 6px 6px -1px rgba(0, 0, 0, 0.15);
  width: 90%;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	  margin-left:-10px;
    width: 80%;
  }
`;

const ColumnIcon = styled.div`
  float: left;
  width: 8%;
  padding-left: 1em;
  padding-top: 1.5em;
  text-align: center;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    display: none;
  }
`;


const ColumnInfo = styled.div`
  flex-directions:row
  overflow: hidden;
  float: left;
  width: 90%;
`;

const RowInfo = styled.div`
	margin-left:60px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top:10px;
		margin-left:0px;
	}
  }
`;
const Icon = styled(Row)`
	color: #055293;
  	width: 15px !important;
  	height: 15px !important;
  	margin: 0;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color: #055293;
	}
`;
const ColumnData = styled.div`
  float: left;
`;

const RowInDataStyled = styled.div`
  label {
    margin: 0.2rem;
  }
  span {
    margin-right: 0.5rem;
  }
  padding: 0.2rem 0.5rem;
`;

const EmptyIconWrap = styled.div`
  margin: 100px auto;
  position: relative;
  left: -5%;
`;

const ContentButton = styled.div`


	@media  (max-width: 811px){
		width: 100%;
		>div{
			display: flex;
  justify-content: center;
		}
	};
	@media (min-width: 811px) and (max-width: 1238px){
		width: 10%;
	};
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 100%;
	};
`;
const PdfButton = styled(Column)`
	display: block !important;
  	background: #3CA70D;
	border-radius: 29px;
	color: #FFFFFF;
	width: 142px;
	height: 35px;
	text-align: center !important;
	cursor: pointer;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
	font-size: 14px;
	line-height: 35px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 86px;
	};
	@media (max-width: 433px) {
		width: 130px;
		height: 44px;
		line-height: 44px;
	};
	@media (max-width: 803px){
		width: 140px;
		margin: 10px
	};
`;
const ContainModal = styled(Column)`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  overflow: auto;
  z-index: 99;
  background-color: #00000058;
  width: 100%;
  padding: 0;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

	}
`;
const Modal = styled(Column)<{isMobile: boolean}>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 10px;
  width: ${({ isMobile }) => isMobile ? '80%' : 'fit-content'};
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 	0%;
  margin-top: ${({ isMobile }) => isMobile ? '60%' : '15%'};
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

	}
`;
const ContainerCancelButton = styled(Column)`
	display: flex;
	text-align: center;
	justify-content: center;
`;
const ContainerFilterButton = styled(Column)`
	display: flex;
	text-align: center;
	justify-content: center;
	align-content: center;
	margin: 0;
`;


const Button = styled(KeraltyButton)`
	letter-spacing: 1px;
	align-self: center;
	margin-top: 10px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		padding: 0px;
	}
`;
const RowInDataList = (
	title: string,
	value: string,
	iconClass: string,
	color: keyof (typeof theme.colors) = 'primary',
	labelColor: keyof (typeof theme.colors) = 'primary'
) => (
  <RowInDataStyled>
    <KeraltyIcon iconClass={iconClass} color={color}></KeraltyIcon>
    <KeraltyText type="label" color={labelColor}>{`${title}:`}</KeraltyText>
    <KeraltyText type="label" color="dark">{`${value}`}</KeraltyText>
  </RowInDataStyled>
);

const VisitSummaryCards = ({ data: cardsList, isEmpty = false, initPage = false }: VisitSummaryCardsProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const endpoint = 'ecw';
  const [modal, setModal] = useState(false);
  const endDate = moment().format('YYYY-MM-DD');
  const beginDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
  const { user } = useAuth();
  const [itemToDownload, setItemToDownload] = useState<any>();

  const ModalAnnual = () => {
    return (
      <ContainModal>
        {modal &&
          <Modal isMobile={isMobile}>
            <div style={{ padding: '10px' }}>
              <img style={{ background: '#3CA70D', borderRadius: '50%' }} src={ImgModal3} alt="" />
            </div>
            {isMobile ?
              <div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
                <KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
                  Este archivo se guardara en la carpeta predetermidada de tu dispositivo
                </KeraltyText>
              </div> :
              <div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px', width: '60%' }}>
                <KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
                  Este archivo se guardara en la carpeta predetermidada de tu dispositivo
                </KeraltyText>
              </div>}

            <div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
              <KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova' width='20px' >
                ¿Deseas continuar?
              </KeraltyText>
            </div>



            {isMobile ? <Row>

              <ContainerFilterButton lg={12} md={6} sm={6} >
                <Button smFull type={'submit'}  >
                  {t('myHealth.yes')}
                </Button>
              </ContainerFilterButton>

              <ContainerCancelButton lg={0} md={6} sm={6}>
                <Button
                  outline
                  style={{ borderColor: '#FFFFFF', color: 'rgba(91, 92, 91, 0.5)' }}
                  smFull
                  type={'text'}
                  onClick={() => {
                    setModal(false)
                  }}
                >
                  {t('myHealth.no')}
                </Button>
              </ContainerCancelButton>
            </Row>
              :

              <div style={{ width: '75%', textAlign: 'center', padding: '5px', marginLeft: '200px' }}>
                <Row>

                  <ContainerFilterButton >
                    <Button smFull type={'submit'}     >
                      {t('myHealth.yes')}
                    </Button>
                  </ContainerFilterButton>

                  <ContainerCancelButton >
                    <Button
                      outline
                      style={{ borderColor: '#FFFFFF', color: 'rgba(91, 92, 91, 0.5)' }}
                      smFull
                      type={'text'}
                      onClick={() => {
                        setModal(false)
                      }}
                    >
                      {t('myHealth.no')}
                    </Button>
                  </ContainerCancelButton>
                </Row>      </div>}

            {/* <div>
          <KeraltyButton type={'submit'} full   onClick={()=>setModal(false)}
          >
          {t('home.modalButton') }
          </KeraltyButton>

          <KeraltyButton type={'submit'} full
           onClick={()=>setModal(false)}  >
          cancel
          </KeraltyButton>
        </div>
          {/* <KeraltyText color='#0069A7' type="info" align="center" bold='true' underline='true'  onClick={()=>setModal(false)}  >
          {t('home.modalAnnual')}

          </KeraltyText> */}

          </Modal>
        }

      </ContainModal>
    )
  }


  const downloadPdf = (dat) => {
    const body = {
      "patientId": dat.patientId,
      "encounterId": dat.encounterId,
      "beginDate": beginDate,
      "endDate": endDate,
      "birthDate": user.birthdate,
      "language": i18n.language,
    };
    const headers = {
      "Content-Type": "application/json",
      "recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
    };
    return PrivateHttpClient.post(`${endpoint}/patient/visit-summaries/pdf`, body, { headers: headers })
      .then((res) => {
        if (res) {
            const linkSource = `data:application/pdf;base64,${res}`;
            let downloadLink = document.createElement("a");
            const dateBD = dat.appointmentDate;
			const dateFormat= moment(dateBD).format('MM/DD/YYYY');
            downloadLink.href = linkSource;
            downloadLink.download = `${t('myHealth.textVisitSummary')} ${dat.appointmentProvider} ${dateFormat}`;
            downloadLink.click();
        }
      }
      ).catch((e) => {
      })
      ;
  }
  const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
    contentType = contentType || '';
    var byteCharacters = atob(b64Data);
    var byteArrays: any = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  if (isEmpty)
    return (
      <EmptyIconWrap>
        <EmptyIcon text={t('myHealth.noResults')} />
      </EmptyIconWrap>
    );

  const DownloadPdfModal = () => (
    <ContainModal>
      <Modal isMobile={isMobile}>
        <div style={{ padding: '10px' }}>
          <KeraltyIcon iconClass="fas fa-exclamation" size="medium" color="accent" title={t('imgNicons.paperPlaneIcon')} rounded />
        </div>

        {isMobile
          ? (
            <div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
              <KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
                {t('myHealth.saved')}
              </KeraltyText>
            </div>
          )
          : (
            <div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px', width: '60%' }}>
              <KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
                {t('myHealth.saved')}
              </KeraltyText>
            </div>
          )
        }

        <div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
          <KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova' width='20px' >
            {t('myHealth.want')}
          </KeraltyText>
        </div>

        {isMobile
          ? (
            <Row>
              <ContainerFilterButton lg={12} md={6} sm={6} >
                <Button
                  smFull
                  type={'submit'}
                  onClick={() => {
                    downloadPdf(itemToDownload)
                    setModal(false)
                  }}
                >
                  {t('myHealth.yes')}
                </Button>
              </ContainerFilterButton>
              <ContainerCancelButton lg={0} md={6} sm={6}>
                <Button
                  outline
                  smFull
                  type={'text'}
                  onClick={() => {
                    setModal(false)
                  }}
                >
                  {t('myHealth.no')}
                </Button>
              </ContainerCancelButton>
            </Row>
          )
          : (
            <div style={{ textAlign: 'center', padding: '5px' }}>
              <Row>
                <ContainerFilterButton >
                  <Button
                    smFull
                    type={'submit'}
                    onClick={() => {
                      downloadPdf(itemToDownload)
                      setModal(false)
                    }}
                  >
                    {t('myHealth.yes')}
                  </Button>
                </ContainerFilterButton>
                <ContainerCancelButton>
                  <Button
                    outline
                    smFull
                    type={'text'}
                    onClick={() => {
                      setModal(false)
                    }}
                  >
                    {t('myHealth.no')}
                  </Button>
                </ContainerCancelButton>
              </Row>
            </div>
          )
        }
      </Modal>
    </ContainModal>
  );

  return (
    <>
      { modal && <DownloadPdfModal/> }
      <PaginateItems isCenter={true} setToStartPage={initPage}>
        {cardsList
          ? cardsList.map((card) => {
            return (
              <VisitSummaryCard>
                <ColumnIcon>
                  <IconLinkRow
                    color={'primary'}
                    iconSize={3}
                    nounderline
                    icon={pagesIcon}
                    iconAlt={t('myAccount.nameOfInsurance')}
                    text={''}
                    bold={true}
                  />
                </ColumnIcon>
                <Row style={{ marginLeft: 15 }} >
                  <div style={{ flexDirection: 'column', width: isMobile ? 'auto' : '30%' }}>
                    <div>
                      {isMobile ? <> <Image src={Archive} height={3} />
                        <span style={{ fontSize: '14px', fontWeight: '600', color: '#055293', marginLeft: 10 }}>{card.appointmentProvider}</span> </>
                        :
                        <span style={{ marginRight: '10px', fontSize: '14px', fontWeight: '600', color: '#055293', marginLeft: 10 }}>{card.appointmentProvider}</span>}

                    </div>
                    {isMobile ? <div style={{ marginTop: 10 }}>
                      <Icon className="fa fa-calendar-day" style={{ marginLeft: 10 }} ></Icon>
                      <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummartDate')}</span>
					  <span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5, width: '300px' }}>{moment(card.appointmentDate).format(FORMATS.date)} - {moment(card.startTime, 'hh:mm:ss').format('hh:mm A')}</span></div>
                      :
                      <div style={{ marginTop: 10 }}><Icon className="fas fa-user-tag" style={{ marginLeft: 10 }} ></Icon>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummaryReason')}</span>
                        <span style={{ marginRight: '10px', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{card.reason}</span></div>
                    }

                    {isMobile ? <div style={{ marginTop: 10 }}><Icon className="fas fa-user-tag" style={{ marginLeft: 10 }} ></Icon>
                      <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummaryReason')}</span>
                      <span style={{ marginRight: '10px', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{card.reason}</span></div>
                      :
                      <div style={{ marginTop: 10 }}>
                        <Icon className="fas fa-map-marker-alt" style={{ marginLeft: 10 }} ></Icon>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummaryLocation')}</span>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{card.medicalCenter}</span>
                      </div>
                    }
                  </div>
                  <RowInfo style={{ flexDirection: 'column' }} >
                    <div>
                      {isMobile ? <div >
                        <Icon className="fas fa-map-marker-alt" style={{ marginLeft: 10 }} ></Icon>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummaryLocation')}</span>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{card.medicalCenter}</span>
                      </div>
                        :
                        <div><Icon className="fa fa-calendar-day" style={{ marginLeft: 10 }} ></Icon>
                          <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummartDate')}</span>
						  <span style={{ marginRight: '10px', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{moment(card.appointmentDate).format(FORMATS.date)} - {moment(card.startTime, 'hh:mm:ss').format('hh:mm A')}</span></div>
                      }

                    </div>
                    {isMobile ? <div style={{ marginTop: 10 }}>
                      <Icon className="fas fa-map-marker-alt" style={{ marginLeft: 10 }} ></Icon>
                      <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummaryAddress')}</span>
                      <span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{card.medicalCenterAddress}</span>
                    </div>
                      :
                      <div style={{ marginTop: 10 }}>
                        <Icon className="fas fa-notes-medical" style={{ marginLeft: 10 }} ></Icon>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummaryType')}</span>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{card.visitType}</span>
                      </div>
                    }
                    {isMobile ? <div style={{ marginTop: 10 }}>
                      <Icon className="fas fa-notes-medical" style={{ marginLeft: 10 }} ></Icon>
                      <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummaryType')}</span>
                      <span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{card.visitType}</span>
                    </div>
                      :
                      <div style={{ marginTop: 10 }}>
                        <Icon className="fas fa-map-marker-alt" style={{ marginLeft: 10 }} ></Icon>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.visitSummaryAddress')}</span>
                        <span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{card.medicalCenterAddress}</span>
                      </div>
                    }


                  </RowInfo>


                  {isMobile ? <div style={{ marginTop: 10, marginLeft: '14.5%' }}>
                    <ContentButton >
                      <div  >
                        <PdfButton
                          onClick={() => {
                            setItemToDownload(card)
                            setModal(true)
                          }}
                        >
                          {t('myHealth.pdf2')}
                        </PdfButton>
                      </div>
                    </ContentButton>
                  </div>
                    :
                    <div style={{ marginTop: 23, position: 'absolute', marginLeft: '60%' }}>
                      <ContentButton>
                        <PdfButton
                          onClick={() => {
                            setItemToDownload(card)
                            setModal(true)
                          }}>
                            {t('myHealth.pdf2')}
                        </PdfButton>
                      </ContentButton>
                    </div>
                  }

                </Row>
              </VisitSummaryCard>
            );
          })
          : ''}
      </PaginateItems>
    </>

  );
};

export default VisitSummaryCards;
