import { lazy, Suspense, useCallback, useMemo, useState } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
// UI
import { KeraltyStepper } from '../../components/molecules';
import { Page, Container, Loader } from '../../components/templates';
// LOGIC
import CaptchaProvider from '../../contexts/CaptchaProvider';
import { useStepper } from '../../hooks/useStepper';
// ADAPTER
import { useAppDispatch, useAppSelector } from '../../../adapter/hooks';
import { registerActions } from '../../../adapter/register/registerSlice';
import registerSelectors from '../../../adapter/register/registerSelectors';
// ROUTES
import ROUTES from '../../ui-core/utils/routes';
import FB from 'domain/valueObjects/floridaBlue';
import PrivateHttpClient from 'infrastructure/keraltyApi/privateHttpClient';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
const { REGISTER: CURRENT, MAIN } = ROUTES;


const RegisterSanitasPage = () => {
	const { t } = useTranslation();
	const { replace, push, goBack } = useHistory();
	const { step } = useParams();
	const dispatch = useAppDispatch();
	const asyncError = useAppSelector(registerSelectors.selectError);
	const formValues = useAppSelector(registerSelectors.selectUserInfo);
	const userId = useAppSelector(registerSelectors.selectUserId);
	const isLoading = useAppSelector(registerSelectors.selectIsLoading);
	const partialRegister = useAppSelector(registerSelectors.selectPartialRegister);
	const endpoint = 'auth';
	const [errorVal, setErrorVal] = useState('');
	const [isViaCode, setIsViaCode] = useState(false);
	const [value, setValue] = useState(5);
	const [smsCode, setSmsCode] = useState('');
	const [timerLimit, setTimerLimit] = useState(30);
	const [error, setError] = useState(false);
	const [response, setResponse] = useState({
		"cause": "VERIFIED",
		"tries": 0,
		"timer": 0
	});


	const handleCleanAsyncError = useCallback(() => {
		dispatch(registerActions.setProp({ error: undefined }))
	}, [dispatch]);

	const justFirstQuestion = useMemo(() => step === CURRENT.hadSanitas, [step]);
	const isMedicalServices = useMemo(() => [CURRENT.hadSanitas, CURRENT.accountType].includes(step), [step]);

	/* console.log('justFirstQuestion --> ',justFirstQuestion);
	console.log('isMedicalServices --> ', isMedicalServices);
	console.log('step --> ', step); */

	const steps = useMemo(() => [
		{
			label: '', route: CURRENT.secondSent, hidden: true,
			component: lazy(() => import('./organisms/SecondSent'))
		},
		{
			label: t('register.medicalServices'), route: CURRENT.hadSanitas,
			component: lazy(() => import('./organisms/MedicalServices'))
		},
		...((!isMedicalServices && formValues?.hadSanitas !== undefined) ?
			[
				...(formValues?.hadSanitas ?
					[{
						label: t('register.accountInformation'), route: CURRENT.account,
						component: lazy(() => import('./organisms/AccountInformation'))
					}]
					:
					[{
						label: t('register.personalInfo'), route: CURRENT.basic,
						component: lazy(() => import('./organisms/BasicPersonalInfoForm'))
					}]
				),
				...(formValues?.hadSanitas ?
					[{
						label: t('register.contactSecurity'), route: CURRENT.confirm,
						component: lazy(() => import('./organisms/ConfirmLoginForm')),
						config: { shouldOmitEmail: !formValues?.hadSanitas, shouldOmitPhone: !formValues?.hadSanitas }
					}]
					:
					[{
						label: t('register.security'), route: CURRENT.confirm,
						component: lazy(() => import('./organisms/ConfirmLoginForm')),
						config: { shouldOmitEmail: !formValues?.hadSanitas, shouldOmitPhone: !formValues?.hadSanitas }
					}]
				),
				{
					label: t('register.verification'), route: CURRENT.contact,
					component: lazy(() => import('./organisms/ContactInfoForm')),
					config: { emailDisabled: formValues.emailDisabled, phoneDisabled: formValues.phoneDisabled }
				},
				{
					label: t('register.verification'), route: CURRENT.codeSent, hidden: true,
					component: lazy(() => import('./organisms/CodeSent'))
				},
				...(formValues?.contactMethod === 'phone' ?
					[{
						label: '', route: CURRENT.verify, hidden: false,
						component: lazy(() => import('./organisms/VerifySMSCode'))
					},
					]
					:
					[{
						label: '', route: CURRENT.oneMore, hidden: false,
						component: lazy(() => import('./organisms/OneMoreStep'))
					}
					]
				)
			]
			:
			[]
		),
	], [t, justFirstQuestion, isMedicalServices, formValues]);

	//console.log({steps});

	const handleFinish = useCallback(() => {
		push(`/${MAIN.patientRegistration}`);
	}, [push]);

	//#region FLOW ACTIONS
	const { currentStep, Step: FormStep, next } = useStepper(0, steps);

	const handleSuccessStep = async (value) => {
		//console.debug('Submit values =>', value);
		dispatch(registerActions.updateUserInfo(value));

		let res;
		switch (step) {
			case CURRENT.hadSanitas:
				setErrorVal('');
				// TEMP FIX
				dispatch(registerActions.updateUserInfo({
					accountNumber: undefined, birthdate: undefined, firstName: undefined, lastName: undefined,
					gender: undefined, state: undefined, email: undefined, cellphone: undefined
				}));
				if (value.hadSanitas) push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.account}`);
				else push(`/${MAIN.register}/${CURRENT.public}/${CURRENT.basic}`);
				break;
			case CURRENT.account:
				if(!value?.birthdate) return;

				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_USER, value?.accountNumber);
				const splitedBirth = value?.birthdate.split('/');
				const body3 = {
					"accountNumber": value?.accountNumber,
					"dateOfBirth": `${splitedBirth[2]}-${splitedBirth[0]}-${splitedBirth[1]}`
				};
				const headers3 = {
					"Content-Type": "application/json",
					"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
				};
				try{
					res = await PrivateHttpClient.post(`${endpoint}/createuser/verifyCodeEcw`, body3, { headers: headers3 })
					if (res.cause === 'VERIFIED' || res.cause === 'ATTEMPTS' || res.cause === 'TRIES') {
						setResponse(res)
						push(CURRENT.secondSent)
						return
					}

					KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.REPLACE_ROUTE, 'account');
					setErrorVal('');
					res = await dispatch(registerActions.validateAccount(value)).unwrap();
					KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE);
					if (res?.state) KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE, res.state);
					if (res) push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.confirm}`);

				}catch(e: any){
					setErrorVal(`${e?.message}`);
					setResponse({
						"cause": "SUCCESS",
						"tries": 0,
						"timer": 0
					})
					await dispatch(registerActions.validateAccount(value)).unwrap();
				}

				break;
			case CURRENT.basic:
				try{
					KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.REPLACE_ROUTE, 'basic');
					KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.USER_ID_REGISTER)
					KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_ID)
					setErrorVal('');
					res = await dispatch(registerActions.matchAccountInfo(value)).unwrap().catch((e: any) => {});
					KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.EMAIL, value.email);
				}catch(e){
					await dispatch(registerActions.matchAccountInfo(value)).unwrap();
				}

				if(!value?.birthdate || !res) return;

				const splitedBirth_basic = value?.birthdate.split('/');
				const body3_basic = {
					"accountNumber": res?.accountNumber,
					"dateOfBirth": `${splitedBirth_basic[2]}-${splitedBirth_basic[0]}-${splitedBirth_basic[1]}`
				};
				const headers3_basic = {
					"Content-Type": "application/json",
					"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
				};

				const res2: any = await PrivateHttpClient.post(`${endpoint}/createuser/verifyCodeEcw`, body3_basic, { headers: headers3_basic })
				if (res2?.cause === 'VERIFIED' || res2?.cause === 'ATTEMPTS' || res2?.cause === 'TRIES') {
					setResponse(res2)
					push(CURRENT.secondSent)
					return
				}

				if (res?.state) KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE, res.state)
				if (res?.matchEmail || res?.matchPhone)
					push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.confirm}`);

				break;
			case CURRENT.confirm:
				setErrorVal('');
				if (!formValues?.hasAccountNumber) push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.contact}`);
				else {
					res = await dispatch(registerActions.validateAccountContact(value)).unwrap();
					dispatch(registerActions.updatePartialRegister({state: res.state}));
					KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.EMAIL, value.email);
					if (res?.state) KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE, res.state);
					if (res?.cause === 'VERIFIED' || res?.cause === 'ATTEMPTS' || res?.cause === 'TRIES') {
						setResponse(res)
						push(CURRENT.secondSent)
						return
					}
					if (res.userId && res.userId != FB.USER_NO_FB){
						KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ID_REGISTER, res.userId)
						setResponse({
							"cause": "SUCCESS",
							"tries": 0,
							"timer": 0
						})
					}
					if (res?.matchEmail || res?.matchPhone){
						setResponse({
							"cause": "SUCCESS",
							"tries": 0,
							"timer": 0
						})
						push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.contact}`);
					}
				}
				break;
			case CURRENT.contact:
				setErrorVal('');
				KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_ID)
				res = await dispatch(registerActions.initialSaveByAccount()).unwrap();
				dispatch(registerActions.updatePartialRegister({tempSessionId: res.tempSessionId}));
				sessionStorage.setItem(SESSION_STORAGE_ITEMS.TEMP_SESSION, JSON.stringify({...partialRegister, tempSessionId: res.tempSessionId}));
				if (res?.cause === 'VERIFIED' || res?.cause === 'ATTEMPTS' || res?.cause === 'TRIES') {
					setResponse(res)
					push(CURRENT.secondSent)
					return
				}
				if (res?.accountNumber) {
					KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_ID, res.accountNumber)
					setResponse({
						"cause": "SUCCESS",
						"tries": 0,
						"timer": 0
					})
				}
				if (res) {
					setResponse({
						"cause": "SUCCESS",
						"tries": 0,
						"timer": 0
					})
					push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.codeSent}`);
				}
				break;
			case CURRENT.codeSent:
				push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.verify}`);
				break;
			case CURRENT.verify:
				setErrorVal('');
				const body2 = {
					"email": formValues?.email,
					"byEmail": (formValues?.contactMethod === 'phone' ? false : true),
					"state": formValues?.state ?? KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE),
					"code": value?.smsCode,
				};
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.CODE, value?.smsCode)
				setSmsCode(value?.smsCode)
				const headers2 = {
					"Content-Type": "application/json",
					"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
				};
				try {
					res = await PrivateHttpClient.post(`${endpoint}/register/triesToBlock`, body2, { headers: headers2 })
					setIsViaCode(true)
					setResponse(res)
					if(res?.cause === 'VERIFIED') {
						setValue(200)
						next()
					}
					if (res?.tries == 5) push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.secondSent}`);
					if (res?.tries <= 4) {
						if (res.tries == 0) {
							setValue(0)
							push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.secondSent}`);
						}
						timeOff(res.tries, res.timer)
					}
				} catch (error: any) {
					if (error.code === '160' || error.code === 160 || error.message === '160' || error.message === 160) {
						setValue(160)
						// next()
						push('second-sent')
					}

					if (error.code === 500 || error.code === '500' || error.message === '500' || error.message === 500) {
						setValue(160)
						// next()
						push('second-sent')
					}
				}
				break;
			case CURRENT.oneMore:
				setErrorVal('');
				const body = {
					"email": formValues?.email,
					"byEmail": (formValues?.contactMethod === 'phone' ? false : true),
					"state": formValues?.state ?? KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE),
					"code": value?.smsCode,
				};
				KeraltySWS?.swInstance?.setItem('code', value?.smsCode)
				setSmsCode(value?.smsCode)
				const headers = {
					"Content-Type": "application/json",
					"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
				};
				try {
					res = await PrivateHttpClient.post(`${endpoint}/register/triesToBlock`, body, { headers: headers })
					setIsViaCode(false)
					setResponse(res)
					if(res?.cause === 'VERIFIED' || res?.cause === 'ATTEMPTS' || res?.cause === 'TRIES') {
						setValue(200)
						next()
					}

					if (res?.tries == 5) push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.secondSent}`);
					if (res?.tries <= 4) {
						if (res.tries == 0) {
							setValue(0)
							push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.secondSent}`);
						}
						timeOff(res.tries, res.timer)
					}
				} catch (error: any) {

					if (error.code === '160' || error.code === 160 || error.message === '160' || error.message === 160) {
						setValue(160)
						// next()
						push('second-sent')
					}

					if (error.code === 500 || error.code === '500' || error.message === '500' || error.message === 500) {
						setValue(160)
						// next()
						push('second-sent')
					}
				}
				break;
			case CURRENT.secondSent:
				const code = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CODE)
				const email = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.EMAIL)

				try {
					// const response = await PrivateHttpClient.get(`${endpoint}/register/eligibilitydata/${smsCode || code}/${formValues.email || email}`)
					let infoData: any = { id: smsCode || code, email: formValues.email || email };
					const userIdRegister = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.USER_ID_REGISTER);

					if (isViaCode) {
						if (userIdRegister) {
							res = await dispatch(registerActions.loadMaxUserInfoByCode(infoData)).unwrap();
						} else {
							res = await dispatch(registerActions.loadUserInfoByCode({ code: smsCode || code || '', email: formValues?.email || email, state: formValues?.state || KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE) })).unwrap();
						}
					} else {
						if (userIdRegister) {
							res = await dispatch(registerActions.loadMaxUserInfo(infoData)).unwrap();
							const { contractNumber } = res;
                            dispatch(registerActions.updateUserInfo({contractNumber}));
						} else {
							res = await dispatch(registerActions.loadUserInfo({ code: smsCode || code || '', email: formValues?.email ?? email, state: formValues?.state || KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE) })).unwrap();
						}
					}
					push(`/${MAIN.patientRegistration}`);
				}catch( error: any ){
					if(error.message === '100' || error.message === '80'){
						setError(true)
					}
					return
				}

				break;
		}
	};

	const timeOff = (tries, timer) => {
		setTimerLimit(timer)
		if (tries > 0) {
			if (tries == 1) setErrorVal(t('register.trier') + tries + t('register.trier2'));
			else setErrorVal(t('errors.code150'));
		} else if (tries == 0) {
			setErrorVal(`${t('myAccount.limit1')} ${timer} ${t('myAccount.limit2')}`)
		}
	}

	const handleMissingData = () => replace(`/${MAIN.register}`);
	//#endregion

	//#region UI LOGIC
	const title = useMemo(() => {
		const routesWithoutTitle: string[] = [CURRENT.codeSent, CURRENT.secondSent, CURRENT.errorVerify];
		if (routesWithoutTitle.indexOf(steps[currentStep]?.route) >= 0) return '';

		return t('register.createAccount');
	}, [t, currentStep, steps]);

	const handleGoBack = useCallback(() => {
		handleCleanAsyncError();
		goBack();
	}, [handleCleanAsyncError, goBack]);

	const routeNames = useMemo(() => title ? [
		{ label: t('routes.login'), callback: () => replace(`/${MAIN.login}`) },
		{ label: title || t('routes.register') }
	] : [], [t, title, replace]);
	//#endregion

	return (
		<CaptchaProvider>
			{isLoading && <Loader />}
			<Page
				title={title}
				routes={routeNames}
				onPrevious={!justFirstQuestion ? () => handleGoBack() : undefined}
				previousLabel={t('routes.back')}
				sendCodeResponse={response} >
				<Container centered={'xy'} noPadding>
					<KeraltyStepper steps={steps} currentRoute={true} activeStep={currentStep} noPadding>
						<Suspense fallback={<Loader />}>
							<FormStep
								timer={timerLimit}
								formValues={formValues}
								errorVal={errorVal}
								onSuccess={handleSuccessStep}
								asyncError={asyncError}
								cleanAsyncError={handleCleanAsyncError}
								onMissingData={handleMissingData}
								value={value}
								config={steps[currentStep]?.config}
								setResponse={setResponse}
								response={response}
								error={error} />
						</Suspense>
					</KeraltyStepper>
				</Container>
			</Page>
		</CaptchaProvider>
	);
};

export default RegisterSanitasPage;
