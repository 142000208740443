import { ImageWidget, KeraltyTextDescription, SectionWidget } from './styles';
import KeraltyText from '../keraltyText';
import useIsMobile from 'app/hooks/useIsMobile';

const HomeSectionWidget = (props) => {
  const isMobile = useIsMobile();
  return (
     <SectionWidget innerWidth={window.innerWidth}>
       <ImageWidget src={props.img} alt={props.alt} />
       <KeraltyText type="info" color="primary" style={{ textAlign: 'center', fontWeight: isMobile ? '600' : '400' }} adaptable={false}>
         {props.text}
       </KeraltyText>
       {props.description && (
         <KeraltyTextDescription
           type="info"
           color="darkGray"
           bold
           adaptable={false}
		   isMobile={isMobile}
         >
           {props.description}
         </KeraltyTextDescription>
       )}
     </SectionWidget>
  );
};
export default HomeSectionWidget;
