import { useTranslation } from 'react-i18next';

import { Column } from '../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../atoms';
import { KeraltyModal } from 'app/components/molecules';

import theme from '../../../ui-core/utils/theme';

import Icon1 from '../../../assets/icons/onBoard1.svg';
import Icon2 from '../../../assets/icons/onBoard2.svg';
import Icon3 from '../../../assets/icons/onBoard3.svg';
import Icon4 from '../../../assets/icons/onBoard4.svg';
import Icon5 from '../../../assets/icons/onBoard5.svg';
import prevWhite from '../../../assets/icons/prevWhite.svg';
import nextWhite from '../../../assets/icons/nextWhite.svg';

import { useAppDispatch } from 'adapter/hooks';
import useIsMobile from '../../../hooks/useIsMobile';
import i18n from 'i18next';
import { DataOnboarding, OnboardingProps } from './MentalHealth.types';
import { useState } from 'react';

const footerContentStl = {
  display: 'flex',
  //flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 25,
};
const contentBodyStl = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
};
const btnPevStl = {
  height: 59,
  paddingTop: '3px',
  paddingLeft: '14px',
  paddingRight: '14px',
  border: '7px solid #e2f3dc',
  marginInline: 5,
  borderRadius: 29,
};
const btnNextStl = {
  height: 59,
  paddingTop: '3px',
  paddingLeft: '15px',
  paddingRight: '14px',
  border: '7px solid #e2f3dc',
  marginInline: 5,
  borderRadius: 29,
};

const ModalOnboarding = (onboardingProps: OnboardingProps) => {
  const { start, skip } = onboardingProps;
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);
  const isMobile = useIsMobile();
  const language = i18n.language.includes('en') ? 'en' : 'es';
  const { t } = useTranslation();

  const buttonContentStl = {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: isMobile ? '0 auto' : '11%',
    marginRight: isMobile ? '0 auto' : '7%',
    marginTop: 20,
  };
  const btnLetsStl = {
    height: isMobile ? 41 : '',
    marginBottom: isMobile ? '15px' : '',
    marginRight: isMobile ? 20 : '',
    marginTop: isMobile ? 5 : '',
  };

  const data: DataOnboarding[] = [
    {
      id: 0,
      subTitle: '',
      text: t('mentalHealth.OnBoardText1'),
      icon: Icon1,
    },
    {
      id: 1,
      subTitle: t('mentalHealth.OnBoardSubTitle2'),
      text: t('mentalHealth.OnBoardText2'),
      icon: Icon2,
    },
    {
      id: 2,
      subTitle: t('mentalHealth.OnBoardSubTitle3'),
      text: t('mentalHealth.OnBoardText3'),
      icon: Icon3,
    },
    {
      id: 3,
      subTitle: t('mentalHealth.OnBoardSubTitle4'),
      text: t('mentalHealth.OnBoardText4'),
      icon: Icon4,
    },
    {
      id: 4,
      subTitle: '',
      text: t('mentalHealth.OnBoardText5'),
      text2: t('mentalHealth.OnBoardText6'),
      text3: t('mentalHealth.OnBoardText7'),
      text4: t('mentalHealth.OnBoardText8'),
      icon: Icon5,
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);

  const handlePrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, data.length - 1));
  };

  const Footer = () => {
    const isCurrent = (item: number) => {
      if (item == currentPage) return true;
      return false;
    };
    return (
      <div style={footerContentStl}>
        {data.map((item) => (
          <div
            key={item.id}
            style={{
              backgroundColor: isCurrent(item.id) ? theme.colors.accent : theme.colors.gray,
              height: 8,
              width: isCurrent(item.id) ? 21 : 8,
              borderRadius: 4,
              marginRight: 12,
            }}
          >
            {' '}
          </div>
        ))}
      </div>
    );
  };

  return (
    <KeraltyModal size="media" scroll="initial">
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
        <Column lg={12} style={{ marginInline: isMobile ? '0 auto' : '11%' }}>
          <KeraltyText
            type="subTitle"
            align="center"
            style={{ fontSize: isMobile ? 20 : language == 'en' ? 24 : 20 }}
            color={isMobile ? 'blueTitle' : ''}
            bold
            pb
          >
            {t('mentalHealth.OnBoardTitle')}
          </KeraltyText>
          <Column lg={12} style={contentBodyStl}>
            <Image src={data[currentPage].icon} alt={t('imgNicons.myAccount')} />
          </Column>
          {isMobile && (
            <div style={{ marginTop: 15 }}>
              <Footer />
            </div>
          )}
          <KeraltyText type="subTitle" align="left" bold pb style={{ marginTop: 15 }}>
            {data[currentPage].subTitle}
          </KeraltyText>
          {currentPage == 4 && (
            <>
              <KeraltyText
                align="left"
                type="info"
                color={isMobile ? 'dark' : 'TEXTCOLORLIGHT'}
                style={{ fontSize: isMobile ? 16 : language == 'en' ? 18 : 14 }}
              >
                {data[currentPage].text}
              </KeraltyText>
              <KeraltyText
                align="left"
                type="info"
                bold
                color={isMobile ? 'dark' : 'TEXTCOLORLIGHT'}
                style={{ fontSize: isMobile ? 16 : language == 'en' ? 18 : 14 }}
              >
                {data[currentPage].text2}
              </KeraltyText>
              <KeraltyText
                align="left"
                type="info"
                color={isMobile ? 'dark' : 'TEXTCOLORLIGHT'}
                style={{ fontSize: isMobile ? 16 : language == 'en' ? 18 : 14 }}
              >
                {' '}
                {data[currentPage].text3}
              </KeraltyText>
              <KeraltyText
                align="left"
                type="info"
                bold
                color={isMobile ? 'dark' : 'TEXTCOLORLIGHT'}
                style={{ fontSize: isMobile ? 16 : language == 'en' ? 18 : 14 }}
              >
                {data[currentPage].text4}
              </KeraltyText>
            </>
          )}
          {currentPage != 4 && (
            <KeraltyText
              align="left"
              type="info"
              color={isMobile ? 'dark' : 'TEXTCOLORLIGHT'}
              style={{ fontSize: isMobile ? 16 : language == 'en' ? 18 : 14 }}
            >
              {data[currentPage].text}
            </KeraltyText>
          )}
        </Column>
      </div>
      <Column style={buttonContentStl}>

        <div style={{ paddingTop: isMobile ? 15 : 20, paddingLeft: isMobile ? 10 : '' }}>
          <KeraltyText
            type="link"
            bold
			undecorated
            onClick={currentPage == 4 ? handlePrevious : skip }
            style={{ textTransform: isMobile ? '' : 'uppercase' }}
            color={isMobile ? 'blueAction' : 'accent'}
          >
            {currentPage == 4 ? t('mentalHealth.back'):t('mentalHealth.skip') }
          </KeraltyText>
        </div>
        <div>
          {currentPage > 0 && currentPage < 4 && (
              <KeraltyButton
                disabled={currentPage === 0}
                onClick={handlePrevious}
                style={btnPevStl}
                children={<Image src={prevWhite} size={1.5} alt={t('imgNicons.myAccount')} />}
              />
          )}

          {currentPage < 4 && (
            <KeraltyButton
              disabled={currentPage === data.length - 1}
              onClick={handleNext}
              style={btnNextStl}
              children={<Image src={nextWhite} size={1.4} alt={t('imgNicons.myAccount')} />}
            />
          )}
          {currentPage == 4 && (
            <KeraltyButton onClick={start} style={btnLetsStl}>
              {t('mentalHealth.lets')}
            </KeraltyButton>
          )}
        </div>
      </Column>
      {!isMobile && <Footer />}
    </KeraltyModal>
  );
};

export default ModalOnboarding;
