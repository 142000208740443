import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';

interface KeraltyButtonProps {
	theme: any;
	children?: any | any[];
	style?: any;
	outline?: boolean;
	color?: keyof (typeof theme.colors);
	full?: boolean;
	auto?: boolean;
	disabled?: boolean;
	squareRadius?: boolean;
	smFull?: boolean;
	noBorders?: boolean;
	fontWeight?: string;
	bgcolor?: keyof (typeof theme.colors);
	[key: string]: any;
	fontSize?: string;
	width?: string;
	withShadow?: boolean;
	withoutTextTransform?: boolean;
	disabledColor?: string
};

const Button = ({ smFull, full, outline, disabled, auto, squareRadius, noBorders, ...props }: KeraltyButtonProps) => (
	<button {...props} disabled={disabled}>{props.children}</button>
);

const KeraltyButton = styled(Button) <KeraltyButtonProps>`
	/* max-width: 335px; */
	width: ${({ width }) => width ? width : ''};
	font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif;
	cursor: pointer;
    min-height: 3.75rem;
    border-radius: ${({ squareRadius }) => squareRadius ? '.33' : '2.5'}rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
	padding: ${({ squareRadius }) => squareRadius ? '0px 1.5rem' : '0px 30px'};
	font-size: ${({ fontSize }) => fontSize ? fontSize : '1.5rem'};
	line-height: 1.9rem;
	text-align: center;
	font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : '100' };
	text-transform: ${({ withoutTextTransform }) => !withoutTextTransform ? 'uppercase' : 'none' };
	box-shadow: ${({ withShadow }) => withShadow ? '1px 5px 5px #002D574D' : '' };
	word-break: break-word;

	${({ color = 'accent', outline, disabled, full, smFull, auto, noBorders, bgcolor, theme: { colors }, disabledColor }) => `
		border: solid 2px ${colors[color]};
		${outline ? `
			background-color: ${colors.light};
			border: solid 2px ${colors[color]};
			color: ${colors[color]};
		` : `
			background-color: ${colors[color]};
			color: ${colors.light};
		`}

		&:hover {
			border-color: ${colors[`${color}Contrast`]};
			background-color: ${colors[`${color}Contrast`]};
			color: ${colors.light};
		}

		&:active {
			border-color: ${colors[color]};
			background-color: ${colors[color]};
			color: ${colors.light};
		}

		${disabled ? `
			cursor: default;
			background-color: ${ disabledColor ? disabledColor : colors.disabled } !important;
			border: solid 2px ${ disabledColor ? 'transparent' : colors.disabled } !important;
			color: ${colors.darkGray} !important;
		` : ''}

		${full ? 'width: calc(100% - 1rem); margin: 2rem .5rem;' : ''}
		${auto ? 'width: auto;' : ''}
		${noBorders ? 'border: none;' : ''}
		${bgcolor ? `background-color: ${colors[bgcolor]};` : ''}

		@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			${smFull ? 'width: calc(100% - 2rem); margin-left: 1rem; margin-right: 1rem;' : ''}
		}
	`}
`;

export default KeraltyButton;
