import styled from "app/ui-core/styled-components";
import { Column } from "app/components/templates";

export const styleIcon = {
	margin: 20,
	flex: 1,
	align: "center",
}

export const styleText = {
	textAlign: "center",
	marginRight: 7,
    fontWeight: 400,
}

export const styleDivFirst = {
	marginTop: 0,
	paddingTop: 0,
	paddingBottom: 10,
	marginLeft: 0,
	marginRight: 0,
	flex: 1,
	justifyContent: 'center',
	paddingLeft: 20,
	paddingRight: 20,
}

export const styleButton = {
	color: '#3CA70D',
	marginRight: 10,
	marginTop: 10,
	paddingTop: 0,
	paddingBottom: 0,
	backgroundColor: '#FFFFFF',
	borderRadius: 40,
	border: 'none',
	borderColor: "#FFFFFF",
	fontSize: 16,
	fontWeight: 'bold',
	fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif'
}

export const DoneButton = styled(Column)`
    background: #3ca70d !important;
    border-radius: 25px;
    margin-top: 45px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    align-self: center !important;
    padding: 13px;
    font-size: 18px !important;
    width: 50% ;
    line-height: 1.9rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
`;
