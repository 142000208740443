import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Row } from '../../../templates';
import { KeraltyButton, KeraltyIcon, KeraltyText } from '../../../atoms';
import { Grid, RightContent, TitleContiner } from './Anxiety.styled';
import EntryCard from './components/EntryCard';
import ROUTES from "app/ui-core/utils/routes";
import TitleSection from './components/TitleSection';
import { ReactComponent as cbt } from '../../../../assets/icons/cbt.svg';
import cbtImageEn from '../../../../assets/icons/cbtImageEn.svg';
import cbtImageEs from '../../../../assets/icons/cbtImageEs.svg';
import useIsMobile from 'app/hooks/useIsMobile';
import { useAppDispatch } from 'adapter/hooks';
import { useEffect } from 'react';
import { resetUnderstandingYourAnxiety } from 'adapter/understandingAnxiety/understandingAnxiety.slice';
import i18n from 'i18next';

const Cbt = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const isMobile = useIsMobile();
	const { MENTAL_HEALTH } = ROUTES;
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(resetUnderstandingYourAnxiety());
	}, [])

	return (
		<RightContent fullHeight top='10'>
			<Row style={{ paddingBottom: 20 }}>
				<TitleContiner lg={12}>
					<KeraltyText align={isMobile ? 'left' : 'center'} type="title" fontWeight={isMobile ? '600' : '400'}>{t('mentalHealth.understandingYourAnxiety')}</KeraltyText>
				</TitleContiner>
			</Row>
			<Row>
				<div style={{marginLeft: isMobile ? '10%' : '14%', marginRight: '14%', display: 'grid', placeItems: 'center'}}>
					<Row>
						<TitleSection
							icon={cbt}
							title={t('mentalHealth.cbtTitle')}
							subtitle={''}
						/>
					</Row>
					<section style={{ fontSize: '14px', textAlign: 'justify' }}>
						<p>{t('mentalHealth.firstCbtP')}</p>
						<p dangerouslySetInnerHTML={{ __html: t('mentalHealth.secondCbtP') }}></p>
					</section>
					<section style={{ display: 'grid', placeItems: 'center' }}>
						<img style={{ width: '30rem', height: '30rem' }} src={ i18n.language === 'es' ? cbtImageEs : cbtImageEn} alt="" />
					</section>
				</div>
			</Row>
			<Row style={{ display: 'grid', placeItems: 'center'}}>
				<div style={{ marginTop: 20, marginBottom: 50 }}>
					<KeraltyButton
						onClick={ () => history.replace(`${MENTAL_HEALTH.youMadeIt}`) }
						fontWeight={ '600' }
					>
						{t('button.next')}
					</KeraltyButton>
				</div>
			</Row>

		</RightContent>
	);
};

export default Cbt;
