
import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectSelf = (state: RootState): any => state.myHealth

export const selectmyHealthError = createSelector(selectSelf, ({ error }) => error);
export const selectmyHealthLoading = createSelector(selectSelf, ({ isLoading }) => isLoading);

export const selectmyHealthLabInfo = createSelector(selectSelf, ({ data }) => data?.lab);

export const selectmyHealthMedInfo = createSelector(selectSelf, ({ data }) => data?.medications);
export const selectmyHealthReferrals = createSelector(selectSelf, ({ data }) => data?.referrals);
export const selectmyHealthCurrentMedication = createSelector(selectSelf, ({ data }) => data?.current);
export const selectmyHealthImmunizations = createSelector(selectSelf, ({ data }) => {
  let immunizations = data?.immunization || [];

  // Show only those with vaccineName
  return immunizations.filter(item => item?.vaccineName);
});