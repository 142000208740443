import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { closeSymptomChecker } from '../../../../adapter/chat/chatSlice';
import { utcTime } from 'app/ui-core/utils/times';
// UI
import { MASK } from '../../../ui-core/utils/mask';
import { ReactComponent as SupportChatIcon } from '../../../assets/icons/chat 2.svg';
import Select from 'react-select';
import {
  KeraltyText,
  KeraltyDatePicker,
  KeraltyButton,
  KeraltyIcon,
  KeraltyError,
} from '../../atoms';
import { KeraltyField, KeraltyModal, KeraltyDotChatTrigger } from 'app/components/molecules';
import { Container, Column, Row, Loader, IconLinkRow, FormBox } from '../../templates';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { useHistory, useLocation } from 'react-router-dom';
import * as sanitasAdapterMethods from '../../../../adapter/sanitasChat/sanitasChat';

import PersonalInfoRepository from '../../../../infrastructure/keraltyApi/repositories/auth';
import useAuth from '../../../hooks/useAuth';
import { EditAccountProps, EditAccountInf, ListGender } from './formPersonalInfoTypes';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AvailityInfoSchema } from '../../../ui-core/schemas/availityInfo';
import { AvailityInfo, FormAvailityInfo } from 'domain/entities/availityInfo';
import moment from 'moment';
import FORMATS from 'app/ui-core/utils/formats';
import { GENDERS } from 'app/ui-core/utils/constants';

const styleText2 = {
  textAlign: 'center',
  marginRight: 7,
  fontSize: 24,
  fontWeight: 500,
  color: '#055293',
};

const FormPersonalInfo = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const data = location?.state?.data;
  const marital = location?.state?.marital;
  const { user } = useAuth();
  const { goBack } = useHistory();
  const [ageModalConfirm, setModalConfirm] = useState(false);
  const [ageSuccessModalConfirm, setSuccessModalConfirm] = useState(false);
  const [ageSelectChange, setSelectChange] = useState(false);
  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { authUid } = useAppSelector((state) => state.user);
  const [customError, setCustomError] = useState<string>('');
  const { sanitasState } = sanitasChatState;
  let getValueMarital: string | object = '';
  let getPosition2 = marital?.en.indexOf(data?.maritalStatus);
  let getPosition3 = marital?.es.indexOf(data?.maritalStatus);
  let getMaterial = t('register.lenguage') === 'en' ? marital?.en : marital?.es;
  type FormType = FormAvailityInfo;

  if (getPosition2 != -1) {
    let a = t('register.lenguage') === 'en' ? marital?.en[getPosition2] : marital?.es[getPosition2];
    getValueMarital = { key: getPosition2, value: a, label: a };
  } else if (getPosition3 != -1) {
    let b = t('register.lenguage') === 'en' ? marital?.en[getPosition2] : marital?.es[getPosition2];
    getValueMarital = { key: getPosition3, value: b, label: b };
  } else {
    getValueMarital =
      t('register.lenguage') === 'en'
        ? { key: '', value: 'Marital status', label: 'Marital status' }
        : { key: '', value: 'Estado civil', label: 'Estado civil' };
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    reset,
    setError,
  } = useForm<EditAccountProps>({
    mode: 'onBlur',
    resolver: yupResolver(EditAccountInf),
    defaultValues: {
      isAuthSelected: false,
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobile: data?.mobile,
      homePhone: data?.homePhone,
      dateOfBirth: data?.dateOfBirth,
      address: data?.address1,
      sexAssignedAtBirth: data?.sex,
      city: data?.city,
      email: data?.email,
      state: data?.state,
      maritalStatus: getValueMarital,
      ...data,
    },
  });

  // const genderOptions = useMemo(() => ListGender()?.map(({
  // 	key: key,
  // 	label: label,
  // 	...rest
  // }) => ({
  // 	key,
  // 	label,
  // 	...rest
  // })), [ListGender() ]);

  const genderOptions: any[] = [
    { value: GENDERS.female, label: t('register.gender.female') },
    { value: GENDERS.male, label: t('register.gender.male') },
    { value: GENDERS.unknown, label: t('register.gender.unknown') },
  ];

  const [maritalErrors, setMaritalErrors] = useState(false);
  const [ageMaritalStatus, setValueMaritalStatus] = useState<any>(getValueMarital);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sureToSaveChanges = (e?: any) => {
    e.preventDefault();
	setCustomError('');
    const homePhoneValue = watch('homePhone');
    // Valida numero telefonico menor a 1
    if (homePhoneValue && homePhoneValue.length == 1) {
      setError('homePhone', { message: t('invalidPhone') });
    }
    // Validador de estado civil
    // if (ageMaritalStatus.key === '') {
    //   setMaritalErrors(true);
    // }
    // Validador de campos con errrores
    else if (Object.keys(errors).length === 0) {
      setMaritalErrors(false);
      setModalConfirm(true);
    }
  };

  //Cambio de error en estado civil
  // const methodTransformDateErrorMessage = ($var) => {
  //   if ($var === 'errors.undefined') {
  //     return t('errors.required');
  //   }
  //   return $var;
  // };

  const handleSend = useCallback(
    async (e) => {
      setModalConfirm(false);
      setSuccessModalConfirm(false);
      e.preventDefault();

      try {
        setIsLoading(true);
        let v = true;
        let index = -2;
        if (ageSelectChange) {
          index = 1;
        }

        switch (v) {
          case getValues()?.homePhone !== data?.homePhone:
            index = 1;
            break;
          case getValues()?.mobile !== data?.mobile:
            index = 1;
            break;
          case getValues()?.address !== data?.address1 + ' ' + data?.address2:
            index = 1;
            break;
          case getValues()?.zipCode != data?.zipCode:
            index = 1;
            break;
          case getValues()?.city !== data?.city:
            index = 1;
            break;
        }

        if (index >= 0 || getValues()?.sexAssignedAtBirth != data.sex) {
          const getObj: EditAccountProps = {
            authUid: user.authUid,
            sexAssignedAtBirth: getValues()?.sexAssignedAtBirth,
            maritalStatus: ageMaritalStatus.key === '' ? '' : ageMaritalStatus.value,
            homePhone: getValues()?.homePhone,
            mobile: getValues()?.mobile,
            address: getValues()?.address,
            zipCode: getValues()?.zipCode,
            city: getValues()?.city,
            state: getValues()?.state,
          };

		  try {
			const resp = await PersonalInfoRepository.updatePersonalInfoData({ getObj });
			setSuccessModalConfirm(true);
		  }catch (err: any) {
			setCustomError(err?.message)
		  }

          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [user?.authUid, getValues()?.sexAssignedAtBirth, ageMaritalStatus]
  );

  const triggerPatientSupport = useCallback(async () => {
    dispatch(closeSymptomChecker());
    if (sanitasState.isShow && sanitasState.isSetted) {
      await dispatch(sanitasAdapterMethods.shutdown());
    }

    await setTimeout(async () => {
      await dispatch(
        sanitasAdapterMethods.setUp({
          actionColor: '#00cdac',
          backgroundColor: '#00cdac',
          name: `${user.displayName}`,
          email: `${user.email}`,
          state: `${user.state}`,
          currentTimeZone: `${utcTime()}`,
          authUid: `${authUid}`,
          sanitasAccountNumber: `${user.idEcw}`,
          gender: `${user.sex}`,
          birthday: `${user.birthdate}`,
          customAttributes: { cType: 'support' },
        })
      );
      await dispatch(sanitasAdapterMethods.show());
    }, 500);
  }, [dispatch]);

  const ModalConfirm = () => {
    return (
      <KeraltyModal size="extraLong" textalign="center">
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center', marginTop: '4%' }}>
          <span style={{ backgroundColor: 'orange', padding: '1%', borderRadius: 28 }}>
            <KeraltyIcon iconClass="fas fa-exclamation" color="white" size="small" rounded />
          </span>
        </Row>
        <Row style={{ marginTop: '9%', justifyContent: 'center' }}>
          <KeraltyText fontSize={'20px'} type={'label'} style={{ color: '#002D57' }}>
            {t('formLabel.titleAlertConfirm')}
          </KeraltyText>
        </Row>

        <div className="unblock-account" style={{ margin: '8% 0%' }}>
          <KeraltyButton
            style={{ width: '45%', fontSize: 12, padding: '0' }}
            onClick={handleSend}
            //onClick={ ()=> handleSubmit(handleSend) }
          >
            {t('formLabel.yes')}
          </KeraltyButton>

          <KeraltyButton
            onClick={() => setModalConfirm(false)}
            style={{
              width: '45%',
              fontSize: 12,
              backgroundColor: 'white',
              color: '#3CA70D',
              borderColor: '#3CA70D',
              marginLeft: '10%',
            }}
          >
            {t('formLabel.no')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };

  const ModalSuccess = () => {
    return (
      <KeraltyModal size="long" textalign="center">
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center', marginTop: '2%' }}>
          <KeraltyIcon iconClass="fas fa-check" color="accent" size="small" rounded />
        </Row>
        <Row style={{ margin: '13% 0%', justifyContent: 'center' }}>
          <KeraltyText fontSize={'20px'} type={'label'} color={'primary'}>
            {t('formLabel.titleSuccessConfirm')}
          </KeraltyText>
        </Row>

        <div className="unblock-account" style={{}}>
          <KeraltyButton
            onClick={() => (setSuccessModalConfirm(false), goBack())}
            style={{ paddingLeft: '9%', paddingRight: '9%', fontSize: 13 }}
          >
            {t('formLabel.btnTitleSuccessConfirm')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };

  return (
    <Row>
      <IconLinkRow
        onClick={goBack}
        color="accent"
        icon={arrowAltCircleLeftIcon}
        iconAlt={t('imgNicons.backArrow')}
        text={t('formLabel.btnReturnInformation')}
      />
      {isLoading && <Loader />}

      <section style={{ display: 'grid', placeItems: 'center' }}>
		<FormBox style={{ width: '80%' }}>
			<Container style={{ textAlign: 'center' }}>
			<KeraltyText type={'label'} align={'center'} style={styleText2}>
				{t('formLabel.btnUpdateInformation')}
			</KeraltyText>
			</Container>

			<Row style={{}}>
			<Column lg={6}>
				<KeraltyField
					iconClass={'fas fa-user'}
					title={t('imgNicons.idIcon')}
					placeholder={data?.firstName}
					label={t('formLabel.firstName')}
					color="#747574"
					disabled
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
				iconClass={'fas fa-phone-rotary'}
				title={t('imgNicons.idIcon')}
				mask={watch('homePhone') ? MASK.phone : ''}
				placeholder={t('formLabel.homePhone')}
				label={t('formLabel.homePhone')}
				field={register('homePhone')}
				value={watch('homePhone')}
				error={errors.homePhone}
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
				iconClass={'fas fa-user'}
				title={t('imgNicons.idIcon')}
				placeholder={data?.lastName}
				label={t('formLabel.lastName')}
				color="#747574"
				disabled
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
				iconClass={'fas fa-mobile-alt'}
				title={t('imgNicons.idIcon')}
				placeholder={data?.mobile != '' ? data?.mobile : t('formLabel.movilPhone')}
				label={t('formLabel.movilPhone')}
				field={register('mobile')}
				value={watch('mobile')}
				error={errors.mobile}
				mask={MASK.phone}
				mandatory
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
				control={KeraltyDatePicker}
				customInput={<input {...register('dateOfBirth')} />}
				iconClass={'fas fa-birthday-cake'}
				title={t('imgNicons.calendarIcon')}
				label={t('formLabel.dateOfBirth')}
				color="#747574"
				placeholderText={
					watch('dateOfBirth')
					? moment(watch('dateOfBirth')).format(FORMATS.date)
					: FORMATS.date
				}
				onChange={(v) =>
					setValue('dateOfBirth', v || '', { shouldValidate: true, shouldTouch: true })
				}
				selected={new Date(moment(watch('dateOfBirth')).format(FORMATS.date))}
				disabled
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
				iconClass={'fas fa-map-marker-alt'}
				title={t('imgNicons.idIcon')}
				placeholder={t('formLabel.textAddress')}
				label={t('formLabel.textAddress')}
				field={register('address')}
				error={errors.address}
				mandatory
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
				control={Select}
				iconClass="fas fa-venus-mars"
				title={t('imgNicons.mapMarkedIcon')}
				placeholder={t('formLabel.sex')}
				label={t('formLabel.sex')}
				type={'select'}
				options={genderOptions}
				field={register('sexAssignedAtBirth')}
				value={genderOptions.find(({ value }) => value === watch('sexAssignedAtBirth'))}
				onChange={({ value }) => {
					setValue('sexAssignedAtBirth', value, { shouldValidate: true });
					setSelectChange(true);
				}}
				error={errors.sexAssignedAtBirth}
				mandatory
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
				iconClass={'fas fa-map-marked-alt'}
				title={t('imgNicons.idIcon')}
				placeholder={t('formLabel.zipCode')}
				label={t('formLabel.zipCode')}
				field={register('zipCode')}
				value={watch('zipCode')}
				error={errors.zipCode}
				mask={MASK.zip}
				mandatory
				/>
			</Column>

			<Column lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
				<KeraltyField
				control={Select}
				iconClass="fas fa-rings-wedding"
				title={t('imgNicons.idIcon')}
				placeholder={t('formLabel.maritalStatus')}
				label={t('formLabel.maritalStatus')}
				type={'select'}
				options={getMaterial?.map((v: any, i: number) => {
					return {
					key: i,
					value: getMaterial[i],
					label: v,
					};
				})}
				field={register('maritalStatus')}
				onChange={(v: any) => {
					setValueMaritalStatus(v);
					setMaritalErrors(false);
					setValue('maritalStatus', v.value, { shouldValidate: true });
					setSelectChange(true);
				}}
				value={ageMaritalStatus}
				// error={maritalErrors}
				error={errors.maritalStatus}
				// modifyErrorMessage={($var) => methodTransformDateErrorMessage($var)}
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
				iconClass={'fas fa-city'}
				title={t('imgNicons.idIcon')}
				placeholder={t('formLabel.city')}
				label={t('formLabel.city')}
				field={register('city')}
				error={errors.city}
				mandatory
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
					iconClass={'fas fa-envelope'}
					title={t('imgNicons.idIcon')}
					placeholder={data?.email}
					label={t('formLabel.email')}
					disabled
					color="#747574"
				/>
			</Column>

			<Column lg={6}>
				<KeraltyField
					iconClass={'fas fa-flag-usa'}
					title={t('imgNicons.idIcon')}
					placeholder={data?.state}
					label={t('formLabel.state')}
					field={register('state')}
					error={errors.state}
					color="#747574"
					disabled
				/>
			</Column>

			{
				customError
				&& (
					<Container style={{ justifyContent: 'center', width: '100%', marginTop: '10px' }}>
						{/* ERRORS */}
						<KeraltyError error={t(`errors.code${customError}`)} />
					</Container>
				)
			}

			<Container style={{ justifyContent: 'center', width: '100%', marginTop: '20px' }}>
				<KeraltyButton onClick={sureToSaveChanges}>
				{t('formLabel.btnUpdateInformation')}
				</KeraltyButton>
			</Container>
			</Row>

			{ageModalConfirm ? <ModalConfirm /> : ageSuccessModalConfirm ? <ModalSuccess /> : null}
		</FormBox>
	  </section>

      {/*   <div style={{ width: '20%', justifyContent: 'center', alignItems: 'center' }}>
        <KeraltyDotChatTrigger
          color={'primary'}
          colordot={'primary'}
          colorlabel={'primary'}
          label={t('button.supportChat')}
          position={true}
          right={false}
          svg={SupportChatIcon}
          onClick={() => {
            triggerPatientSupport();
            dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: 'support', state: true }));
          }}
        />
      </div> */}
    </Row>
  );
};

export default FormPersonalInfo;
