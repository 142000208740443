
import useIsMobile from '../../hooks/useIsMobile';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import { Fragment } from 'react';

interface Props {
  className?: string;
  children: JSX.Element
}

const SimpleClassWrapper: React.FC<Props> = ({ className, children }) => (
  <div className={`cordova ${className}`}>{children} </div>
);

export const StyledWrapper = styled(SimpleClassWrapper)``;

const Wrapper = ({ children }) => {
  const isMobile = useIsMobile();
  const AppWrapper = isMobile ? StyledWrapper : Fragment;

  return (
    <AppWrapper>
      <BrowserRouter>{children}</BrowserRouter>
    </AppWrapper>
  );
};

export default Wrapper;
