import { KeraltyText } from 'app/components/atoms';
import { KeraltyModal } from 'app/components/molecules';
import { Row, Column } from 'app/components/templates';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import x from '../../../../../assets/icons/x.svg';
import useIsMobile from 'app/hooks/useIsMobile';
import { IInsurancePaymentResponse } from 'domain/entities/paymentInfo';
import { useHistory } from 'react-router-dom';
import ROUTES from 'app/ui-core/utils/routes';

const Link = styled.span`
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
`;

const KeraltyTextButtom = styled(KeraltyText)`
	background: #3ca70d !important;
	line-height: 14px;
	border-radius: 25px;
	margin-top: 25px;
	text-align: center;
	cursor: pointer;
	color: #fff;
	align-self: center !important;
	padding: 13px;
	text-align: center;
	font-weight: 100;
	text-transform: uppercase;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	width: 100% !important;
	}
`;

const KeralTextButtom = styled(KeraltyText)`
	background: #FFFFFF !important;
	line-height: 12px;
	border-color: #3CA70D;
	border-radius: 25px;
	border: 2px solid #0F880D;
	margin-top: 25px;
	text-align: center;
	cursor: pointer;
	color: #3CA70D;
	align-self: center !important;
	padding: 13px;
	text-align: center;
	font-weight: 100;
	text-transform: uppercase;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	width: 100% !important;

	}
`;

const KeralTextButt = styled(KeraltyText)`
	background: #FFFFFF !important;
	line-height: 14px;
	border-color: #3CA70D;
	border-radius: 25px;
	margin-top: 25px;
	text-align: center;
	cursor: pointer;
	color: #3CA70D;
	align-self: center !important;
	padding: 13px;
	text-align: center;
	font-weight: 100;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 20px;
	text-decoration: underline #3CA70D;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	width: 100% !important;
	color:#055293 !important;
	font-weight: 600;
	text-decoration: underline #055293;
	font-size: 14px;
	}
`;

interface InsuranceInactivedProps {
  close: any,
  paymentInfo: IInsurancePaymentResponse,
  triggerPatientSupport: () => Promise<void>
  createTransactionBase: () => void
}

const ModalInsuranceInactived = ({ close, paymentInfo, triggerPatientSupport, createTransactionBase }: InsuranceInactivedProps) => {
	const { t } = useTranslation();
	const isMobile = useIsMobile();
	const { replace } = useHistory();
	const { MAIN, MY_ACCOUNT } = ROUTES;

	const goToInsurance = () => {
		close();
		replace(`/${MAIN.myAccount}/${MY_ACCOUNT.myInsurance}`);
	}

    return (
        <KeraltyModal size='media' textalign="center">
            <div style={{marginRight: '3%'}}>
              <img onClick={close} src={x} alt="" style={{ cursor: 'pointer', float: 'right', width: '20px' }} />
              <Row noMargin style={{ paddingTop: '3%' }}>
                  <KeraltyText type="title" fontWeight={isMobile ? '600' : '400'}>{t('getCare.televisit.insuranceInactived')} </KeraltyText>
              </Row>
              <Row noMargin style={{ paddingBottom: '3%' }}>
                  <KeraltyText type={'info'} color={'#5B5C5B'} fontSize='16px'>{t('getCare.televisit.insuranceInactivedSubtitle')}</KeraltyText>
              </Row>
              <Row style={{ width: '100%', paddingTop: '3%', paddingBottom: '3%', justifyContent: 'center' }}>
                  <Column style={{ width: isMobile ? '100%' : '80%' }}>
                          <span style={{ width: '100%', fontSize: isMobile ? '20px' : '24px', fontWeight: '700', color: '#055293', lineHeight: '30px' }}>{t('getCare.televisit.transactTotal')}</span>
                          {!isMobile ? <span style={{ width: '100%', fontSize: '24px', fontWeight: '400', color: '#5B5C5B', lineHeight: '30px' }}>{`$${Number.parseFloat(paymentInfo.total ? paymentInfo.total.toString() : '0').toFixed(2)}`}</span>
                          :<span style={{ width: '100%', fontSize: '20px', fontWeight: '400', color: '#055293', lineHeight: '30px' }}>{`$${Number.parseFloat(paymentInfo.total ? paymentInfo.total.toString() : '0').toFixed(2)}`}</span>}
                  </Column>
              </Row>
              <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
                  <KeraltyTextButtom onClick={createTransactionBase} type="label" undecorated style={{ width: '40%', fontWeight: 600}}>{t('getCare.televisit.transactionContinue')}</KeraltyTextButtom>
              </div>
              <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
                  <KeralTextButtom onClick={goToInsurance} type="label" undecorated style={{ width: '40%', fontWeight: 600, border: `${isMobile ? '1px' : '2px'} solid`}}>{t('getCare.televisit.transactGoInsurance')}</KeralTextButtom>
              </div>
              <Row noMargin style={{ paddingBottom: '3%', paddingTop: '5%' }}>
                  <KeraltyText type={'info'} color={!isMobile?'#5B5C5B':'#055293;'}>{t('getCare.televisit.transactSupport')} <Link onClick={() => triggerPatientSupport()}>{t('getCare.televisit.patientSupport')}</Link> </KeraltyText>
              </Row>
              <div style={{ display: 'grid', placeItems: 'center', width: '100%', paddingBottom: '5%' }}>
                  <KeralTextButt onClick={close} type="label">{t('getCare.televisit.transactBack')}</KeralTextButt>
              </div>
            </div>
        </KeraltyModal>

    );
}

export default ModalInsuranceInactived;
