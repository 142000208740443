import { useMemo, useState, useCallback, useEffect } from 'react';
import PrivateHttpClient from '../../../../infrastructure/keraltyApi/privateHttpClient';
// 3RD PARTY
import { useHistory } from 'react-router-dom';

//Interaction
//LOGIC

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import NoResult from '../../../assets/icons/noResult.svg';

// UI
import TabWrapper from './TabWrapper';
import { KeraltyText, Image, SvgIcon, KeraltyButton } from '../../atoms';
import EmptyIcon from '../../atoms/emptyIcon2';
import { SeeIconCell, KeraltyFilterDate, KeraltyField } from '../../molecules';
import { Column, Container, Row } from '../../templates';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import styled from '../../../ui-core/styled-components';

import { Referral } from 'domain/entities/referral'
import useIsMobile from '../../../hooks/useIsMobile';
import FilterImg from '../../../assets/images/filter.png';
import FilterEs from '../../../assets/images/filter-es.png';
import PaginateBar from 'app/components/atoms/paginateBar';
import moment from 'moment';
import ROUTES from '../../../ui-core/utils/routes';
import ReferralsIcon from '../../../assets/icons/archive.svg';

import { ReactComponent as Provider } from '../../../assets/icons/Provider.svg';
import { ReactComponent as Speciality } from '../../../assets/icons/Speciality.svg';
import { ReactComponent as MedicalCenter } from '../../../assets/icons/MedicalCenter.svg';
import { ReactComponent as Status_Icon } from '../../../assets/icons/Status_Icon.svg';
import { ReactComponent as Npi } from '../../../assets/icons/NPI.svg';
import { ReactComponent as Calendar_day } from '../../../assets/icons/Calendar-day-Blue.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/map-marker-alt Location_.svg';
import { ReactComponent as PhoneReffIcon } from '../../../assets/icons/PhoneReffIcon.svg';
import { ReactComponent as Diagnosis_Icon } from '../../../assets/icons/Diagnosis_Icon.svg';
import { ReactComponent as ReasonRefIcon } from '../../../assets/icons/ReasonRefIcon.svg';
import EmptyRef from '../../../assets/icons/Empty_Ref.svg';
import ImgModal3 from '../../../assets/icons/hand.svg';
import NoSearchResult from 'app/components/molecules/noSearchResult';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import { basicScrollIntoTop } from 'app/ui-core/utils/screen';
import { ModalErrorMyHealth } from 'app/components/molecules/modalError';
import { useAppSelector } from 'adapter/hooks';
import { selectmyHealthError } from 'adapter/myHealth/myHealthSelectors';
import { formatDate } from 'app/ui-core/utils/dates';
const { MAIN, MY_HEALTH: CURRENT } = ROUTES;

declare global {
	interface Window {
		FB: any;
	}
}

declare global {
	interface Window {
		resolveLocalFileSystemURL: any;
	}
}

type Props = {
	referralsData: Referral[];
	onClickItem: (idx?: number, cellText?: string) => void;
};

const MainContain = styled(Row)`
	display: grid;
	justify-content: space-around;
	align-content: center;
	align-items: center;
	background: #fff !important;
	z-index: 1;
	width: 100%;
	padding-bottom: 10%;
	place-items: center;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		display: contents;
	};
`;


const ContainMap = styled(Row)`
  	display: flex;
	justify-content: space-between;
	background: #FFF;
	box-shadow: 0px 4px 4px rgba(1, 42, 88, 0.25);
	border-radius: 14px;
	width: 91%;
	height: 84px;
  	align-content: center;
	align-items: center !important;
	padding: 10px;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) {
		height: max-content;
	};
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 85%;
		margin-left: 2% !important;
	};
`;

const ContentButton = styled.div`
	@media  (max-width: 811px){
		width: 100%;
		>div{
			margin: auto
		}
	};
	@media (min-width: 811px) and (max-width: 1238px){
		width: 30%;
	};
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 100%;
	};
`;
const ContentDiv = styled.div`
	background: #3CA70D;
	border-radius: 29px;
	height:30px;
	@media (max-width: 803px){
		background: #f5f5f5;
	};
`;
const PdfButton = styled(Column)`
	display: block !important;
  	background: #3CA70D;
	border-radius: 29px;
	color: #FFFFFF;
	width: 142px;
	height: 35px;
	text-align: center !important;
	cursor: pointer;

	font-size: 14px;
	line-height: 35px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 86px;
	};
	@media (max-width: 433px) {
		width: 130px;
		height: 44px;
		line-height: 44px;
	};
	@media (max-width: 803px){
		width: 140px;
		margin: 10px
	};
`;

const ContainInfo = styled(Column)`
	flex-direction: column;
	width: 100%;
`;

const ContainImg = styled(Column)`
	width: 20px;
	margin-top: 10px;
	height: 60px;
	@media (min-width: 600px) and (max-width: 1028px){
		margin-right: 20px;
		margin-left: -10px;
	};
	@media (max-width: 600px){
		margin-right: 10px;
		margin-left: 21px;
	};
	@media (max-width: 500px){
		margin: 6px 10px 0 15px
	};
`;
const ContainImgMovil = styled(Column)`
	padding: 0;
	width: 20px;
	height: 60px;
`;

const Content = styled.div`
	display: flex;
	margin-left: -10%;
	@media (min-width: 600px) and (max-width: 1023px){
		margin-left: 0;
	}
`;

const CardTablet = styled(Row)`

`;
const Icon = styled(Row)`
	color: #055293;
  	width: 15px !important;
  	height: 15px !important;
  	margin: 0;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color: #055293;
	}
`;
const IconSub = styled(Row)`
	color: #055293;
  	width: 15px !important;
  	height: 15px !important;
  	margin: 0;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color: #055293;
	}
`;

const IconLab = styled(Row)`
	color: #48AC1C;
  	width: 40px !important;
  	height: 40px !important;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 30px !important;
  	height: 30px !important;
	}
`;

const ContainHelp = styled(Row)`
	//color: #055293;
  	width: 235px !important;
  	height: 140px !important;
  	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(20px);
	border-radius: 10px;
	position: absolute;
	margin-top: 2.5%;
	padding: 10px;
	align-content: space-between;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 7% !important;
	}
`;
const Search = styled(KeraltyField)`
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width:100%;
}
`;
const SearchWeb = styled(KeraltyField)`

	height: 90px;

`;
const EmptyIconWrap = styled.div`
  margin: 100px auto;
  position: relative;
  left: -5%;
`;

const ContainModal = styled(Column)`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  overflow: auto;
  z-index: 99;
  background-color: #00000058;
  width: 100%;
  padding: 0;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

	}
`;
const Modal = styled(Column) <{ isMobile: boolean }>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 10px;
  width: ${({ isMobile }) => isMobile ? '80%' : 'fit-content'};
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 	0%;
  margin-top: ${({ isMobile }) => isMobile ? '60%' : '15%'};
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

	}
`;
const ContainerCancelButton = styled(Column)`
	display: flex;
	text-align: center;
	justify-content: center;
`;
const ContainerFilterButton = styled(Column)`
	display: flex;
	text-align: center;
	justify-content: center;
	align-content: center;
	margin: 0;
`;


const Button = styled(KeraltyButton)`
	letter-spacing: 1px;
	align-self: center;
	margin-top: 10px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		padding: 0px;
	}
`;

const styleButton = {
	color: '#3CA70D',
	marginRight: 60,
	marginLeft: 52,
	marginTop: 10,
	paddingTop: 0,
	paddingBottom: 0,
	backgroundColor: '#FFFFFF',
	borderRadius: 40,
	border: 'none',
	borderColor: "#FFFFFF",
	fontSize: 16,
	fontWeight: 'bold',
}

const styleDiv = {
	marginTop: 0,
	paddingTop: 0,
	paddingBottom: 10,
	marginLeft: 0,
	marginRight: 0,
	flex: 1,
	align: "center",
	justifyContent: 'center',
	alignSelf: 'center',
}

const styleText = {
	textAlign: "center",
	marginRight: 7,
	marginTop: 15,
	color: "#5b5c5b",
	fontWeight: 400,
}

const styleNoResultMobile = {
	// marginTop: '40%',
}

const styleNoResult = {
	backgroundColor: '#fff'
}

const ReferralsTable: React.FC<Props> = ({ referralsData, onClickItem }) => {
	const { t } = useTranslation();
	const [filter, setFilter] = useState(true);
	const isMobile = useIsMobile();
	const { goBack, push } = useHistory();
	const endpoint = 'ecw';
	const [currPage, setCurrPage] = useState(1);
	const [dataObject, setDataObject] = useState<{ from?: string; to?: string }>({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [cleanInput, setCleanInput] = useState(false);
	const [visible, setVisible] = useState(false);
	const [page, setPage] = useState(false);
	const [idreferrals, setIdReferrals] = useState({});
	const [modal, setModal] = useState(false);

	const [closeModalError, setCloseModalError] = useState(false);
	const error = useAppSelector(selectmyHealthError);
	useEffect(() => {
		if (['400', '500', '503'].includes(error?.code?.toString())) setCloseModalError(error)
	}, [error])


	const data = useMemo(
		() => {
			const result = referralsData.map((referralItem, idx) => ({
				...referralItem,
				icon: <SeeIconCell idx={idx} cellText={''} hasIcon onClick={onClickItem} />,
			}))
			return [...result].sort((a: any, b: any) => moment(b.startDate, 'YYYY-MM-DD').diff(moment(a.startDate, 'YYYY-MM-DD')))

		},
		[referralsData, onClickItem]
	);

	useMemo(() => {
		//method in charge of cleaning form
		if (filter) setDataObject({})
	}, [filter]);

	const onChangeDate = useCallback((type, data) => {
		setPage(false);
		type && setDataObject(data);
		setIsModalVisible(false);
		setCurrPage(1)
	}, []);

	const dataToDisplay = useMemo(() => {
		//function in charge of filtering data by date range
		if (!dataObject.to || !dataObject.from) return data;
		const fromDate = moment(dataObject.from, 'MM/DD/YYYY');
		const toDate = moment(dataObject.to, 'MM/DD/YYYY');
		setVisible(true)
		return data.filter((appointment) => {
			const currentDate = moment(appointment.startDate, 'YYYY-MM-DD');
			return currentDate.isBetween(fromDate, toDate, undefined, '[]');
		});
	}, [dataObject, data]);

	const newData = useMemo(() => {
		const indexLastPage = currPage * 5;
		const indexFirstPage = indexLastPage - 5;

		return dataToDisplay.slice(indexFirstPage, indexLastPage)
	}, [currPage, dataToDisplay]);

	const lastPage = Math.ceil(dataToDisplay.length / 5);

	//Download PDF
	const downloadPdf = (dat) => {
		const body = {
			"patientId": dat.patientId,
			"referralId": dat.referralId,
			"language": i18n.language,
		};
		return PrivateHttpClient.get(`${endpoint}/patient/referrals-pdf?patientId=${filterData()?.patientId}&referralsId=${filterData()?.referralId}&language=${i18n.language}`)
			.then((res) => {
				if (res) {
					const linkSource = `data:application/pdf;base64,${res}`;
					let downloadLink = document.createElement("a");
					downloadLink.href = linkSource;
					downloadLink.download = 'doc';
					downloadLink.click();
				}
			}
			).catch((e) => {
			})
			;
	}
	const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
		contentType = contentType || '';
		var byteCharacters = atob(b64Data);
		var byteArrays: any = [];
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}
	const ClearDate = () => {
		var p1 = { from: "", to: "" };
		var Init = moment().format();
		setDataObject(p1)
		setVisible(false)
		setFilter(!filter)
	}
	const FilterVisible = () => {
		return (
			<>
				<Row noMargin style={{ width: isMobile ? '85%' : '100%', justifyContent: 'space-between', marginLeft: isMobile ? '0%' : '4%', display: dataObject.from ? 'flex' : 'none' }}>
					<div style={{ background: '#3CA70D', paddingInline: 20, borderRadius: 20, cursor: 'pointer', height: 30, alignItems: 'center' }} onClick={() => { ClearDate(); setCleanInput(!cleanInput) }}>
						<p style={{ fontSize: '12px', color: '#FFFFFF', margin: 0 }}>
							{`${moment(dataObject.from).format("MM/DD/YY")} - ${moment(dataObject.to).format("MM/DD/YY")}`}
							<span style={{ color: '#3CA70D' }}>----</span><span style={{ fontFamily: 'inherit' }}>X</span></p>
					</div>
					<KeraltyText style={{ width: 'auto', marginRight: isMobile ? '-2%' : '10%', alignSelf: 'center', fontSize: '16px', color: '#999999' }} >{t('myHealth.show')} {dataToDisplay.length} {t('myHealth.resul')}</KeraltyText>
				</Row>
			</>
		)
	}

	const MainFilters = () => {
		return (
			<div style={{ alignSelf: 'center' }}>
				{i18n.language == 'en' ?
					<img onClick={() => { setFilter(!filter); setIsModalVisible(filter); setCleanInput(!cleanInput) }} src={isMobile ? FilterImg : filter ? FilterImg : ''} alt="" style={{ cursor: 'pointer' }} />
					:
					<img onClick={() => { setFilter(!filter); setIsModalVisible(filter); setCleanInput(!cleanInput) }} src={isMobile ? FilterEs : filter ? FilterEs : ''} alt="" style={{ cursor: 'pointer' }} />
				}
			</div>
		);
	};

	const filterData = () => {
		let result = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.REFERRAL)
		if (JSON.stringify(result) == '{}') {
			return data.find(id => id.referralId === result)
		} else {
			return data.find(id => id.referralId == result)
		}
	}

	const LabelRef = ({ src, title, text, width = '50%' }) => {
		return (
			<Row noMargin style={{ width: '100%', marginBottom: 7 }}>
				<Column noPadding style={{ width }}>
					<SvgIcon src={src} />
					<span style={{ fontSize: '15.4px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t(title)}</span>
				</Column>
				<span style={{ width, fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px' }}>{text}</span>
			</Row>
		)
	}

	const LabelRefWeb = ({ src, title, text, width = '50%' }) => {
		return (
			<Row noMargin style={{ width, marginBottom: 5 }}>
				<Column noPadding>
					<SvgIcon src={src} />
					<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t(title)}
						<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{text}</span>
					</span>
				</Column>
			</Row>
		)
	}

	useEffect(() => {
		console.log({ newData })
	}, [])


	const CardsReferrals = () => {
		return (
			<MainContain>
				{newData.map((dat) => (
					<ContainMap key={dat.referralId}>
						<ContainInfo style={{ justifyContent: 'center' }}>
							{!isMobile ?
								<div>
									<Row>
										<ContainImg style={{ width: '7%', alignSelf: 'center' }}>
											<Image src={ReferralsIcon} />
										</ContainImg>
										<Row noMargin style={{ width: '60%' }}>
											<LabelRefWeb src={Provider} title={'myHealth.provider'} text={`${dat.fromFirstName} ${dat.fromLastName}`} />
											<LabelRefWeb src={Calendar_day} title={'myHealth.issue'} text={formatDate({ date: dat.startDate, format: "MM-DD-YYYY" })} />
											<LabelRefWeb src={Speciality} title={'myHealth.speciality'} text={dat.speciality} />
											<LabelRefWeb src={Npi} title={'myHealth.authId'} text={dat.authNo} />
											<LabelRefWeb src={MedicalCenter} title={'myHealth.center'} text={dat.toFacilityName} />
											<LabelRefWeb src={Status_Icon} title={'myHealth.status'} text={t(`myHealth.${dat.status}`)} />
										</Row>

										<Column style={{ alignSelf: 'center' }}>
											<ContentButton>
												<div style={{ marginLeft: 'auto' }}>
													<PdfButton onClick={() => { setIdReferrals(dat.referralId); KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.REFERRAL, JSON.stringify(dat.referralId)); handleReferrals(); }}>{t('myHealth.details')}</PdfButton>
												</div>
											</ContentButton>
										</Column>
									</Row>
								</div>
								:
								<>
									<Row>
										<Column noPadding style={{ width: '10%' }}>
											<ContainImgMovil>
												<Image src={ReferralsIcon} height={3.5} />
											</ContainImgMovil>
										</Column>
										<Row noMargin style={{ width: '90%', paddingTop: 3 }}>
											<LabelRef src={Provider} title={'myHealth.provider'} text={`${dat.fromFirstName} ${dat.fromLastName}`} />
											<LabelRef src={Speciality} title={'myHealth.speciality'} text={dat.speciality} />
											<LabelRef src={MedicalCenter} title={'myHealth.center'} text={dat.toFacilityName} />
											<LabelRef src={Status_Icon} title={'myHealth.status'} text={t(`myHealth.${dat.status}`)} />
											<LabelRef src={Npi} title={'myHealth.authId'} text={dat.authNo} />
											<LabelRef src={Calendar_day} title={'myHealth.issue'} text={formatDate({ date: dat.startDate, format: "MM-DD-YYYY" })} />
										</Row>
									</Row>
									<ContentButton>
										<PdfButton onClick={() => { setIdReferrals(dat.referralId); KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.REFERRAL, JSON.stringify(dat.referralId)); handleReferrals(); }}>{t('myHealth.details2')}</PdfButton>
									</ContentButton>
								</>
							}
						</ContainInfo>
					</ContainMap>
				))}
				{dataToDisplay.length >= 5 ?
					<Row>
						<Container centered="x" noPadding>
							<Container centered="y" noPadding>
								<PaginateBar
									currentPage={currPage}
									itemsPerPage={5}
									lastPage={lastPage}
									select={(page) => setCurrPage(page)}
									totalItems={dataToDisplay.length}
								/>
							</Container>
						</Container>
					</Row>
					: <></>}
			</MainContain >
		);
	};

	const handleReferrals = () => {
		push(`/${MAIN.myHealth}/${CURRENT.referrals}/${CURRENT.referralsDetails}`);
	};

	useEffect(() => {
		basicScrollIntoTop()
	}, [])

	return (
		<div>
			{closeModalError && <ModalErrorMyHealth setCloseModalError={() => setCloseModalError(false)} />}

			{window.location.pathname.includes('referrals-Details') ?
				<TabWrapper>
					<KeraltyText style={{ marginTop: -5, marginBottom: -15 }} type={'title'} >{t('myHealth.referrals')}</KeraltyText>
					{
						dataToDisplay?.length ?
							<MainContain>
								{modal ? <ContainModal>
									{modal &&
										<Modal isMobile={isMobile}>
											<div style={{ padding: '10px' }}>
												<img style={{ background: '#3CA70D', borderRadius: '50%' }} src={ImgModal3} alt="" />
											</div>
											{isMobile ?
												<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
													<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
														{t('myHealth.saved')}
													</KeraltyText>
												</div> :
												<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px', width: '60%' }}>
													<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
														{t('myHealth.saved')}
													</KeraltyText>
												</div>}

											<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
												<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova' width='20px' >
													{t('myHealth.want')}
												</KeraltyText>
											</div>



											{isMobile ? <Row>

												<ContainerFilterButton lg={12} md={6} sm={6} >
													<Button smFull type={'submit'} onClick={() => {
														downloadPdf(filterData())
														setModal(false)
													}}    >
														{t('myHealth.yes')}
													</Button>
												</ContainerFilterButton>

												<ContainerCancelButton lg={0} md={6} sm={6}>
													<Button
														outline
														style={{ borderColor: '#FFFFFF', color: 'rgba(91, 92, 91, 0.5)' }}
														smFull
														type={'text'}
														onClick={() => {
															setModal(false)
														}}
													>
														{t('myHealth.no')}
													</Button>
												</ContainerCancelButton>
											</Row>
												:

												<div style={{ display: 'grid', placeItems: 'center' }}>
													<Row>

														<ContainerFilterButton >
															<Button smFull type={'submit'} onClick={() => {
																downloadPdf(filterData())
																setModal(false)
															}}   >
																{t('myHealth.yes')}
															</Button>
														</ContainerFilterButton>

														<ContainerCancelButton >
															<Button
																outline
																smFull
																type={'text'}
																onClick={() => {
																	setModal(false)
																}}
															>
																{t('myHealth.no')}
															</Button>
														</ContainerCancelButton>
													</Row>      </div>}

										</Modal>
									}

								</ContainModal> : ''}
								{idreferrals != null &&
									< >
										{!isMobile && idreferrals ?
											<>
												<ContainMap style={{ width: '95%' }}>
													<Row style={{ justifyContent: 'center' }}>
														<LabelRefWeb width='45%' src={Calendar_day} title={'myHealth.issue'} text={moment(filterData()?.startDate).format("MM-DD-YYYY")} />
														<LabelRefWeb width='45%' src={Status_Icon} title={'myHealth.status'} text={t(`myHealth.${filterData()?.status}`)} />
													</Row>
												</ContainMap>

												<Row noMargin style={{ justifyContent: 'space-around' }}>
													<ContainMap style={{ width: '45%', alignContent: 'start' }}>
														<ContainInfo>
															<div style={{ marginBlock: 10 }}>
																<span style={{ fontSize: '15.4px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.seeing')}</span>
															</div>
															<LabelRefWeb width='100%' src={Provider} title={'myHealth.provider'} text={`${filterData()?.toFirstName ?? ''} ${filterData()?.toLastName ?? ''}`} />
															<LabelRefWeb width='100%' src={Speciality} title={'myHealth.speciality'} text={filterData()?.speciality} />
															<LabelRefWeb width='100%' src={MedicalCenter} title={'myHealth.center'} text={filterData()?.toFacilityName} />
															<LabelRefWeb width='100%' src={LocationIcon} title={'myHealth.address'} text={filterData()?.toAddress} />
															<LabelRefWeb width='100%' src={PhoneReffIcon} title={'myHealth.phone'} text={filterData()?.toTel} />
														</ContainInfo>
													</ContainMap>
													<ContainMap style={{ width: '45%', alignContent: 'start' }}>
														<ContainInfo>
															<div style={{ marginBlock: 10 }}>
																<span style={{ fontSize: '15.4px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.referralFrom')}</span>
															</div>
															<LabelRefWeb width='100%' src={MedicalCenter} title={'myHealth.center'} text={filterData()?.fromFacilityName} />
															<LabelRefWeb width='100%' src={Npi} title={'myHealth.numberId'} text={filterData()?.fromFacility} />
															<LabelRefWeb width='100%' src={Npi} title={'myHealth.npi'} text={filterData()?.refFromNPI} />
															<div style={{ margin: 25 }} />
														</ContainInfo>
													</ContainMap>
												</Row>
												<ContainMap style={{ width: '95%' }}>
													<ContainInfo style={{ width: '100%', alignItems: 'stretch' }}>
														<Column style={{ justifyContent: 'center' }}>
															<div style={{ marginTop: 10, width: '100%' }}>
																<span style={{ fontSize: '15.4px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.seen')}</span>
															</div>
															<Row style={{ width: '50%' }}>
																<LabelRefWeb width='100%' src={Calendar_day} title={'myHealth.startDate'} text={moment(filterData()?.startDate).format("MM-DD-YYYY")} />
																<LabelRefWeb width='100%' src={Calendar_day} title={'myHealth.validUntil'} text={moment(filterData()?.endDate).format("MM-DD-YYYY")} />
																<LabelRefWeb width='100%' src={Npi} title={'myHealth.authId'} text={filterData()?.authNo} />
															</Row>
															<Row style={{ width: '50%' }}>
																<LabelRefWeb width='100%' src={ReasonRefIcon} title={'myHealth.reasonRef'} text={filterData()?.reason} />
																<LabelRefWeb width='100%' src={Diagnosis_Icon} title={'myHealth.diag'} text={filterData()?.diagnosisDesc} />
															</Row>
														</Column>
													</ContainInfo>
												</ContainMap>
												<ContentButton style={{ width: '100%', justifyContent: 'center' }}>
													<PdfButton onClick={() => { setModal(true) }}>{t('myHealth.pdf2')}</PdfButton>
												</ContentButton>
											</>
											:
											<ContainMap >
												<ContainInfo>
													<Row>
														<Row noMargin style={{ width: '100%' }}>
															<LabelRef src={Status_Icon} title={'myHealth.status'} text={t(`myHealth.${filterData()?.status}`)} />
															<LabelRef src={Calendar_day} title={'myHealth.issue'} text={moment(filterData()?.startDate).format("MM-DD-YYYY")} />
															<div style={{ marginTop: 10, marginBottom: 15 }}>
																<span style={{ fontSize: '15.4px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.seeing')}</span>
															</div>
															<LabelRef src={Provider} title={'myHealth.provider'} text={`${filterData()?.toFirstName ?? ''} ${filterData()?.toLastName ?? ''}`} />
															<LabelRef src={Speciality} title={'myHealth.speciality'} text={filterData()?.speciality} />
															<LabelRef src={MedicalCenter} title={'myHealth.center'} text={filterData()?.toFacilityName} />
															<LabelRef src={LocationIcon} title={'myHealth.address'} text={filterData()?.toAddress} />
															<LabelRef src={PhoneReffIcon} title={'myHealth.phone'} text={filterData()?.toTel} />
															<div style={{ marginTop: 10, marginBottom: 15 }}>
																<span style={{ fontSize: '15.4px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.referralFrom')}</span>
															</div>
															<LabelRef src={MedicalCenter} title={'myHealth.center'} text={filterData()?.fromFacilityName} />
															<LabelRef src={Npi} title={'myHealth.numberId'} text={filterData()?.fromFacility} />
															<LabelRef src={Npi} title={'myHealth.npi'} text={filterData()?.refFromNPI} />
															<div style={{ marginTop: 10, marginBottom: 15 }}>
																<span style={{ fontSize: '15.4px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.seen')}</span>
															</div>
															<LabelRef src={Calendar_day} title={'myHealth.startDate'} text={moment(filterData()?.startDate).format("MM-DD-YYYY")} />
															<LabelRef src={Calendar_day} title={'myHealth.validUntil'} text={moment(filterData()?.endDate).format("MM-DD-YYYY")} />
															<LabelRef src={Npi} title={'myHealth.authId'} text={filterData()?.authNo} />
															<LabelRef src={ReasonRefIcon} title={'myHealth.reasonRef'} text={filterData()?.reason} />
															<LabelRef src={Diagnosis_Icon} title={'myHealth.diag'} text={filterData()?.diagnosisDesc} />
															<ContentButton>
																<div style={{}}>
																	<PdfButton onClick={() => { setModal(true) }}>{t('myHealth.pdf2')}</PdfButton>
																</div>
															</ContentButton>
														</Row>
													</Row>
												</ContainInfo>
											</ContainMap>
										}
									</>
								}
							</MainContain >
							:
							<>
								{!data ? <EmptyIcon /> :
									<EmptyIcon />
								}
								<Row>
									<div style={{ marginBottom: 300, width: '40vw', display: 'flex', background: 'red' }}> </div>
								</Row>
							</>
					}
				</TabWrapper>
				:
				<TabWrapper title={!isMobile ? t('myHealth.referrals') : undefined}>
					<div>
						<Row style={{ width: !isMobile ? '65vw' : '85vw', justifyContent: 'space-between', marginLeft: !!newData.length ? 30 : 5 }}>
							<KeraltyText style={{ width: 'auto', margin: 0, fontSize: 18, marginLeft: filter ? -10 : 0 }} type={'title'} >{isMobile ? t('myHealth.referrals') : ''}</KeraltyText>
							<MainFilters />
						</Row>
						<div style={{
							zIndex: isMobile ? 4 : 1,
							display: filter ? 'none' : 'grid',
							marginInlineEnd: isMobile ? 'inherit' : 'auto',
							paddingInline: '2%',
						}}>
							<KeraltyFilterDate
								onSuccess={onChangeDate}
								onCancel={() => { setIsModalVisible(false); setFilter(true) }}
								showModalFlag={isModalVisible}
								cleanInput={cleanInput}
							></KeraltyFilterDate>
						</div>
						{visible && !isMobile ? <FilterVisible /> : ''}
						{visible && isMobile ? <FilterVisible /> : ''}

						{!error ? (newData.length == 0) ?
							<div style={isMobile ? styleNoResultMobile : styleNoResult}>
								{visible ? <NoSearchResult image={NoResult} title={t('myHealth.noResult')} subTitle={t('myHealth.noResultVisitSmmary')} /> :
									<NoSearchResult image={EmptyRef} title={""} subTitle={t('myHealth.noRef')} />}
							</div>
							: <CardsReferrals />
							: <></>}
					</div>
				</TabWrapper>
			}
		</div>
	);
};
export default ReferralsTable;
