import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { KeraltySWS } from './useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

type useCaptchaType = (formName?: string) => {
	/* Token generated */
	token: string | null;
	/* Clear internal token and errors */
	// handleReset: () => void;
	/* Generate a new token and save it in local state */
	// handleReCaptchaVerify: () => void;
	/* If a valid token is set, execute submit function */
	handleValidCaptchaSubmit: (onSubmitCallback: Function) => any;
	/* If no valid token is set on submit, set errors */
	captchaError?: string;
}

/**
 * @see https://www.npmjs.com/package/react-google-recaptcha-v3
 */
const useCaptcha: useCaptchaType = (formName) => {
	const { t } = useTranslation();
	const [token, setToken] = useState<string | null>(null);
	const [captchaError, setCaptchaError] = useState<string | undefined>()
	const { executeRecaptcha } = useGoogleReCaptcha();

	const loadToken = useCallback(async () => {
		// @ts-ignore
		const token = await executeRecaptcha(formName);
		setToken(token);
		sessionStorage.setItem(SESSION_STORAGE_ITEMS.GRECAPTCHA_TOKEN, token);
	}, [executeRecaptcha, formName])

	const handleValidCaptchaSubmit = async (onSubmit) => {
		// MOBILE & TEST ENVS WORKAROUND
		if (process.env.NODE_ENV === 'test') {
			onSubmit();
		} else {
			try {
				if (executeRecaptcha) {
					await loadToken();
					onSubmit();
				}
			} catch (exc) {
				console.error(exc);
				setCaptchaError(t('errors.captchaExpired'));
			}
		}
	};

	return {
		token,
		captchaError,
		handleValidCaptchaSubmit
	};
};

export default useCaptcha;
