import { Container, FormBox } from "app/components/templates";
import { AccountBlocked, CodeExpired, CodeSent } from "./components";
import { useTwoFactorAuthentication } from "app/hooks/useTwoFactorAuthentication";
import { TwoFactorAuthenticationSteps } from "adapter/twoFactorAuthentication/twoFactorAuthentication.state";

const Status = () => {
  const { infoTwoFactor, STATUS_CODES } = useTwoFactorAuthentication();

  const DispayView = () => {

    if( infoTwoFactor.expiredCode ) {
      return <CodeExpired />
    }

    if( infoTwoFactor.errorCodeAttemps === '-1' || infoTwoFactor.sendCodeAttemps === STATUS_CODES.ACCOUNT_BLOCKED ) {
      return <AccountBlocked blockedByAttempsError={ infoTwoFactor.errorCodeAttemps === '-1' } />
    }

    if ( infoTwoFactor.currentStep === TwoFactorAuthenticationSteps.resendCode ) {
      return <CodeSent resendCode />
    }

    return <CodeSent />

  }


  return (
    <Container centered={'xy'}>
      <FormBox style={{ marginTop: '10%', width: '100%' }}>
        <DispayView />
      </FormBox>
    </Container>
  )
}

export default Status;
