import styled from 'styled-components';
import { KeraltyText } from '../atoms';
import Image from '../atoms/image';
import { Row } from '../templates';

interface NoSearchProps {
    image?: any;
    title?: string;
    subTitle?: string;

}

const titleStyle={
    fontSize: 18,
    fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
    color: '#5B5C5B',
    fontWeight: 600,
    whiteSpace:'pre-line'
}
const subTitleStyle={
    fontSize: 18,
    fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
    color: '#5B5C5B',
    fontWeight: 400,
    whiteSpace:'pre-line'
}

const NoSearchResult = ({ image, title, subTitle }: NoSearchProps) => {


    return (
        <Row style={{ paddingTop: 50, justifyContent: 'center' }} >
            <div >
                <Image
                    src={image}
                />
            </div>
            <Row  style={{ justifyContent: 'center' , paddingTop: 35,margin:0, paddingBottom:10}}>
            {title && <KeraltyText fontWeight={'400'} type="label" style={titleStyle}>{title}</KeraltyText>}
            </Row>
            <Row  style={{ justifyContent: 'center' , margin:0}}>
                <KeraltyText fontWeight={'400'} type="label" style={subTitleStyle}>{subTitle} </KeraltyText>
            </Row>


        </Row>
    )
};

export default NoSearchResult;

const MajorContainer = styled.div<any>`








`;
