import styled from '../../ui-core/styled-components';
import { Column, Container } from '../templates';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import useIsMobile from 'app/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { KeraltyButton } from '../atoms';
import { ReactComponent as CircleExclamation } from 'app/assets/icons/circle-exclamation.svg';


interface InsuranceNoValidatedProps {
    setCloseModalError: any;
}

const ModalErrorMyHealth = ({
    setCloseModalError
}: InsuranceNoValidatedProps) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <ContainModal>
            <Modal isMobile={isMobile} >
                <Container />
                <CircleExclamation />
                <Container />

                <div style={{ maxWidth: 300, textAlign: 'center' }}>
                    <SubTitleError>{t('errors.somethingWentWrong')}</SubTitleError>
                </div>

                <div>
                    <KeraltyButton
                        type={'submit'}
                        full
                        onClick={() => {
                            setCloseModalError('');
                        }}
                    >
                        {t('button.accept2')}
                    </KeraltyButton>
                </div>
            </Modal>
        </ContainModal>
    );
};

const SubTitleError = styled.span`
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;

  color: #055293;
`;

const ContainModal = styled(Column)`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  overflow: auto;
  z-index: 99;
  background-color: #00000058;
  width: 100%;
  padding: 0;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

}
`;
const Modal = styled(Column) <{ isMobile: boolean }>`
background: #ffffff 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 20px;
padding: 10px;
width: ${({ isMobile }) => isMobile ? '80%' : 'fit-content'};
height: fit-content;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-left: 	0%;
margin-top: ${({ isMobile }) => isMobile ? '60%' : '15%'};
@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

}
`;

export {
    ModalErrorMyHealth,
}
