
const openSafeUrl = ( url: string ) => {

	const newWindow = window.open();

	if( !newWindow ) return;

	newWindow.opener = null;
	newWindow.location = url;
}

export {
	openSafeUrl,
}
