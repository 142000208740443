import { useTranslation } from 'react-i18next';


import calendarDayIcon from '../../../assets/icons/calendar-day.svg';
import shieldCheckIcon from '../../../assets/icons/shield-check.svg';
import user from '../../../assets/icons/userMyhlt.svg';
import calendar from '../../../assets/icons/calendar.svg';
import towUser from '../../../assets/icons/people-arrowsMyhlt.svg';
import framedUser from '../../../assets/icons/clipboard-userMyhlt.svg';


import { Row, Column, IconLinkRow, Card, SpaceWrapper } from '../../../components/templates';
import { KeraltyButton, KeraltyIcon, KeraltyText, Image, Divider } from '../../../components/atoms';

import styled from '../../../ui-core/styled-components';

import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import { InsuranceInfo } from '../../../../domain/entities/insuranceInfo';
import appConfig from '../../../../config';
import { useState } from 'react';
import {  NewUserInfo } from 'domain/entities/availityInfo';
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import React from 'react';
import useIsMobile from 'app/hooks/useIsMobile';
import moment from 'moment';
import FORMATS from 'app/ui-core/utils/formats';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';


interface MyInsuranceCardsProps {
	data: NewUserInfo[] | null;
	handleShowForm(arg: boolean): void;
	setValuesByMemberId(arg: string): void;
}

const CardsColumn = styled(Column)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media  (max-width: ${BREAKPOINTS.maxTabletWidth}) {
        flex-direction: column !important;
    }
`;

const InsuranceCard = styled(Card)`
    justify-content: left;
    flex-direction: column;
    margin: 2rem 0rem;
    padding-top: .7rem;
    align-items: flex-start;
	border-radius: 2.0rem;
`;

const RowInCard = styled(Column)`
    &.card-info {
        margin-top: 0rem;
    }
    margin: 1.9rem 1.7rem;
    max-width: calc(100% - 7.4rem);
`;

const RowInList = styled(Row)`
    &:first-child {
        margin-top: 0rem;
    }

    margin: .5rem .5rem 1rem .5rem;
`;

const KeraltyButtonStyle = styled(KeraltyButton)`
		font-size: 1.35rem;
		padding: 0 1rem;
`;

const RowInDataList = (title, value, icon) => (
	<RowInList>
		<Row style={{margin:0, padding:0}}>
			<div>
				<div style={{ alignItems: 'center', justifyContent: 'center', marginRight: 10, width: 18 }}>
					<Image size={ 1.5} src={icon} />
				</div>
				<KeraltyText type="subTitle" bold  color={"dark"} fontSize={'16px'} align={'left'} >{`${title}`}</KeraltyText>
			</div>
		</Row>
		<Row style={{margin:0, padding:0, marginLeft: 30}}>
			{
				value
				? <KeraltyText type="subTitle" color={"dark"} fontSize={'16px'} align={'left'} style={{ paddingLeft:28, height: value.length>=2?  'auto':  20}}>{`${value}`}</KeraltyText>
				: <div style={{height:20}}></div>
			}
		</Row>
	</RowInList>




)

const MyInsuranceCards: React.FC<MyInsuranceCardsProps> = (props) => {


	const { data , handleShowForm, setValuesByMemberId} = props;
	const { t } = useTranslation();
	const [isfloridaBlue, setIsfloridaBlue] = useState(false)
	const [isActive, setIsActive] = useState(false)
	const isMobile = useIsMobile();


	const isValidity = (coverageEndDate) => {
		const today = new Date().getTime();
		const endDate = new Date(coverageEndDate).getTime();
		return today < endDate;
	};

	const updateDataUser =(isPrimary: boolean, insuranceName: string, memberId: string)=>{
		handleShowForm(true)
		//handleIsFb(data? data.isFb: null)
		setValuesByMemberId(memberId);
		KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.IS_PRIMARY, isPrimary? 'true':'false')
		KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.INSURANCE_NAME, insuranceName)
	}

	const hangleGoToFB = () => {
		window?.open(appConfig.FB_INSURANCE_URL, '_blank')?.focus();
	};

	return (
		<div style={{ display: 'grid', placeItems: 'center' }}>
			<CardsColumn noPadding>
				{data ? data.map((card, index) => {

					return (
						<Column noPadding key={index}>
							<InsuranceCard style={{ width: isMobile ? 'auto' : '376px', marginLeft: isMobile ? 0 : 15 }}>
								<RowInCard style={{paddingLeft:15, marginLeft:0}}>
									<IconLinkRow
										color={'primary'}
										iconSize={1.4}
										nounderline
										icon={shieldCheckIcon}
										iconAlt={t('myAccount.nameOfInsurance')}
										text={`${card.insuranceName}`}
										bold={false}
										fontSize={16}
									/>
								</RowInCard>

								<RowInCard className={'card-info'}>
									{ RowInDataList(t('myAccount.nameInsured'), capitalizeJustFirst(card.insuredFirstName? card.insuredFirstName:'' ) +' ' + capitalizeJustFirst(card.insuredLastName? card.insuredLastName:'' )+' ' ,user)}
									{RowInDataList(t('myAccount.relationship'), card.insuredRelationshipToPatient,towUser) }
									{RowInDataList(t('myAccount.suscriberId'), card.memberId, framedUser)}

									{ RowInDataList(t('myAccount.groupId'), card.groupId , framedUser) }
									{((card.isFb || card.isTn || card.isSc) && card.status == true)? RowInDataList(t('myAccount.status'), t('myAccount.active'), framedUser):((card.isFb || card.isTn || card.isSc) && card.status == false)? RowInDataList(t('myAccount.status'), t('myAccount.inactive'), framedUser): null }
								</RowInCard>

								{
									card.holderInsuredDto && card.insuredRelationshipToPatient && !card.insuredRelationshipToPatient.toLowerCase().startsWith('self') &&
									<div>

										{ card.holderInsuredDto.name && card.holderInsuredDto.lastName && card.holderInsuredDto.dateOfBirth && <Divider style={{ marginBottom: '2rem' }} />}

										<RowInCard className={'card-info'}>
											{
												card.holderInsuredDto.name && RowInDataList( t('myAccount.holderName'), capitalizeJustFirst( card.holderInsuredDto.name ), user )
											}
											{
												card.holderInsuredDto.lastName && RowInDataList( t('myAccount.holderLastName'), capitalizeJustFirst( card.holderInsuredDto.lastName ), user )
											}
											{
												card.holderInsuredDto.dateOfBirth && RowInDataList( t('myAccount.holderDateOfBirth'), moment( card.holderInsuredDto.dateOfBirth ).format(FORMATS.date), calendar )
											}
										</RowInCard>

									</div>

								}

								<Row noMargin style={{ padding:0, display: 'grid', placeItems: 'center' }}>
									<KeraltyButtonStyle type={'button'} color={'accent'} full
										onClick={() => updateDataUser(card.isPrimary,card.insuranceName, card.memberId )}
										style={{marginLeft:'5%' ,width:'auto'}}>
											<text style={{whiteSpace:'pre',paddingLeft:16,paddingRight:4}}>{t('button.update')}{' '}  </text>
									</KeraltyButtonStyle>
									{card.isFb && <KeraltyButtonStyle type={'button'} color={'light'} full onClick={() => hangleGoToFB()}  style={{marginLeft:'5%', width:'auto', marginTop:0, borderWidth:1, borderColor:"#3CA70D"}}><text style={{whiteSpace:'pre', color:"#3CA70D"}}>{t('button.goToFB')}{' '} </text>
										<KeraltyIcon iconClass="fas fa-external-link" color="accent" />
									</KeraltyButtonStyle>}
								</Row>

							</InsuranceCard>
						</Column>
					);
				}) : null}
			</CardsColumn>
		</div>
	);
}

export default MyInsuranceCards;
