import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
// UI
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { KeraltyIcon, KeraltyText } from '../../atoms';
import { Container, Column, Row, IconLinkRow } from '../../templates';
import { KeraltyDotChatTrigger } from 'app/components/molecules';
import { ReactComponent as LabResultsIcon } from '../../../assets/icons/lab-results.svg';
import { ReactComponent as SupportChatIcon } from '../../../assets/icons/chat 2.svg';
import { closeSymptomChecker } from 'adapter/chat/chatSlice';
import * as sanitasAdapterMethods from '../../../../adapter/sanitasChat/sanitasChat';
import styled from '../../../ui-core/styled-components';
import { StyledWrapper } from '../../templates/wrapper';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import useAuth from '../../../hooks/useAuth';
import chatImg2 from '../../../assets/images/chat2.png';

import { utcTime } from 'app/ui-core/utils/times';

const ResultsContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
  align-self: 'center';
  max-width: 75%;
`;

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
`;

const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
  }
`;

const LabelText = styled(KeraltyText)`
  margin-left: 0.5rem;
`;

const StudyName = styled(KeraltyText)`
  margin-left: 0.5rem;
  margin-bottom: 1.8rem;
  font-size: 1.65rem;
  font-weight: bold;
`;
const StyledLabResultsIcon = styled(LabResultsIcon)`
  color: ${({ theme: { colors } }) => colors.dark};
  fill: ${({ theme: { colors } }) => colors.dark};
`;

const CordovaColumn = styled(Column)`
  > div {
    display: none;

    @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
      ${StyledWrapper} & {
        display: flex;
        margin-top: 10px;
      }
    }
  }
`;

const CenteredText = styled(KeraltyText)`
  text-align: center;
  margin-bottom: 15px;
`;

const CenteredChat = styled(Column)`
  justify-content: center;
`;

const RegularColumn = styled(Column)`
  > div {
    display: flex;

    @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
      ${StyledWrapper} & {
        display: none;
      }
    }
  }
`;

const ResultAccessContainer = styled(Column)`
  margin-left: -11px;
`;

type LabResultType = {
  patientId: number;
  itemName: string;
  clinicalCenter: string;
  orderDate: string;
  receivedDate: string;
  reason: string;
  result: string;
  notes: string;
};

type Props = {
  data: LabResultType;
  onBackClick?: () => void;
};

const LabResults: React.FC<Props> = ({ data, onBackClick }) => {
  const { itemName, clinicalCenter, result, orderDate, reason, receivedDate, patientId, notes } =
    data;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { sanitasState } = sanitasChatState;
  const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
  const { user } = useAuth();
  const { authUid } = useAppSelector(state => state.user);

  const triggerPatientSupport = useCallback(async () => {
    if (isSCOpen) dispatch(closeSymptomChecker());
    if (sanitasState.isShow && sanitasState.isSetted) {
      await dispatch(sanitasAdapterMethods.shutdown());
    }
    await setTimeout(async () => {
      await dispatch(sanitasAdapterMethods.setUp({
        actionColor: "#7cbc98",
        backgroundColor: "#7cbc98",
        name: `${user.displayName}`,
        email: `${user.email}`,
        state: `${user.state}`,
        currentTimeZone: `${utcTime()}`,
        authUid: `${authUid}`,
        sanitasAccountNumber: `${user.idEcw}`,
        gender: `${user.sex}`,
        birthday:`${user.birthdate}`,
        customAttributes: { cType: 'support' },
      }));
      await dispatch(sanitasAdapterMethods.show());
    }, 500);
  }, [dispatch, sanitasState, user, isSCOpen]);


  const dotContent = !sanitasState.isShow ? (
    <KeraltyDotChatTrigger
      color={'primary'}
      colordot={'primary'}
      colorlabel={'primary'}
      label={t('myHealth.supportChat')}
      svg={SupportChatIcon}
      onClick={() => triggerPatientSupport()}
      position
    />
  ) : null;

  return (
    <RightContent fullHeight bg="light">
      <IconLinkRow
        color="accent"
        onClick={onBackClick}
        icon={arrowAltCircleLeftIcon}
        iconAlt={t('imgNicons.backArrow')}
        text={t('routes.back')}
      />
      <Row>
        <TitleContiner lg={12}>
          <KeraltyText type={'title'}>{t('myHealth.labResultsLong')}</KeraltyText>
        </TitleContiner>
      </Row>
      <Container centered={'x'} noPadding>
        <ResultsContainer>
          <Row>
            <Column lg={11}>
              <KeraltyIcon
                iconClass="fas fa-flask"
                size="common"
                color="accent"
                title={t('imgNicons.flaskIcon')}
              />
              <StudyName color="primary">{itemName}</StudyName>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={12}>
              <span>
                <StyledLabResultsIcon />
                <LabelText type="info" bold>
                  {t('myHealth.clinicalCenter')}
                </LabelText>
                <KeraltyText color="dark" type="info">
                  {clinicalCenter}
                </KeraltyText>
              </span>
            </Column>
            <Column lg={4} md={4} sm={12}>
              <span>
                <KeraltyIcon
                  iconClass="fas fa-file-medical-alt"
                  size="common"
                  color="dark"
                  title={t('imgNicons.medicalFileIcon')}
                />
                <LabelText type="info" bold>
                  {t('myHealth.results')}
                </LabelText>
                <KeraltyText color="dark" type="info">
                  {result}
                </KeraltyText>
              </span>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={12}>
              <span>
                <KeraltyIcon
                  iconClass="fas fa-calendar-day"
                  size="common"
                  color="dark"
                  title={t('imgNicons.calendarDayIcon')}
                />
                <LabelText type="info" bold>
                  {t('myHealth.orderDate')}
                </LabelText>
                <KeraltyText color="dark" type="info">
                  {orderDate}
                </KeraltyText>
              </span>
            </Column>
            <Column lg={4} md={4} sm={12}>
              <span>
                <KeraltyIcon
                  iconClass="fas fa-user-tag"
                  size="common"
                  color="dark"
                  title={t('imgNicons.userTagIcon')}
                />
                <LabelText type="info" bold>
                  {t('myHealth.reason')}:
                </LabelText>
                <KeraltyText color="dark" type="info">
                  {reason}
                </KeraltyText>
              </span>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={12}>
              <span>
                <KeraltyIcon
                  iconClass="fas fa-calendar-day"
                  size="common"
                  color="dark"
                  title={t('imgNicons.calendarDayIcon')}
                />
                <LabelText type="info" bold>
                  {t('myHealth.receivedDate')}
                </LabelText>
                <KeraltyText color="dark" type="info">
                  {receivedDate}
                </KeraltyText>
              </span>
            </Column>
            <Column lg={4} md={4} sm={12}>
              <span>
                <KeraltyIcon
                  iconClass="fas fa-id-card"
                  size="common"
                  color="dark"
                  title={t('imgNicons.idIcon')}
                />
                <LabelText type="info" bold>
                  {t('myHealth.patientId')}
                </LabelText>
                <KeraltyText color="dark" type="info">
                  {patientId}
                </KeraltyText>
              </span>
            </Column>
          </Row>

          <Row>
            <Column lg={11}>
              <span>
                <KeraltyIcon
                  iconClass="fas fa-notes-medical"
                  size="common"
                  color="dark"
                  title={t('imgNicons.medicalNotesIcon')}
                />
                <LabelText type="info" bold>
                  {t('myHealth.notes')}
                </LabelText>
                <KeraltyText color="dark" type="info">
                  {notes}
                </KeraltyText>
              </span>
            </Column>
          </Row>
          <Row>
            <RegularColumn lg={12}>
              <ResultAccessContainer lg={8} md={8} sm={8}>
                <KeraltyText type={'info'} color={'primary'}>
                  {t('myHealth.resultsAccess')}
                </KeraltyText>
              </ResultAccessContainer>
              <CenteredChat lg={4} md={4} sm={4}>
                {dotContent}
              </CenteredChat>
            </RegularColumn>

            <CordovaColumn lg={12}>
              <Row>
                <Column lg={12}>
                  <CenteredText type={'info'} align={'center'} color={'primary'}>
                    {t('myHealth.resultsAccess')}
                  </CenteredText>
                </Column>
              </Row>
            </CordovaColumn>
            <CordovaColumn lg={12}>
              <Row>
                <CenteredChat lg={12}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <label
                      style={{ fontSize: '14px', color: '#055293', marginBottom: '10px', fontWeight: '600' }}
                      htmlFor="">
                      {t('myHealth.supportChat')}
                    </label>
                    <div style={{ background: '#055293', borderRadius: '50%', padding: '12px' }}>
                      <img

                        src={chatImg2}
                        width='46px'
                        alt=""
                        onClick={() => triggerPatientSupport()}
                      />
                    </div>
                  </div>
                </CenteredChat>
              </Row>
            </CordovaColumn>
          </Row>
        </ResultsContainer>
      </Container>
    </RightContent>
  );
};

export default LabResults;
