import { useTranslation } from 'react-i18next';

import { Row } from '../../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../../atoms';

import Icon1 from '../../../../assets/icons/offBoard1.svg';
import Icon2 from '../../../../assets/icons/offBoard2.svg';

import useIsMobile from '../../../../hooks/useIsMobile'
import { AnimationProps, DataOnboarding } from '../MentalHealth.types';
import { useState } from 'react';


const contentBodyStl = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: 10
}
const btnPevStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '14px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29
}
const btnNextStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '15px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29
}




const OffboardingStiking = (animationProps: AnimationProps) => {
	const { nextStep } = animationProps
	const isMobile = useIsMobile();
	const { t } = useTranslation();

	const buttonContentStl = {
		display: 'flex',
		justifyContent: 'right',
		marginLeft: isMobile ? '0 auto' : '11%',
		marginRight: isMobile ? '0 auto' : '7%',
		marginTop: 40
	}
	const btnLetsStl = {
		height: isMobile ? 41 : '',
		marginBottom: isMobile ? '15px' : '',
		marginRight: isMobile ? 20 : '',
		marginTop: isMobile ? 75 : ''
	}


	const data: DataOnboarding[] = [
		{
			id: 0,
			subTitle: t('mentalHealth.challenging'),
			text: t('mentalHealth.challengingText1'),
			icon: Icon1
		},
		{
			id: 1,
			subTitle: t('mentalHealth.challenging'),
			text: t('mentalHealth.challengingText2'),
			icon: Icon2
		}

	];

	const [currentPage, setCurrentPage] = useState(0);

	const handlePrevious = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
	};

	const handleNext = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, data.length - 1));
	};

	console.log('currentPage', currentPage)

	return (
		<Row style={{ display: 'flex', flexDirection: 'row'}}>
			<div style={{ display: 'flex', flexDirection: 'row', height: isMobile ? 370 : '' }} >
				<Row style={{ marginInline: isMobile ? '2%' : '11%', paddingTop: 'auto' }}>

					<Row
						style={contentBodyStl}
					>
						<Image
							height={isMobile ? 27 : 18}
							src={data[currentPage].icon}

							alt={t('imgNicons.myAccount')}
						/>
					</Row>

					<KeraltyText type="subTitle" align="left" bold color={'blueB98'}
						fontSize = {isMobile ? "18px" : "20px"}
						style={{marginTop: isMobile ? 50 : 55 , marginBottom:isMobile?25:10 }}>{data[currentPage].subTitle}
					</KeraltyText>

					<KeraltyText
						align="left"
						type="info"
						color={'TEXTCOLORLIGHT'}
						fontWeight='400'
						style={{ fontSize: 16, marginTop: isMobile ? 'initial' : 10 }}
						dangerouslySetInnerHTML={{ __html: data[currentPage].text }}
					/>

				</Row>
			</div>
			<Row style={buttonContentStl} >
				<KeraltyButton onClick={currentPage<1?handleNext:nextStep}
					style={btnLetsStl}
					fontWeight={ '600' }
				>
					{currentPage<1? t('mentalHealth.next'): t('mentalHealth.start')}
				</KeraltyButton>
			</Row>

		</Row>
	);
};

export default OffboardingStiking;
