import { closeSymptomChecker } from "adapter/chat/chatSlice";
import { useAppDispatch, useAppSelector } from "adapter/hooks";
import { validateInsurance } from 'adapter/payment/paymentSlice';
import { IValidateInsuranceToChatResponse } from "domain/entities/chatWithDocInfo";
import { InsuranceCode } from "domain/valueObjects/insurance";
import { SERVICES_TYPES } from "domain/valueObjects/payment";
import useAuth from "./useAuth";
import { useState } from "react";
import * as sanitasAdapterMethods from '../../adapter/sanitasChat/sanitasChat';
import ChatHttpClient from "infrastructure/keraltyApi/chatHttpClient";
import { ROOM_STATUS } from "app/ui-core/utils/constants";
import useGeneralErrors from "./useGeneralErrors";

const initialChatStatus: IValidateInsuranceToChatResponse = { code: InsuranceCode.default_code, message: "" }

interface KeraltyChatProps {
	queue: 'support' | 'provider' | 'educator';
	state?: boolean;
}

const useChatProvider = () => {

    const dispatch = useAppDispatch();
    const { token, authUid, state } = useAppSelector(state => state.user)
    const sanitasChatState = useAppSelector((state) => state.sanitasChat);
    const { sanitasState } = sanitasChatState;
    const { user } = useAuth();
    const [currentChatStatus, setCurrentChatStatus] = useState<IValidateInsuranceToChatResponse>(initialChatStatus);
	const { Errors, setGeneralError } = useGeneralErrors();

	let isDisabled = false;

	const handleChatWithDoc = () => {
		dispatch(validateInsurance({
			authUid,
			state: state.toUpperCase(),
			token,
			service: SERVICES_TYPES.CHAT_WITH_DOCTOR
		}))
		.unwrap()
		.then( (response: IValidateInsuranceToChatResponse) => {
			if( response.code === InsuranceCode.valid_insurance){
				openChat({queue: 'provider'});
				return
			}
			setCurrentChatStatus(response)
		})
	};

    const closeModalChatStatus = () => setCurrentChatStatus(initialChatStatus);

	const openChat = async ({ queue, state = true }: KeraltyChatProps) => {

		if (isDisabled) return;

		isDisabled = true;
		setTimeout(() => {
		  isDisabled = false;
		}, 5000);

		dispatch(closeSymptomChecker());

		const headers = {
			"Content-Type": "application/json",
		};

		const body = {
			room: `${queue}-${user.email}`,
			private: true,
			cache: false
		};

		try {
			const { data } = await ChatHttpClient.post(`api/Queue/GetRoomIsActive`, body, { headers: headers });
			if( data === ROOM_STATUS.TROUBLE_WITH_CHAT ) {
				setGeneralError(Errors.CHAT_IN_PROGRESS)
				return;
			};

			if (sanitasState.isShow && sanitasState.isSetted) {
			  dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: '', state: false }));
			}
			dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue, state }));
		}catch(err) {}

	}

    return {
        handleChatWithDoc,
        currentChatStatus,
        closeModalChatStatus,
		openChat
    }
}

export default useChatProvider;
