import styled from 'styled-components';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import { StyledWrapper } from 'app/components/templates/wrapper';
import { Card } from 'app/components/templates';

export const ClickeableCard = styled(Card)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    display: flex;
    flex-direccion: column;
    align-items: center;
    justify-content: center;

    ${StyledWrapper} & {
      :after {
        content: '';
        display: block;
        // padding-bottom: 100%;
      }
      padding: 10px;
      height: 170px;
      align-items: stretch;
    }
  }

  cursor: pointer;
`;
