import { useTranslation } from 'react-i18next';

import { Row, Loader, Container } from '../../../templates';
import { KeraltyButton, KeraltyText, Image, KeraltyIcon } from '../../../atoms';
import { KeraltyModal } from 'app/components/molecules';

import theme from '../../../../ui-core/utils/theme';
import './styles.scss';

import prevWhite from '../../../../assets/icons/prevWhite.svg';
import nextWhite from '../../../../assets/icons/nextWhite.svg';

import useIsMobile from '../../../../hooks/useIsMobile'
import i18n from "i18next";
import { dataStinkingThinkingResp, SthinkingProps } from '../MentalHealth.types';
import { useEffect, useState } from 'react';
import MentalHealth from 'infrastructure/keraltyApi/repositories/mentalHealth';


const footerContentStl = {
	display: 'flex',
	//flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	marginBottom: 25,
	paddingLeft: 10
};
const btnPrevStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '14px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 40,
	borderRadius: 29,
	marginTop: 120,
}
const btnNextStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '14px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	borderRadius: 29,
	marginTop: 120,
	marginInline: 40,
}
const btnPrevRespStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '14px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29,


}
const btnNextRespStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '15px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginLeft: 0,
	borderRadius: 29,
	marginInline: 5,

}
const btnResponsive = {
	display: 'flex',
	justifyContent: 'center',
}




const DataStinkingThinking = (sthinkingProps: SthinkingProps) => {
	const { onCallback, nextStep } = sthinkingProps

	const isMobile = useIsMobile();

	const { t } = useTranslation();
	const [showModal, setshowModal] = useState(false)
	const [selectedThing, setselectedThing] = useState<any>([])
	const [isLoading, setisLoading] = useState(true)

	const [dataResp, setdataResp] = useState<dataStinkingThinkingResp | any>([])
	const [showAlert1, setshowAlert1] = useState(false)
	const [showAlert2, setshowAlert2] = useState(false)

	const english = () => {
		if (i18n.language.includes('en')) return true
		return false;
	}

	const cardShadowStyle = {
		backgroundColor: 'white',
		boxShadow: '3px 4px 4px 4px rgba(0, 0, 0, 0.3)',
		borderRadius: '20px',
		paddingInline: isMobile ? '10px' : '1px',
		maxWidth: isMobile ? '100%' : '54%',
		height: 'auto',
		border: '1px',
	}


	const data: any[] = [
		{ id: 0, }, { id: 1, }, { id: 2, }, { id: 3, }, { id: 4, }, { id: 5, }, { id: 6, }, { id: 7, }, { id: 8, }, { id: 9, }];
	const createMarkup = (htmlString) => {
		return { __html: htmlString };
	};

	const ModalALert = () => {
		return (<KeraltyModal size='long' textalign='center'>
			<KeraltyIcon style={{ marginTop: 15 }} iconClass="fas fa-exclamation" color={"orange"} size="medium" title={t('imgNicons.paperPlaneIcon')} rounded />

			<Row style={{ marginBottom: 25, marginTop: 25 }}>
				<KeraltyText fontWeight='400' type={'label'} >{showAlert1?t('mentalHealth.alertStinking1') : t('mentalHealth.alertStinking2')}</KeraltyText>

			</Row>
			<Row noMargin>
				<Container centered={'x'} noPadding>
					<KeraltyButton bgcolor={(window.innerWidth < 1023) ? 'light' : 'accent'} noBorders color='accent' style={{ color: (window.innerWidth < 1023) ? '#3CA70D' : '#fff' }}
						onClick={() => {
							setshowAlert1(false)
							setshowAlert2(false)
						}}>
						{t('button.accept')}
					</KeraltyButton>
				</Container>
			</Row>
		</KeraltyModal>)
	}

	const ModaldetailThink = () => {


		return (
			<KeraltyModal size="intermediate" scroll='initial'>
				<div style={{ marginInline: isMobile ? '2%' : '4%', overflow: 'auto', maxHeight: isMobile ? '90vh' : '100%' }}>


					<Row style={{ marginTop: isMobile ? 20 : 20, marginBottom: 15, fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
						<span className="title-container" dangerouslySetInnerHTML={createMarkup(english() ? dataResp[currentPage]?.titleEn : dataResp[currentPage]?.titleEs)} />

					</Row>
					<Row style={{ paddingBottom: 0, paddingTop: isMobile ? 0 : 10, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
						<span className="custom-container" dangerouslySetInnerHTML={createMarkup(english() ? dataResp[currentPage]?.contentEn : dataResp[currentPage]?.contentEs)} />

					</Row>
					<Row style={{ paddingBottom: 25, paddingTop: isMobile ? 5 : 15, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
						<span className="custom-container" dangerouslySetInnerHTML={createMarkup(english() ? dataResp[currentPage]?.quoteEn : dataResp[currentPage]?.quoteEs)} />

					</Row>
					<KeraltyText type="link" bold onClick={() => setshowModal(false)} pb
						style={{ textTransform: 'uppercase', marginTop: 15, display: 'flex', justifyContent: 'center', textAlign: 'center' }}
						color={'accent'}
						align="center"					>
						{t('mentalHealth.close')}
					</KeraltyText>

				</div>

			</KeraltyModal>
		);

	}


	const showDataModal = () => { setshowModal(true) }



	const [currentPage, setCurrentPage] = useState(0);

	const handlePrevious = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
	};

	const handleNext = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, data.length - 1));
	};

	const Footer = () => {
		const isCurrent = (item: number) => {
			if (item == currentPage) return true
			return false
		}
		return (
			<div style={footerContentStl}>
				{data.map((item) => (
					<div key={item.id}
						style={{
							backgroundColor: isCurrent(item.id) ? theme.colors.accent : theme.colors.gray,
							height: isCurrent(item.id) ? 11 : 7, width: isCurrent(item.id) ? 11 : 7,
							borderRadius: isCurrent(item.id) ? 5 : 4,
							marginRight: 12
						}}
					>	</div>
				))}
			</div>
		)
	}
	const isDisabled = (item) => {
		if (selectedThing.includes(item)) return true
		return false
	}
	const addItemThing = (item) => {

		if (isDisabled(item)) {
			const updatedArray = selectedThing.filter((i) => i !== item);
			setselectedThing(updatedArray)
			onCallback(selectedThing.length)
		} else

			if (selectedThing.length < 3) {
				const updatedArray = [...selectedThing, item];
				setselectedThing(updatedArray)
				onCallback(selectedThing.length)
			}

	}


	const needHelp = async () => {
		return await MentalHealth.getStinkingThinking()
			.then((result) => {
				setdataResp(result)
				setisLoading(false)

			})
			.finally(() => console.log(''));
	};
	useEffect(() => {
		onCallback(selectedThing.length)
		needHelp()
	}, [selectedThing])



	return (
		<Row>
			{showAlert1  && <ModalALert />}
			{showAlert2  && <ModalALert />}
			{isLoading && <Loader />}
			{showModal && <ModaldetailThink />}
			<Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
				<div style={{maxWidth: isMobile ? '100%' : '54%'}}>
					<KeraltyText
						type="subTitle"
						align={isMobile ? "left" : "center"}
						style={{ marginBottom: 15, fontSize: isMobile ? '14px' : '16px' }}

						color={'#5B5C5B'}
					>
						{t('mentalHealth.stinkingThinkingLimitMessage')}
					</KeraltyText>
				</div>
			</Row>
			<Row style={{ display: 'flex', justifyContent: 'center', }}>
				{!isMobile && <KeraltyButton

					onClick={handlePrevious}

					style={btnPrevStl}
					children={<Image
						src={prevWhite}
						size={1.5}
						alt={t('imgNicons.myAccount')}
					/>}
				/>}
				<div style={{...cardShadowStyle}}>

					<div style={{ display: 'flex', flexDirection: 'row', }}>
						<Row style={{ marginInline: isMobile ? '20px' : '25px' }}>

							<Row style={{ marginTop: 0, marginBottom: 0, fontSize: 20, display: 'flex', justifyContent: 'left', alignItems: 'left', position: 'relative', top: 0, left: 0 }}>
								<span   className={isDisabled(data[currentPage].id) ? 'title-container' : selectedThing.length ===3 && !isDisabled(data[currentPage].id) ?'title-container-dark': 'title-container'} dangerouslySetInnerHTML={createMarkup(english() ? dataResp[currentPage]?.titleEn : dataResp[currentPage]?.titleEs)} />
							</Row>

							<Row style={{ paddingTop: 0, marginTop: 0 }}>
								<span className="preview-container" dangerouslySetInnerHTML={createMarkup(english() ? dataResp[currentPage]?.previewTextEn : dataResp[currentPage]?.previewTextEs)} />

							</Row>

							<KeraltyText type="link" bold onClick={showDataModal}
								style={{ textTransform: 'uppercase', marginTop: 15, marginBottom: 15, fontSize: isMobile ? 16 : 19 }}

								color={!(selectedThing.length <3) && !isDisabled(data[currentPage].id) ? 'textColorDark' : 'accent'}
							>
								{t('mentalHealth.more')}
							</KeraltyText>

							<Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: isMobile ? 1 : '' }}>
								<KeraltyButton
									color={isDisabled(data[currentPage].id) ? 'light' : selectedThing.length ===3 && !isDisabled(data[currentPage].id) ?'light': 'accent'}
									bgcolor={isDisabled(data[currentPage].id) ? 'accent' : selectedThing.length ===3 && !isDisabled(data[currentPage].id) ?'darkGray': 'light'}
									onClick={() => {!(selectedThing.length <3) && !isDisabled(data[currentPage].id) ? setshowAlert2(false) : addItemThing(data[currentPage].id)}}
									style={{ fontSize: isMobile ? 16 : 19, fontWeight: '700', cursor: isDisabled(data[currentPage].id) ? 'pointer' : selectedThing.length === 3 && !isDisabled(data[currentPage].id) ? 'default' : 'pointer' }}
									noBorders={isDisabled(data[currentPage].id) ? true : false}
									outline
								>{t('mentalHealth.applies')}</KeraltyButton>
							</Row>
						</Row>

					</div>

				</div>

				{!isMobile && <KeraltyButton

					onClick={handleNext}
					style={btnNextStl}
					children={<Image
						src={nextWhite}
						size={1.5}
						alt={t('imgNicons.myAccount')}
					/>}
				/>}
			</Row>
			<div style={{ marginInline: 'auto', marginTop: 25 }}>
				<Footer />
			</div>
			{isMobile &&
				<Row style={btnResponsive}>
					<KeraltyButton

						onClick={handlePrevious}
						style={btnPrevRespStl}
						children={<Image
							src={prevWhite}
							size={1.5}
							alt={t('imgNicons.myAccount')}
						/>}
					/>
					<KeraltyButton

						onClick={handleNext}
						style={btnNextRespStl}
						children={<Image
							src={nextWhite}
							size={1.4}
							alt={t('imgNicons.myAccount')}
						/>}
					/>


				</Row>}
			<Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
				<KeraltyText type="link" bold onClick={selectedThing.length > 0 ? nextStep : ()=>null}
					style={{ textTransform: 'uppercase', marginTop: 15, marginBottom: 15, fontSize: isMobile ? 16 : 19 }}

					color={selectedThing.length > 0 ? 'accent' : 'textColorDark'}
				>
					{t('mentalHealth.already')}
				</KeraltyText>
			</Row>
		</Row>
	);
};

export default DataStinkingThinking;
