import { useMemo, useState } from 'react';
import styled from '../ui-core/styled-components';
import Header from 'app/components/Header';
import { Layout, Column, Row, Card } from '../components/templates';
import { KeraltyText } from '../components/atoms';
import Sidebar from '../components/Sidebar';
import SidebarCordova from 'app/components/SidebarCordova';
import BREAKPOINTS from '../ui-core/utils/breakpoints';
import ROUTES from '../ui-core/utils/routes';
import blueCurvedVector from '../assets/images/blue-curved-vector-large.png';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { StyledWrapper } from '../components/templates/wrapper';
import useIsMobile from 'app/hooks/useIsMobile';

const Body = styled(Row) <any>`
  display: flex;
  margin: 0;
  ${({ theme: { sizes }, isBannerShowed }) => `
		min-height: calc(100% - ${sizes.header} + 14px);
		max-height: calc(100% - ${sizes.header} + 14px);

		overflow-y: auto;
		${isBannerShowed ? '' : `margin-top: calc(${sizes.header} + 10px);`}
		@media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
			margin-top: calc(${sizes.header} - 8px);
		}
		${StyledWrapper} & {
			padding-top:  3rem;
		}
	`}

  & > div {
    min-height: 100%;
    max-height: 100%;
  }
  * {
    transition: all 0.25s;
    -webkit-ransition: all 0.25s;
  }
`;

const Text = styled(KeraltyText)`
  padding-inline: 20px;
`;
const Banner = styled(Card)`
  position: fixed;
  z-index: 2;
  padding: 0px;
  margin: 0px;
  height: 6.33rem;
  border-radius: 0px;
  width: 100%;
  padding-top: 10px;
  margin-top: ${({ theme: { sizes } }) => sizes.header};
  background-size: auto;
  background-position-y: bottom;
  span {
    font-size: 3.17rem;
  }
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    display: none;
  }
`;

const MainPage = ({ children }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const match = useRouteMatch('/:route');

  const showBanner = useMemo(() => match?.url !== `/${ROUTES.MAIN.home}`, [match]);

  const userName = user?.firstName.charAt(0) + user?.firstName.slice(1).toLowerCase();
  const SidebarComponent = isMobile ? SidebarCordova : Sidebar;
  const userId = user?.idEcw;

  return (
    <Layout bg={isMenuOpen ? 'light' : 'darkLight'}>
      <Header
	  	toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
	  >
        {showBanner ?
          <Banner bgImg={blueCurvedVector} bg="primary">
            <Row style={{ justifyContent: 'space-between' }}>
              <Text type="big" color={'light'} bold>
                {`${t('home.hi')} ${userName ?? ''}!`}
              </Text>
              {user?.idEcw ?
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', marginRight: '5%' }}>
                  <Text fontSize='14px !important' align='right' type="info" color={'light'}>
                    {t('home.yourId')}
                  </Text>
                  <Text fontSize='14px !important' align='right' type="info" color={'light'}>
                    {userId ? userId : ''}
                  </Text>
                </div>
                : ''}
            </Row>
          </Banner>
          : <></>}
      </Header>
      <Body isBannerShowed={showBanner} style={showBanner && !isMobile ?{marginTop: 175} : undefined}>
        <Column lg={12} md={isMenuOpen ? 0 : 12} sm={isMenuOpen ? 0 : 12} noPadding>
          {children}
        </Column>
        <Column lg={0} md={isMenuOpen ? 12 : 0} sm={isMenuOpen ? 12 : 0}>
          {isMenuOpen && <SidebarComponent toggleMenu={(v: boolean) => setIsMenuOpen(v)} />}
        </Column>
      </Body>
    </Layout>
  );
};

export default MainPage;
