const nsPatientRegistration = {
  en: {
    responsibleInfo: 'Responsible party information',
	fieldsObligatorios: "All fields with * are mandatory",
    stepTitleA: "Party Information",
		stepTitleB: "Insurance Information",
		stepTitleC: "Consents",
    insuranceInfo: 'Primary insurance information',
    insuranceCardDesc:
      '(Please make sure to write Name and Last name of insured exactly as they appear in the insurance card)',
    insuranceInfo2nd: 'Secondary insurance information',
    secondaryInsuranceCheck: 'I have a secondary insurance',
    consentToTreatment: 'Consent to treatment',
    consentSubtitle: 'Consents',
  },
  es: {
    responsibleInfo: 'Información del representante',
    fieldsObligatorios: "Todos los campos con * son obligatorios",
    stepTitleA: "Información del representante",
		stepTitleB: "Información seguro principal",
		stepTitleC: "Consentimientos",
    insuranceInfo: 'Información del seguro principal',
    insuranceCardDesc: '(Presente su tarjeta de seguro en la recepción al momento del check-in)',
    insuranceInfo2nd: 'Información del seguro secundario',
    secondaryInsuranceCheck: 'Tienes un seguro secundario',
    consentToTreatment: 'Consentimiento para el tratamiento',
    consentSubtitle: 'Consentimientos',
  },
};

export default nsPatientRegistration;
