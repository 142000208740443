import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './app/assets/fonts/proxima_nova/proxima-nova-regular.otf';
import './app/assets/fonts/lato/lato-regular.ttf';
import './app/assets/fonts/roboto/roboto-regular.ttf';
import App from './App';
import { store, persistor } from './adapter/store';
import theme from './app/ui-core/utils/theme';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import Wrapper from './app/components/templates/wrapper';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import appConfig from 'config';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

declare global {
	interface Window {
	  Fingerprint: {
		show: (
			config: {
			  clientId: string;
			  clientSecret: string;
			  disableBackup: boolean;
			  confirmationRequired: boolean;
			},
			onSuccess: (result: any) => void,
			onError: (error: { code: number; message?: string }) => void
		  ) => void;
	  };
	}
}

root.render(
  <React.StrictMode>
	<KeraltySWS.Provider
		swOptions={{
			key: appConfig.KERALTY_SWS_KEY,
			typeStorage: 'sessionStorage',
		}}
	>
		<ReduxProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider theme={theme}>
					<Wrapper>
						<App />
					</Wrapper>
				</ThemeProvider>
			</PersistGate>
		</ReduxProvider>
	</KeraltySWS.Provider>
  </React.StrictMode>
);

navigator?.serviceWorker?.getRegistrations().then((registrations) => {
  for (let registration of registrations) {
    registration.unregister();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// if(process.env.production !== 'development')
// if (window.location.hostname !== 'localhost')
// 	navigator?.serviceWorker?.register(`${process.env.PUBLIC_URL}/service-worker.js`);
// else serviceWorker.unregister();
