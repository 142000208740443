import styled from 'styled-components';
import Image from '../image';
import KeraltyText from '../keraltyText';

interface SectionWidgetProps {
	innerWidth: number;
}

interface KeraltyTextDescriptionProps {
	isMobile: boolean;
  }

export const SectionWidget = styled.div<SectionWidgetProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  paddingTop: ${(props) => (props.innerWidth >= 600 ? 7 : 0)};
  whiteSpace: ${(props) => (props.innerWidth >= 600 ? 'pre-line' : 'normal')};
`;

export const ImageWidget = styled(Image)`
  width: auto;
  height: 35px;
  margin-bottom: 18px;
`;

export const KeraltyTextDescription = styled(KeraltyText)<KeraltyTextDescriptionProps>`
  text-align: center;
  line-height: 1.3;
  margin-top: 5px;
  height: ${(props) => (props.isMobile ? '32px' : 'auto')};
  color: #747474;
  font-size: 14px;
  font-weight: 600;

  @media (max-width: 768px) {
  	font-size: 13px;
  }

  @media (max-width: 480px) {
  	font-size: 12px;
  }
`;
