import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SideBarSectionWidget } from '.';
import { Row, Column, SpaceWrapper, Container } from '../templates';
import { Tab as TabInteface } from '../../hooks/useTabs';
import { KeraltyText, Divider, Image, KeraltyIcon } from '../atoms';
import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import homeIcon from '../../assets/icons/homeIcon.svg';

import theme from 'app/ui-core/utils/theme';
import useIsMobile from 'app/hooks/useIsMobile';

type SideTabs = TabInteface & { content?: any };
export interface SidebarProps {
  sideTabs: SideTabs[];
  routeLabel?: string;
  activeByRoute: string;
  show?: any;
  hideMenu?: boolean;
}

const TabsWrapper = styled(Column)`

@media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
  >div{
    margin-top: ${props => props.show ? "10%" : "0"};
    display: ${props => props.show ? "none" : "block"};
  }
   /* { */
    height: auto !important;
    box-shadow: none !important;
    z-index: 1;
    margin-top: ${props => props.show ? "0" : "30px"};
  /* } */
}
`;

const SideTab = styled(Row)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin: 0;
    flex: 1 0 0;
    background: #ffffff;
    box-shadow: 2px 4px 4px #dbdbdb;
    border-radius: 10px;
    margin-bottom: 15px;
  }
`;

const DividerStyled = styled(Divider)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    display: none;
  }
`;

const FixedRow = styled(Column)`
  box-shadow: 1px 7px 14px -3px #c4c4c4;
  position: fixed;
  height: -webkit-fill-available;
  overflow-y: auto;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    box-shadow: none !important;
    max-height: 100vh !important;
    position: static;
    overflow-y: visible;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    justify-content: space-around;
    flex-direction: column !important
  }
`;

const TitleColumn = styled(Column)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    //display: none !important;
    margin-left: 35%;
  }
`;

const Tab = styled.div`
  width: 100%;
  display: block;
  margin: 0px;
  cursor: pointer;

  h3 {
    font-size: 1.5rem;
  }

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center !important;
    padding: 0.5rem;
    box-sizing: border-box;
    height: 40px;
    h3 {
      font-size: calc(9px + 1vw);
      text-align: center;
    }
  }
`;

const SectionRow = styled(Row)`
  margin-top: 30px;
  height: auto;
  position: absolute;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    flex-direction: column;
  }
  @media (max-width: ${BREAKPOINTS.minTabletWidth}) {
    height: auto;
    margin-top: 10px;
  };
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    background: #f5f5f5;
    padding-bottom: 5%
  }
`
const Breadcrumbs = styled(Row)`
  // margin-left: 25px;
  // margin-top: 5px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    display: none;
  }
`
const ContainerText = styled(Container)`
  margin-inline: 10px;
  cursor: pointer;
`

const NavigationText = styled.div`
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5B5C5B;
  text-transform: capitalize;
`

const SecondaryText = styled.div`
  margin-left: 5px;
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5B5C5B;
`

const KeraltySidebar = ({ sideTabs, routeLabel, activeByRoute, hideMenu }: SidebarProps) => {
  const history = useHistory();
  const { push } = useHistory();
  const isMobile = useIsMobile();

  const activeTab = useMemo(
    () => sideTabs.find(({ name }) => name === activeByRoute),
    [sideTabs, activeByRoute]
  );
  const isShow = activeTab?.showOnMobile === undefined || activeTab?.showOnMobile === true ? true : false;


  const splitRoute = activeTab.routeName.split('/');
  const firstRoute = splitRoute[0];
  const handleBread = () => {
    push(`/${firstRoute}`);
 };

  return (
    <Row noMargin style={{ height: '100%', marginTop: isMobile ? '2%' : '0%', background: isMobile ? '#f5f5f5' : '#ffffff' }}>
      {(activeTab?.text && !isMobile ) || activeTab.showRoute ?
        <Breadcrumbs noMargin style={{ visibility: isMobile ? 'hidden' : 'visible' }}>
          <div style={{background: '#f5f5f5', width: '100%', paddingBottom: '10px' }}>
            <Image src={homeIcon} height={1.5} style={{marginLeft: '45px'}} />
            <ContainerText noPadding
              onClick={handleBread}>
              <NavigationText>{routeLabel ? routeLabel : ''}</NavigationText>
            </ContainerText>
            {
              !hideMenu &&
              (
                <>
                {
                  !activeTab.showRoute
                  ? (
                    <>
                      <KeraltyIcon className={'bread-icon'} iconClass={'fas fa-chevron-right'} color={'darkGray'} />
                      <SecondaryText>{activeTab.text}</SecondaryText>
                    </>
                  )
                  : null
                }
                </>
              )
            }
          </div>
        </Breadcrumbs>
        : <></>}

      <SectionRow bg="light" fullHeight fullHeightChildren={false}>
        {!hideMenu ?
          <TabsWrapper lg={3} md={12} sm={12} noPadding show={isShow === true ? false : true}>

            <FixedRow lg={3} md={12} sm={12} noPadding>

              <StyledRow>
                <TitleColumn lg={12}>
                  <SpaceWrapper my={3} ml={3}>
                    <KeraltyText type="subTitle" bold>
                      {routeLabel ? routeLabel : ''}
                    </KeraltyText>
                  </SpaceWrapper>
                </TitleColumn>
                {sideTabs.map((tab, i) => (
                  tab.text ?
                    <SideTab key={`tab-${i}`}>
                      <Tab>
                        <SideBarSectionWidget
                          textColor={activeTab?.name === tab.name ? 'accent' : undefined}

                          onClick={() => {
                            tab.callbackClick ? tab.callbackClick() : history.replace(`/${tab.routeName}`)
                          }
                          }
                          svg={tab.icon}
                          text={tab.text}
                        />
                      </Tab>
                      {sideTabs.length != i+1 && <DividerStyled my={1.5} />}
                    </SideTab>
                    : null
                ))}
              </StyledRow>

            </FixedRow>

          </TabsWrapper>
          : null
        }

        {

          !hideMenu && (
            <Column lg={9} md={12} sm={12} noPadding={!isMobile ? false : true}>
              {activeTab ? activeTab.content : null}
            </Column>
          )
        }
        {
          hideMenu && (
            <Column lg={12} md={12} sm={12} noPadding={!isMobile ? false : true}>
              {activeTab ? activeTab.content : null}
            </Column>
          )
        }
      </SectionRow >

    </Row>
  );
};

export default KeraltySidebar;
