import { useState, useEffect, useMemo, useCallback } from 'react';

import useAuth from '../../../hooks/useAuth';

import Select from 'react-select';

import {
  Row,
  Column,
  IconLinkRow,
  Loader,
  FormBox,
  Container,
  SpaceWrapper,
} from '../../templates';

import {
  Divider,
  KeraltyButton,
  KeraltyText,
  Image,
  KeraltyError,
  KeraltyDatePicker,
} from '../../atoms';

import { EcwRepository } from '../../../../infrastructure/keraltyApi/repositories/ecw';

import AuthRepository from '../../../../infrastructure/keraltyApi/repositories/auth';

import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';

import icon from '../../../assets/icons/circleCheck.svg';

import icon2 from '../../../assets/icons/circleAdmiration.svg';

import styled from '../../../ui-core/styled-components';

import { KeraltyField, KeraltyModal } from 'app/components/molecules';

import { AvailityInfo, GetAvailityStatus, UpdateInsuranceData } from 'domain/entities/availityInfo';

import { AvailityInfoSchema } from '../../../ui-core/schemas/availityInfo';

import { yupResolver } from '@hookform/resolvers/yup';

import useIsMobile from '../../../hooks/useIsMobile';

import React from 'react';

import { useHistory } from 'react-router-dom';

import { gridClasses } from '@mui/material';
import moment from 'moment';
import FORMATS from 'app/ui-core/utils/formats';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { COMPANY, SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

type FormType = AvailityInfo;

const KeraltyButtonStyle = styled(KeraltyButton)`
  font-size: 1.35rem;

  padding: 0 1rem;
`;

interface FormUpdateInsuranceProps {
  state: string;
  memberId: number;
  insuranceName: string;
  isPrimary: boolean;
  isFb: boolean;
  isTn: boolean;
  isSc: boolean;
  handleShowForm(arg: boolean): void;
}

const Icon = styled(Image)`
  width: 100%;
  height: 35px;
  margin-right: 1.5rem;
  margin-bottom: 20px;
`;

const styleButton = {
  height: 51,
  borderRadius: 40,
  color: '#FFF',
  marginRight: 10,
  marginLeft: 5,
  marginTop: 10,
  paddingTop: 0,
  paddingBottom: 0,
  marginBottom: 0,
  backgroundColor: '#3CA70D',
  border: 'none',
  borderColor: '#FFFFFF',
  fontSize: 18,
  fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
  cursor: 'pointer',
};

const styleButton2 = {
  color: '#3CA70D',
  marginRight: 22,
  marginLeft: 5,
  marginTop: 10,
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: '#FFFFFF',
  borderRadius: 40,
  height: '51px',
  borderColor: '#3CA70D',
  fontSize: 16,
  fontWeight: 'bold',
  fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
  borderWidth: 2,
  width: '98px',
};

const styleDiv = {
  padding: 0,
  margin: 0,
  flex: 1,
  justifyContent: 'center',
};

const styleText = {
  textAlign: 'center',
  marginRight: 7,
  fontSize: 16,
  fontWeight: 400,
  color: '#212121',
};

const styleText2 = {
  textAlign: 'center',
  marginRight: 7,
  fontSize: 14,
  fontWeight: 600,
  color: '#5B5C5B',
};

const styleTitle = {
  textAlign: 'center',
  marginRight: 7,
  fontSize: 24,
  fontWeight: 600,
  color: '#0069A7',
};

const FormUpdateInsurance: React.FC<FormUpdateInsuranceProps> = (props) => {
  const { state, memberId, insuranceName, isPrimary, handleShowForm, isFb, isTn, isSc } = props;
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const [insurersData, setInsurersData] = useState<any>([]);
  const [showPopUp, setShowPOpUp] = useState<any>();
  const [dataUpdate, setDataUpdate] = useState<UpdateInsuranceData>();
  const [showModal, setShowModal] = useState(undefined);
  const [showPopUpInactive, setShowPOpUpInactive] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const dataInsurers = async () => {
    const result = await EcwRepository.loadInsurances();
    return result;
  };

  const availityStatus = async (data: GetAvailityStatus) => {
    let response: any;
    try {
      setIsLoading(true);

      response = await AuthRepository.availityStatus(data);
      setShowError(false);
      setIsLoading(false);
    } catch (e: any) {
      if (e.code == '400') {
        response = false;
        setIsLoading(false);
      } else {
        response = { error: true };
        setShowError(true);
        setIsLoading(false);
      }
    }

    return response;
  };

  const updateInsuranceData = async (data: UpdateInsuranceData) => {
    let response: any;

    try {
      setIsLoading(true);
      response = await AuthRepository.updateInsuranceData(data);
      setShowError(false);
      setIsLoading(false);
      // if( showPopUp === 'false' ){
      //  setShowPOpUp(undefined)
      //  user.idEcw && setShowPOpUpInactive(true)
      //  return
      // }
      // setShowPOpUp('true')
    } catch (e) {
      response = { error: true };
      setShowError(true);
      setIsLoading(false);
    }
    return response;
  };

  const handleOnClose = () => {
    handleShowForm(false);
    window.location.reload();
  };

  const ModalUpdate = () => {
    return (
      <KeraltyModal onClickContainer={''} size={'extraLong'}>
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <Icon src={icon} alt={t('errors.sessionExpired')} />

          <SpaceWrapper mb={1} style={{ paddingTop: 20 }}>
            <Container centered={'x'} noPadding noMargin>
              <KeraltyText type={'label'} align={'center'} style={styleTitle}>
                {t('button.UpdatePupTitle')}
              </KeraltyText>
            </Container>
          </SpaceWrapper>

          {/* <SpaceWrapper mb={1} style={{ paddingTop: 15 }}>

            <Container centered={'x'} noPadding noMargin>

              <KeraltyText type={'label'} align={'center'} style={styleText}>

                {t('button.updatePop')}

              </KeraltyText>

            </Container>

          </SpaceWrapper> */}
        </div>

        <Row noMargin>
          <div style={styleDiv}>
            <button style={styleButton} type="button" onClick={() => updateDataActive()}>
              <text style={{ paddingInline: 30 }}> {t('button.ok')} </text>
            </button>
          </div>
        </Row>
      </KeraltyModal>
    );
  };

  const ModalUpdateInactive = () => {
    return (
      <KeraltyModal onClickContainer={''} size={'extraLong'}>
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <Icon src={icon} alt={t('errors.sessionExpired')} />

          <SpaceWrapper mb={1} style={{ paddingTop: 20 }}>
            <Container centered={'x'} noPadding noMargin>
              <KeraltyText type={'label'} align={'center'} style={styleTitle}>
                {t('button.UpdatePupTitleInactive')}
              </KeraltyText>
            </Container>
          </SpaceWrapper>

          <SpaceWrapper mb={1} style={{ paddingTop: 15 }}>
            <Container centered={'x'} noPadding noMargin>
              <KeraltyText type={'label'} align={'center'} style={styleText}>
                {t('button.updatePopInactive')}
              </KeraltyText>
            </Container>
          </SpaceWrapper>
        </div>

        <Row noMargin>
          <div style={styleDiv}>
            <button style={styleButton} type="button" onClick={() => handleOnClose()}>
              <text style={{ paddingInline: 30 }}> {t('button.ok')} </text>
            </button>
          </div>
        </Row>
      </KeraltyModal>
    );
  };

  const ModalInactive = () => {
    return (
      <KeraltyModal size={'extraLong'} onClickContainer={''}>
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <Icon src={icon2} alt={t('errors.sessionExpired')} />

          <SpaceWrapper mb={1} style={{ paddingTop: 20 }}>
            <Container centered={'x'} noPadding noMargin>
              <KeraltyText type={'label'} style={styleTitle}>
                {t('button.inactiveTitle')}
              </KeraltyText>
            </Container>
          </SpaceWrapper>

          <SpaceWrapper mb={1} style={{ paddingTop: 15 }}>
            <Container centered={'x'} noPadding noMargin>
              <KeraltyText type={'label'} align={'center'} style={styleText}>
                {t('button.inactive')}
              </KeraltyText>
            </Container>
          </SpaceWrapper>
        </div>

        <Row noMargin>
          <div style={styleDiv}>
            <button style={styleButton} type="button" onClick={() => setShowPOpUp(undefined)}>
              <text style={{ paddingInline: 30 }}> {t('button.no')} </text>
            </button>
          </div>

          <div style={styleDiv}>
            <KeraltyButtonStyle
              type={'button'}
              color={'light'}
              full
              onClick={() => {
                dataUpdate && onValidSubmitUpdate(dataUpdate);
              }}
              style={styleButton2}
            >
              <text style={{ color: '#3CA70D', fontSize: 18, letterSpacing: 1 }}>
                {t('button.yes')}
              </text>
            </KeraltyButtonStyle>
          </div>
        </Row>
      </KeraltyModal>
    );
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<FormType>({
    mode: 'onBlur',
    resolver: yupResolver(AvailityInfoSchema),
    //defaultValues: { isAuthSelected: false, ...insurance }
  });

  const getInsurerList = async () => {
    setInsurersData(await dataInsurers());
  };

  const updateDataActive = () => {
    handleOnClose();

    //history.push(`my-insurance`);
  };

  const companyOptions = useMemo(
    () =>
      insurersData?.map(({ id: value, name: label, ...rest }) => ({
        value,
        label,
        ...rest,
      })),

    [insurersData]
  );

  const onValidSubmitAvaility = useCallback(
    async (value: FormType) => {
      const dataReq: any = {
        firstName: value.firstName,
        lastName: value.lastName,
        birthDate: user.birthdate,
        memberId: value.memberId,
        authUid: user.authUid ?? '',
        state: state,
        companyId: value.companyId,
        insuranceName: value.company,
        planType: value.planType ?? '',
        relationshipId: value.relationshipId,
        holderInsured: {
          name: value.holderName,
          lastName: value.holderLastName,
          dateOfBirth: value.holderDateOfBirth,
        },
      };

      const dataReqUpdate: UpdateInsuranceData = {
        patientId: user.idEcw,
        authUid: user.authUid ?? '',
        insuranceName: value.company,
        memberId: value.memberId,
        insuredFirstName: value.firstName,
        insuredLastName: value.lastName,
        subscriberRelationship: value.relationship,
        state: state,
        groupId: value.authUid ?? '',
        isPrimary: isPrimary,
        companyId: value.companyId,
        relationshipId: value.relationshipId,
        planType: value.planType ?? '',
        holderInsured: {
          name: value.holderName ?? '',
          lastName: value.holderLastName ?? '',
          dateOfBirth: value.holderDateOfBirth
            ? moment(value.holderDateOfBirth).utc().format(FORMATS.dateISO8601)
            : '',
        },
      };

      setDataUpdate(dataReqUpdate);

      const response = await availityStatus(dataReq);

      if (response) {
        updateInsuranceData(dataReqUpdate);
        setShowPOpUpInactive(true);
      } else if (response === false) {
        setShowPOpUp('false');
      } else {
        const response = await updateInsuranceData(dataReqUpdate);
        if (!response.error) setShowPOpUp('true');
      }

      // if (isFb || isTn || isSc) {
      // const response = await availityStatus(dataReq);
      // } else {
      // setShowPOpUp(true)
      // updateInsuranceData(dataReqUpdate)
      // }
      // if (isFb || isTn || isSc) {
      //  const response = await availityStatus(dataReq);
      //  if (response == true){
      //    const responseUpdate = await updateInsuranceData(dataReqUpdate)
      //    if(!responseUpdate?.error) setShowPOpUp(true)
      //  }
      // } else {
      //  const responseUpdate = await updateInsuranceData(dataReqUpdate)
      //  if(!responseUpdate?.error) setShowPOpUp(true)
      // }

      reset({ ...value });
    },
    [reset]
  );

  const onValidSubmitUpdate = useCallback(
    (value: UpdateInsuranceData) => {
      setShowPOpUp('true');
      // setShowPOpUpInactive(true)

      updateInsuranceData(value);
      KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.IS_PRIMARY, '');
      KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.INSURANCE_NAME, '');
      KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.SHOW_FORM, 'false');
      //reset({ ...value });
    },

    [reset]
  );

  const { t } = useTranslation();

  const patientRelationshipOptions = useMemo(
    () => [
      { label: t('select.patientRelationship.self'), value: '1' },
      { label: t('select.patientRelationship.spouse'), value: '2' },
      { label: t('select.patientRelationship.naturalChild'), value: '3' },
      { label: t('select.patientRelationship.naturalChild2'), value: '4' },
      { label: t('select.patientRelationship.stepChild'), value: '5' },
      { label: t('select.patientRelationship.fosterChild'), value: '6' },
      { label: t('select.patientRelationship.ward'), value: '7' },
      { label: t('select.patientRelationship.employee'), value: '8' },
      { label: t('select.patientRelationship.unknown'), value: '9' },
      { label: t('select.patientRelationship.handicapped'), value: '10' },
      { label: t('select.patientRelationship.organ'), value: '11' },
      { label: t('select.patientRelationship.cadaver'), value: '12' },
      { label: t('select.patientRelationship.granchild'), value: '13' },
      { label: t('select.patientRelationship.nephew'), value: '14' },
      { label: t('select.patientRelationship.injured'), value: '15' },
      { label: t('select.patientRelationship.sponsored'), value: '16' },
      { label: t('select.patientRelationship.minor'), value: '17' },
      { label: t('select.patientRelationship.parent'), value: '18' },
      { label: t('select.patientRelationship.grandparent'), value: '19' },
      { label: t('select.patientRelationship.other'), value: '99' },
    ],
    [t]
  );

  useEffect(() => {
    getInsurerList();
  }, []);

  useEffect(() => {
    if (watch('company') && watch('company').toLowerCase() === COMPANY.SELFPAY) {
      setValue('relationshipId', '1', { shouldValidate: true });
      setValue('relationship', "Uno mismo", { shouldValidate: true });
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('memberId', '0');
      setValue('authUid', '0');

      clearErrors('relationshipId');
      clearErrors('firstName');
      clearErrors('lastName');
      clearErrors('memberId');
      clearErrors('authUid');
    } else {
      setValue('relationshipId', '');
      setValue('relationship', '');
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('memberId', '');
      setValue('authUid', '');
    }
  }, [watch('company')]);

  useEffect(() => {
    if (watch('relationshipId') === '1') {
      setValue('holderName', '');
      setValue('holderLastName', '');
      setValue('holderDateOfBirth', undefined);

      clearErrors('holderName');
      clearErrors('holderLastName');
      clearErrors('holderDateOfBirth');
    }
  }, [watch('relationshipId')]);


  return (
    <Row noMargin>
      {isLoading && <Loader />}

      <FormBox width="100%" autoComplete={'off'} onSubmit={handleSubmit(onValidSubmitAvaility)}>
        <Container style={{ paddingTop: 20, marginLeft: 30, marginRight: 30 }}>
          <KeraltyText type={'label'} align={'center'} style={styleText2}>
            {t('myAccount.updateText')}
          </KeraltyText>
        </Container>

        {/* CONSENTS DESCRIPTIONS */}
        {/* FIELDS */}
        {/* TREATMENT */}
        <br />
        {/* EMAIL */}
        {/* SIGNATURE & DATE */}
        <Row>
          <Column lg={6}>
            <KeraltyField
              iconClass="fas fa-building"
              title={t('imgNicons.mapMarkedIcon')}
              placeholder={t('formLabel.insuranceCompany')}
              label={t('formLabel.insuranceCompany')}
              type={'select'}
              control={Select}
              options={companyOptions}
              onChange={(v) => {
                setValue('company', v.label, { shouldValidate: true });
                setValue('companyId', v.value, { shouldValidate: true });
                setValue('planType', v.planType, { shouldValidate: true });
              }}
              value={companyOptions?.find(
                ({ value }) => Number(value) === Number(watch('companyId'))
              )}
              error={errors.company}
              mandatory
            />
          </Column>

          <Column lg={6}>
            <KeraltyField
              iconClass={'fas fa-user'}
              title={t('imgNicons.userIcon')}
              placeholder={t('formLabel.insuredName2')}
              label={t('formLabel.insuredName2')}
              field={register('firstName')}
              error={errors.firstName}
              mandatory
              disabled = {watch('company') && watch('company').toLowerCase() === COMPANY.SELFPAY ? true : false}
            />
          </Column>

          <Column lg={6}>
            <KeraltyField
              iconClass={'fas fa-user'}
              title={t('imgNicons.userIcon')}
              placeholder={t('formLabel.insuredLastName2')}
              label={t('formLabel.insuredLastName2')}
              field={register('lastName')}
              error={errors.lastName}
              mandatory
              disabled = {watch('company') && watch('company').toLowerCase() === COMPANY.SELFPAY ? true : false}
            />
          </Column>

          <Column lg={6}>
            <KeraltyField
              iconClass={'fas fa-user'}
              title={t('imgNicons.mapMarkedIcon')}
              placeholder={t('formLabel.patientRelationship2nd2')}
              label={t('formLabel.patientRelationship2nd2')}
              type={'select'}
              control={Select}
              options={patientRelationshipOptions}
              onChange={(v) => {
                setValue('relationship', v.label, { shouldValidate: true });
                setValue('relationshipId', v.value, { shouldValidate: true });
              }}
              value={patientRelationshipOptions.find(
                ({ value }) => value === watch('relationshipId')
              )}
              error={errors.relationshipId}
              mandatory
              isDisabled = {watch('company') && watch('company').toLowerCase() === COMPANY.SELFPAY ? true : false}
            />
          </Column>

          <Column lg={6}>
            <KeraltyField
              iconClass={'fas fa-id-card'}
              title={t('imgNicons.idIcon')}
              placeholder={t('formLabel.subscriberId2')}
              label={t('formLabel.subscriberId2')}
              field={register('memberId')}
              // mask={MASK.number8}
              disabled = {watch('company') && watch('company').toLowerCase() === COMPANY.SELFPAY ? true : false}
              error={errors.memberId}
              mandatory
            />
          </Column>

          <Column lg={6}>
            <KeraltyField
              iconClass={'fas fa-id-card'}
              title={t('imgNicons.idIcon')}
              placeholder={t('formLabel.groupId2')}
              label={t('formLabel.groupId2')}
              field={register('authUid')}
              disabled = {watch('company') && watch('company').toLowerCase() === COMPANY.SELFPAY ? true : false}
              error={errors.authUid}
            />
          </Column>

          {watch('relationshipId') && watch('relationshipId') !== '1' && (
            <>
              <Divider
                style={{ marginTop: 20, width: '90%', marginLeft: '5%', marginRight: '5%' }}
              />

              <Column lg={6}>
                <KeraltyField
                  iconClass={'fas fa-user'}
                  title={t('imgNicons.userIcon')}
                  placeholder={t('formLabel.placeholderNameHolderInsured')}
                  label={t('formLabel.nameHolderInsured')}
                  field={register('holderName')}
                  error={errors.holderName}
                  mandatory
                />
              </Column>
              <Column lg={6}>
                <KeraltyField
                  iconClass={'fas fa-user'}
                  title={t('imgNicons.userIcon')}
                  placeholder={t('formLabel.placeholderLastnameHolderInsured')}
                  label={t('formLabel.lastnameHolderInsured')}
                  field={register('holderLastName')}
                  error={errors.holderLastName}
                  mandatory
                />
              </Column>
              <Column lg={6}>
                <KeraltyField
                  control={KeraltyDatePicker}
                  customInput={<input {...register('holderDateOfBirth')} />}
                  iconClass={'fas fa-calendar-alt'}
                  title={t('imgNicons.calendarIcon')}
                  label={t('formLabel.birthHolderInsured')}
                  placeholderText={
                    watch('holderDateOfBirth')
                      ? moment(watch('holderDateOfBirth')).format(FORMATS.date)
                      : t('formLabel.placeholderBirthHolderInsured')
                  }
                  onChange={(v) =>
                    setValue('holderDateOfBirth', v || '', {
                      shouldValidate: true,
                      shouldTouch: true,
                    })
                  }
                  selected={watch('holderDateOfBirth')}
                  error={errors.holderDateOfBirth}
                  mandatory
                />
              </Column>
            </>
          )}
        </Row>

        {/* ERRORS */}

        {/*<KeraltyError error={asyncError ? t(`errors.code${asyncError}`) : null} />*/}

        {/* ACTIONS */}

        <Row style={{ justifyContent: 'center', paddingTop: 0 }}>
          <Row style={{ justifyContent: 'center', paddingBottom: 0 }}>
            <KeraltyError error={showError ? t('errors.insuranceError') : ''} />
          </Row>

          <KeraltyButton type={'submit'} smFull>
            {t('button.update')}
          </KeraltyButton>

        </Row>
      </FormBox>

      {showPopUp === 'true' ? <ModalUpdate /> : showPopUp === 'false' ? <ModalInactive /> : null}

      {showPopUpInactive == true ? <ModalUpdateInactive /> : null}
    </Row>
  );
};

export default FormUpdateInsurance;
