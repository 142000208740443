import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from '../../../../ui-core/styled-components';
// UI
import { ReactComponent as aprobadaicon } from '../../../../assets/icons/aprobadaicon.svg';
//import aprobadaicon from '../../../../assets/icons/aprobadaicon.svg';

import { Row, Column } from '../../../templates';
import { KeraltyButton, KeraltyText } from '../../../atoms';
import BREAKPOINTS from '../../../../ui-core/utils/breakpoints';

// ADAPTER
import { useAppSelector } from '../../../../../adapter/hooks';
// LOGIC
import useIsMobile from '../../../../hooks/useIsMobile';
import { ISessionInformation } from '../../../../../domain/entities/paymentInfo';
import moment from 'moment';
import FORMATS from '../../../../ui-core/utils/formats';
import { userSelectors } from 'adapter/user/userSelectors';
import appConfig from 'config';
import ROUTES from '../../../../ui-core/utils/routes';
import { openSafeUrl } from 'app/ui-core/utils/url';

type SvgIconProps = {
    src: React.FC,
    color?: string;
    w?: number | string | null;
    h?: number | string | null;
  }

  const IconWrapper = styled.span<Partial<SvgIconProps>>`
  svg {
    ${({ theme: { colors }, color, w, h }) => `
      color: ${color ? colors[color] : undefined};
      fill: ${color ? colors[color] : undefined};

      path {
        color: ${color ? colors[color] : undefined};
        fill: ${color ? colors[color] : undefined};
      }

      ${h ? `height: ${h}rem !important;` : null}

      ${w ? `width: ${w}rem !important;` : null}
    `}
  }
`

const RightContent = styled(Row)`
    display: flex;
    flex-direction: column;
    flex: 100%;
    align-items: flex-start;
    background: transparent;
    height: auto;
`;
const Container = styled(Column)`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
        @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
            width: auto;
            align-items: center;
            text-align: center;
        }
  }
`;

const SubTitleContainer = styled(Column)`
		justify-content: center;
		margin-right: 20px;
		padding: 1rem 0rem;
		width: 60% !important;
		text-align: center;
		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		text-align: center;
		margin-bottom: '5%' !important;
		display: flex;
		align-items: center;
		width: 80% !important;
	}
`;

const TextSubtitle = styled(Column)`
	color: #5B5C5B !important;
	text-align: center;
	font-size: 16px;
	line-height: 1.58rem;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		text-align: left;
	}
`;


const FormContainer = styled(Row)`
    justify-content: center;

`;

const SvgIcon: React.FC<SvgIconProps> = ({ src: IconComponent, color, w, h }) => (
    IconComponent ? (
      <IconWrapper color={color} w={w ? w : null} h={h ? h : null}>
        <IconComponent />
      </IconWrapper>
    ) : null
  );

const LabelRefBottom = ({ title, text, width = '100%', customSize='16px' }) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    return (
        <Row noMargin style={{ marginBottom: 7, width: '100%',  }}>
            <Column noPadding style={{ width, textAlign: 'left' }}>
                    <span style={{ width: !isMobile ? '100%' : '60%', fontSize: customSize, fontWeight: isMobile ? '700' : '400', color: ! isMobile?'#5B5C5B':'#055293', lineHeight: '30px' }}>{t(title)}</span>
                    <span style={{ textAlign: 'right', overflowWrap: 'break-word', width: !isMobile ? '100%' : '50%', fontSize: customSize, fontWeight: '400', color: ! isMobile?'#5B5C5B':'#055293', lineHeight: '30px', marginRight: isMobile ? '10%' : '40%' }}>{text}</span>
            </Column>
        </Row>
    )
}

interface ITelevisitPayResultProps {
  paymentInfo: ISessionInformation
}


const TelevisitPayResult = ({ paymentInfo }: ITelevisitPayResultProps) => {

    const isMobile = useIsMobile();
    const { push } = useHistory();
    const { t } = useTranslation();
    const { id, transactionInformation: { paymentID, paymentDate, serviceTotalValue } } = paymentInfo;
    const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
    const linkToTelevisit: string = appConfig.TELADOC_URL;
    const { MAIN, GET_CARE } = ROUTES;

    const time = moment(new Date(paymentDate * 1000)).format(FORMATS.date4)

    const handleTelevisit = () => {
	  openSafeUrl(linkToTelevisit);
    };

    return (

       // <CaptchaProvider>
			    <RightContent fullHeight bg="light" style={{ marginLeft: isMobile ? '1%' : '0%' }}>

				    <Container style={{ alignItems: 'center', alignSelf: 'center', marginTop: isMobile ? '10%' : '1%'  }}>
              <Column style={{ display: isMobile ? 'grid' : '-webkit-box', placeItems: 'center', WebkitBoxAlign: 'center', paddingRight: isMobile ? '1%' : '5%' }}>
                <SvgIcon src={aprobadaicon}/>
                <KeraltyText type="title" fontWeight={'400'} fontSize={isMobile ? '24px' : '32px'} style={{fontWeight: 'normal', width: isMobile ? '90%' : '100%'}}>{t('getCare.televisit.transactionApproved')} </KeraltyText>
					    </Column>
    			  </Container>

				    <FormContainer>
              <Container>
                <div style={{ width: '100%', textAlign: 'center' , paddingTop: '3%', paddingLeft: isMobile ? '4.5%' : window.innerWidth === 1366 ? '11%' : '8.5%'  }}>
                    <Row noMargin style={{ width: isMobile ? '92%' : window.innerWidth <= 1280 ? '60%' : window.innerWidth === 1366 ?  '55%' : '43%', paddingTop: 3, paddingLeft: window.innerWidth <= 1280 && !isMobile ? '9%' : 0  }}>
                        <LabelRefBottom title={'getCare.televisit.transactionID'} text={id} />
                        <LabelRefBottom title={'getCare.televisit.transactionState'} text={t('getCare.televisit.approved')} />
                        <LabelRefBottom title={'getCare.televisit.transactionDate'} text={time} />
                        <LabelRefBottom title={'getCare.televisit.transactionNumber'} text={paymentID} />
                        <LabelRefBottom title={'getCare.televisit.transactionTotal'} text={`$${Number.parseFloat(serviceTotalValue.toString()).toFixed(2)}`} customSize={isMobile ? '20px' : '16px'} />
                    </Row>
    					  </div>
                <div style={{ width: '100%', textAlign: 'center', paddingTop: isMobile ? '10%' : '3%' }}>
                  <KeraltyButton
                    onClick={ () => handleTelevisit() }
                    style={{ width: isMobile ? '85%' :  window.innerWidth <= 1280 ? '40%' : window.innerWidth === 1366 ? '35%' : '30%', fontWeight: 600, fontSize: isMobile ? '14px' : '20px' }}
                    full
                    smFull
                  >
                    {t('getCare.televisit.goToVideoCall')}
                  </KeraltyButton>
                </div>
					    </Container>
				    </FormContainer>
			    </RightContent>
		  //  </CaptchaProvider>

    )
}

export default TelevisitPayResult;
