import { useState, useEffect } from 'react';
import BREAKPOINTS from '../ui-core/utils/breakpoints';

function calculateIsOnlyMobile() {
  return window.innerWidth <= Number(BREAKPOINTS.maxPhoneWidth.split('px')[0]);
}

export default function useOnlyMobile() {
  const [isOnlyMobile, setIsOnlyMobile] = useState(calculateIsOnlyMobile());

  useEffect(() => {
    const handleResize = () => {
      calculateIsOnlyMobile() !== isOnlyMobile && setIsOnlyMobile(calculateIsOnlyMobile());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOnlyMobile]);

  return isOnlyMobile;
}
