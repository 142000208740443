import React, { Fragment } from 'react';
import Column from '../templates/Column';
import { CenteredWrapper, SpaceWrapper } from '../templates';
import { Image } from '../atoms';
import KeraltyText from '../atoms/keraltyText';
import SvgIcon from '../atoms/SvgIcon';
import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';

type Props = {
  img?: string;
  alt?: string;
  svg?: React.FC;
  text?: string;
  noMargin?: boolean;
  textColor?: string;
  [key: string]: any;
};

const StyledColumn = styled(Column)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    //flex: 0 0 100%;
    display: flex;
    justify-content:
    padding: 0;
    ${SpaceWrapper} {
      margin-left: 10;
      margin-right: 20px;
    }
  }
`;

const SideBarSectionWidget: React.FC<Props> = ({
  img,
  alt,
  text,
  noMargin = false,
  textColor = undefined,
  svg,
  ...props
}) => (
  <Fragment>
    <StyledColumn lg={2} md={1} sm={1} noPadding>
      <CenteredWrapper>
        <SpaceWrapper ml={2}>
          {img ? <Image src={img} alt={alt} /> : null}
          {svg ? <SvgIcon src={svg} color={textColor} h={2} /> : null}
        </SpaceWrapper>
      </CenteredWrapper>
    </StyledColumn>
    <StyledColumn auto {...props}>
      <CenteredWrapper direction="horizontal">
        <KeraltyText type="subTitle" color={textColor} align={'left'} >
          {text}
        </KeraltyText>
      </CenteredWrapper>
    </StyledColumn>
  </Fragment>
);

export default SideBarSectionWidget;
