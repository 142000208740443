import { yupResolver } from "@hookform/resolvers/yup";
import { KeraltyButton, KeraltyError, KeraltyRadio, KeraltyText } from "app/components/atoms";
import { KeraltyField } from "app/components/molecules";
import { Container, FormBox, Loader } from "app/components/templates";
import { useTwoFactorAuthentication } from "app/hooks/useTwoFactorAuthentication";
import { ContactInfoSchema } from "app/ui-core/schemas/contactInfo";
import BREAKPOINTS from "app/ui-core/utils/breakpoints";
import { UserContactMethods } from "domain/entities/userContactMethods";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
import ROUTES from "app/ui-core/utils/routes";
import i18n from "app/ui-core/i18n";
import { TwoFactorAuthenticationSteps } from "adapter/twoFactorAuthentication/twoFactorAuthentication.state";
import useIsMobile from "app/hooks/useIsMobile";

const { MAIN, REGISTER: CURRENT } = ROUTES;

const TitleWrapper = styled.div`
  height: auto;
  margin-bottom: 3rem;
  width: 90%;
  padding-left: 4%;
  padding-right: 4%;
`;

const FormBodyWrapper = styled.div`
  button {
    align-self: center;
  }
`;

const CheckAndInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
`;

const CheckBoxWrapper = styled.div`
  width: 350px;
	margin-top: 1.3rem;
`;

const CustomKeraltyRadio = styled(KeraltyRadio)`
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    input[type=radio] {
      margin-left: -20px;
	  margin-top: 10px;
    }
  }
  input[type=radio] {
	margin-top: 20px;
  }
`;

const CustomKeraltyField = styled(KeraltyField)`
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    label {
      margin-left: -30px;
    }
  }
`;

const ChooseOption = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const {
	infoTwoFactor,
	saveInfoInTwoFactorStore,
	getAuthenticationCode,
	setIsLoading,
	isLoading,
	STATUS_CODES
} = useTwoFactorAuthentication();

  const { register, handleSubmit, watch,
		formState: { errors, isDirty }, reset
	} = useForm<UserContactMethods>({
		resolver: yupResolver(ContactInfoSchema),
		defaultValues: { },
	});

  const PhoneInput = ({ value }) => {
    const maskPhone = (phone = '9999999999') => {
      // 6 digits + special characters () -
      return `******${phone.substring(9, phone.length)}`
    }
    return (
      <CustomKeraltyField
        iconClass={'fas fa-mobile-alt'}
        title={t('imgNicons.mobileIcon')}
        value={maskPhone(value)}
        label={t('register.valCodeScreen.selectPhone')}
		isSelected={watch('contactMethod') === 'phone'}
        disabled />
    );
  };

  const EmailInput = ({ value }) => {
		let maskEmail = (email) => {
			if (!email) return '';

			email = email.split('@');

			let username = email[0];
			let domain = email[1];

			return username.substr(0, (username.length / 2)) + '*'.repeat(username.length / 2) + '@' + domain;
		};

		return (
			<CustomKeraltyField
				iconClass={'fas fa-envelope'}
				title={t('imgNicons.envelopeIcon')}
				value={maskEmail(value)}
				label={t('register.valCodeScreen.selectEmail')}
				isSelected={watch('contactMethod') === 'email'}
				disabled />
		);
};

  const onValidSubmit = useCallback(async (value: UserContactMethods) => {

	const body = {
		email: infoTwoFactor.email,
		phone: infoTwoFactor.phone,
		state: infoTwoFactor.state,
		isEnglish: i18n.language === 'en',
		isEmail: value.contactMethod === 'email',
		resend: false
	};

	setIsLoading(true)

	try{
		const response = await getAuthenticationCode(body);
		saveInfoInTwoFactorStore({ sendCodeBy: value.contactMethod, idTemp: response.idTemp, sendCodeAttemps: response.attemps });
		setIsLoading(false)
		history.replace(`/${MAIN.authentication}/${CURRENT.status}`);

	}catch(error: any){
		if( error.message === STATUS_CODES.ACCOUNT_BLOCKED ) {
			saveInfoInTwoFactorStore({
				sendCodeBy: value.contactMethod,
				idTemp: "",
				sendCodeAttemps: STATUS_CODES.ACCOUNT_BLOCKED
			});
			history.replace(`/${MAIN.authentication}/${CURRENT.status}`);
		};
		setIsLoading(false)
	}


  }, [reset]);

  useEffect(() => {
	saveInfoInTwoFactorStore({ currentStep: TwoFactorAuthenticationSteps.chooseOptions });
  }, [])


  return (
	<>
		{ isLoading && <Loader /> }
		<Container noPadding>
			<TitleWrapper>
				<KeraltyText type="title" bold={isMobile} align="left" fontSize="16px">
					{t('routes.loginAuthentication')}
				</KeraltyText>
				<KeraltyText type="subTitle" color="#5B5C5B" align="justify" fontSize="14px" style={{ lineHeight: '14px' }}>
					{t('authenticationTextOptions')}
				</KeraltyText>
			</TitleWrapper>
			<Container centered={'x'} noPadding>
				<FormBox autoComplete="off" onSubmit={handleSubmit(onValidSubmit)}>
					<FormBodyWrapper>
						<CheckAndInputWrapper>
							<CheckBoxWrapper>
								<CustomKeraltyRadio
									children={
										<PhoneInput value={infoTwoFactor.phone} />
									}
									field={register('contactMethod')}
									// onChange={handleRadioChange}
									checked={watch('contactMethod') === 'phone'}
									value={'phone'} />
							</CheckBoxWrapper>
						</CheckAndInputWrapper>

						<CheckAndInputWrapper>
							<CheckBoxWrapper>
								<CustomKeraltyRadio
									children={
										<EmailInput value={infoTwoFactor.email} />
									}
									field={register('contactMethod')}
									// onChange={handleRadioChange}
									checked={watch('contactMethod') === 'email'}
									value={'email'} />
							</CheckBoxWrapper>
						</CheckAndInputWrapper>

						{errors.contactMethod && errors.contactMethod.message && (
							<KeraltyError error={t(`errors.${errors.contactMethod.message}`)} />
						)}
						{/* <KeraltyError error={asyncError ? t(`errors.code${asyncError}`) : null} />
						{captchaError && <KeraltyError error={captchaError} />} */}

						{/* ACTIONS */}
						<KeraltyButton type={'submit'} full fontWeight="700" style={{ pointerEventes: isLoading ? 'none' : 'auto' }}>
							{t('register.next')}
						</KeraltyButton>
					</FormBodyWrapper>
				</FormBox>
			</Container>
		</Container>
	</>
  )
}

export default ChooseOption;
