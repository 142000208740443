import { useAppSelector } from 'adapter/hooks';
import { idEcw } from 'adapter/user/userSlice';
import { EcwRepository } from 'infrastructure/keraltyApi/repositories/ecw';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AppointmentsInfo } from '../../../../../domain/entities/appointmentsInfo';
import { KeraltyIcon, KeraltyText } from '../../../../components/atoms';
import { KeraltyFilterDate, KeraltyLongCard, PaginateItems } from '../../../../components/molecules';
import { Column, Container, Loader, Row, SpaceWrapper } from '../../../../components/templates';
import styled from '../../../../ui-core/styled-components';
import BREAKPOINTS from '../../../../ui-core/utils/breakpoints';
import moment from 'moment';


import { capitalize } from 'app/ui-core/utils/helpers/format';
import NoSearchResult from 'app/components/molecules/noSearchResult';
import EmptyPrevious from '../../../../assets/icons/EmptyPrevious.svg';
import NoResult from '../../../../assets/icons/noResult.svg';

import useIsMobile from '../../../../hooks/useIsMobile';

import useAppointment from 'app/hooks/useAppointment';
import useAuth from 'app/hooks/useAuth';
import theme from 'app/ui-core/utils/theme';
import { ModalErrorMyHealth } from 'app/components/molecules/modalError';

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 2vh;
    padding-inline: 10px;
  }
`;

const Heart = styled(Column)`
  justify-content: center;
  align-self: center;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    display: none !important;
  }
`;

const CardsContainer = styled(Column)`
  flex-direction: column;
  align-items: flex-start;
`;

const InfoColumn = styled(Column)`
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 14px;
`;

const InfoContainer = styled(Container)`
  width: 100%;
  margin-bottom: 2.5rem;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 0rem;
    margin-top: 0.5rem;
  }
`;

const IconSpace = styled(KeraltyIcon)`
  margin-right: 7px;
  &.toggle {
    @media (min-width: ${BREAKPOINTS.maxPhoneWidth}) {
      display: none;
    }
  }
`;

const TitleContiner = styled(Column)`
  padding: 0;
  width: 100%;
  h2 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`;

const IconSpaceContainer = styled(Column)`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 1rem;
  }
`;

const Label = styled(KeraltyText)`
  margin-right: 0.7rem;
  ${(props) => {
    const weight = props.bold ? 600 : 400;
    return `font-weight: ${weight}`;
  }};
`;

const KeraltyButtonFilterContainer = styled(Column)`
  margin-top: 0.7rem;
  justify-content: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100px;
  span {
    margin: 0 1rem;
  }

`;

const IconRow = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    flex-direction: column;
  }
`;

const ContentDiv = styled.div`

	background: #3CA70D;
	border-radius: 29px;
	height:30px;
	@media (max-width: 803px){
	background: #3CA70D;
	border-radius: 29px;
	height:25px;
	};
`;

interface IconLabelProps {
  color?: keyof (typeof theme.colors);
  iconClass?: string;
  iconKey?: any;
  labelKey: string;
  text?: string;
  textType?: boolean;
  isBold?: boolean;
  title?: boolean;
  toggleMobile?: boolean;
}

const IconWithLabel = ({
  color,
  iconClass,
  iconKey,
  labelKey,
  text,
  isBold,
  title,
  toggleMobile,
}: IconLabelProps) => {
  const { t } = useTranslation();
  const colorType = !color ? 'accent' : color;
  const isMobile = useIsMobile();

  return (
    <IconSpaceContainer md={'auto'} sm={12}>
      {iconClass && (
        <IconSpace
          className={toggleMobile ? 'toggle' : ''}
          iconClass={iconClass}
          color={colorType}
          size="common"
          title={t(iconKey)}
        />
      )}
      {!color ? (
        <Label type="label" bold={isBold}>
          {t(labelKey)}
          {!title && ':'}
        </Label>
      ) : (
        <Label type="label" bold={isBold} color={colorType}>
          {t(labelKey)}:
        </Label>
      )}

      {text && (
        <KeraltyText type={ isMobile ? "label" : "info"} color="dark" fontWeight="400">
          {text}
        </KeraltyText>
      )}

    </IconSpaceContainer>
  );
};

const PreviousBeWell = (props) => {
  const { titleFather } = props;
  const { t } = useTranslation();
  const { user } = useAuth();
  const patientId = useAppSelector(idEcw);

  const [previousAppointmentsData, setPreviousAppointments] = useState<AppointmentsInfo[]>([]);
  const [dataObject, setDataObject] = useState<{ from?: string; to?: string }>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [cleanInput, setCleanInput] = useState(false);
  const [visible, setVisible] = useState(false);
  const isMobile = useIsMobile();
  const [closeModalError, setCloseModalError] = useState(false);
  const [isGetError, setIsGetError] = useState(false);

  const [initPage, setInitPage] = useState(false);

  const { getNewDate } = useAppointment();

  const title = t('getCare.prevAppointments')

  useEffect(() => {
    if (patientId) {
      EcwRepository.getPreviousAppointmentsBeWell({ patientId }, user.state)
        .then((result) => {
          setPreviousAppointments(result);
          setIsGetError(false);
        })
        .catch((error) => {
          if(['400', '500', '503'].includes(error?.code?.toString())) {
            setCloseModalError(true);
            setIsGetError(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const onChangeDate = useCallback((type, data) => {
    setIsFiltered(true)
    type && setDataObject(data);
    type && setVisible(true);
    setIsModalVisible(false);
	setInitPage(true)
  }, []);

  const appointmentsToShow = useMemo(() => {
    return previousAppointmentsData.filter((appointment) => {
      if (!dataObject.to || !dataObject.from) return true;

      const currentDate = moment(appointment.date, 'YYYY-MM-DD');
      return currentDate.isBetween(moment(dataObject.from, 'MM/DD/YYYY'), moment(dataObject.to, 'MM/DD/YYYY'), undefined, '[]');
    }



    );
  }, [dataObject, previousAppointmentsData]);

  const ClearDate = () => {
    var p1 = { from: "", to: "" };
    var Init = moment().format();

    setDataObject(p1)
    setVisible(false)
    setIsFiltered(false)
  }

  const FilterVisible = () => {
    const format = (date) => { return date?.split('/').reverse().join('/').slice(2).split('/').reverse().join('/') }
    return (
      <>
        {
          <Row>
            <Row style={{ justifyContent: 'space-between' }} noMargin>
              <ContentDiv style={{ marginLeft: isMobile ? '0rem' : '2rem' }} onClick={() => { ClearDate(); setCleanInput(!cleanInput) }}>
                <div style={{ padding: 5, textAlign: 'left', marginLeft: '15px' }} >
                  <KeraltyText style={{ fontSize: 12, color: '#FFFFFF' }}>{format(dataObject.from)}</KeraltyText>
                  <KeraltyText style={{ fontSize: 12, color: '#FFFFFF' }}> - </KeraltyText>
                  <KeraltyText style={{ fontSize: 12, color: '#FFFFFF', padding: 5 }}>{format(dataObject.to)}</KeraltyText>
                  <KeraltyText style={{ fontSize: 14, paddingRight: 10, paddingLeft: 5, color: '#FFFFFF' }}>X</KeraltyText>
                </div>
              </ContentDiv>

              <KeraltyText style={{ fontSize: 16, color: '#999999', paddingTop: 5, marginRight: isMobile ? '0rem' : '5rem' }} >{appointmentsToShow.length} {t('myHealth.resul')}</KeraltyText>

            </Row>
          </Row>
        }
      </>
    )
  }

  return (
    <RightContent fullHeight>
      {closeModalError && <ModalErrorMyHealth setCloseModalError={()=>setCloseModalError(false)}/>}
      {isLoading && <Loader />}


      {title && (
        <Row style={{ margin: 0 }}>
          <TitleContiner lg={12}>
            <KeraltyText type="title" align={isMobile ? "left" : "center"} style={{ paddingLeft: isMobile ? 10 : '' }}>{title}</KeraltyText>
            {isMobile && previousAppointmentsData.length > 0 &&
              <KeraltyButtonFilterContainer onClick={() => setIsModalVisible(true)} style={{ display: 'flex', marginRight: 0 }}>
                <KeraltyIcon iconClass="fas fa-filter" color="accent" />
                <KeraltyText type="label" color={'accent'}>
                  {t('KeraltyFilter.title')}
                </KeraltyText>
              </KeraltyButtonFilterContainer>}
          </TitleContiner>

        </Row>
      )}
      {visible && isMobile ? <Row>
        <FilterVisible />
      </Row>

        : ''}


      <Row style={{display: 'grid', placeItems: 'center'}}>
		{
			<Row style={isMobile ? { paddingLeft: '4%', margin: 0 } : { maxWidth: '1000px', paddingLeft: '4%' }}>
			{
				previousAppointmentsData.length > 0  &&
				(
				<KeraltyFilterDate
					onSuccess={onChangeDate}
					onCancel={() => setIsModalVisible(false)}
					showModalFlag={isModalVisible}
					cleanInput={cleanInput}
				/>
				)
			}
			{visible && !isMobile ? <Row>
				<FilterVisible />
			</Row>
				: ''}
			</Row>
		}
        <CardsContainer lg={12} md={12} sm={12} style={{ maxWidth: '1000px' }}>
          {appointmentsToShow.length < 1 && !isGetError ? (!isFiltered ?
            <Container centered={'xy'}>
              <NoSearchResult image={EmptyPrevious} subTitle={t('myHealth.noPrevious')} />
            </Container> : <Container centered={'xy'}>
              <NoSearchResult image={NoResult} title={t('myHealth.titleEmpty')} subTitle={t('myHealth.textEmpty')} />
            </Container>
          ) : (
            <PaginateItems setToStartPage={initPage}>
              {appointmentsToShow.map((aptInfo) => {

                const customDate = getNewDate({ date: `${aptInfo.date}T${aptInfo.startTime}:00Z`, isNotUTCDate: true});

                return (
                  <KeraltyLongCard noPadding>
                    <Row noMargin fullHeight>
                      <Heart lg={1} md={1} sm={12} style={ isMobile ? {} : { paddingRight: 0 }}>
                        <KeraltyIcon
                          iconClass="fas fa-heart"
                          color="accent"
                          size="big"
                          title={t('imgNicons.heartIcon')}
                        />
                      </Heart>

                      <InfoColumn lg={11} md={11} sm={12} style={ isMobile ? {} : { paddingLeft: 0 }}>
                        <InfoContainer noPadding centered="y">
                          <IconRow>
                            <IconWithLabel
                              isBold={ isMobile }
                              iconClass="fas fa-heart"
                              iconKey="imgNicons.heartIcon"
                              labelKey={capitalize(aptInfo.visitType)}
                              title
                              toggleMobile
                            />
                            <IconWithLabel
                              color={'primary'}
                              iconClass="fas fa-calendar-day"
                              iconKey="imgNicons.calendarIcon"
                              labelKey={'getCare.date'}
                              // text={capitalize(moment(aptInfo.date + ' ' + aptInfo.startTime).format('MMMM DD YYYY'))}
                              text={customDate?.date ? moment(customDate.date).format('MMMM DD YYYY') : ''}
                              isBold
                            />
                            {isMobile && <IconWithLabel
                              color={'primary'}
                              iconClass={'fas fa-clock'}
                              iconKey={'imgNicons.clock'}
                              labelKey={'getCare.time'}
                              // text={moment(aptInfo.startTime, 'hh:mm:ss').format('hh:mm A')}
							  text={`${customDate?.time} (${ aptInfo.zonedTime })`}
                              isBold
                            />}
                            {!isMobile &&<IconWithLabel
                              color={'primary'}
                              iconClass="fas fa-user-md"
                              iconKey="imgNicons.chatDoctorIcon"
                              labelKey={'getCare.provider'}
                              text={
                                capitalize(aptInfo.ufName || '') +
                                ' ' +
                                capitalize(aptInfo.ulName || '')
                              }
                              isBold
                            />}
                          </IconRow>
                          <IconRow>
                            <IconWithLabel
                              color={'primary'}
                              iconClass="fas fa-map-marker-alt"
                              iconKey="imgNicons.locationIcon"
                              labelKey={'getCare.location'}
                              text={aptInfo.name}
                              isBold
                            />
                           {isMobile &&<IconWithLabel
                            color={'primary'}
                              iconClass="fas fa-user-md"
                              iconKey="imgNicons.chatDoctorIcon"
                              labelKey={'getCare.provider'}
                              text={
                                capitalize(aptInfo.ufName || '') +
                                ' ' +
                                capitalize(aptInfo.ulName || '')
                              }
                              isBold
                            />}
                          {!isMobile && <IconWithLabel
                          color={'primary'}
                              iconClass={'fas fa-clock'}
                              iconKey={'imgNicons.clock'}
                              labelKey={'getCare.time'}
                              // text={moment(aptInfo.startTime, 'hh:mm:ss').format('hh:mm A')}
                              text={`${customDate?.time} ` + (aptInfo.zonedTime ? `(${ aptInfo.zonedTime  })` : '')}
                              isBold
                            />}
                          </IconRow>
                          <SpaceWrapper mb={2}></SpaceWrapper>
                        </InfoContainer>
                      </InfoColumn>
                    </Row>
                  </KeraltyLongCard>
                );
              })}
            </PaginateItems>
          )}
        </CardsContainer>
      </Row>
    </RightContent>
  );
};

export default PreviousBeWell;
