import { useTranslation } from 'react-i18next';

import { Row } from '../../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../../atoms';

import Icon1 from '../../../../assets/icons/anxietyDisorder.svg';
import Icon2 from '../../../../assets/icons/anxietyDisorder2.svg';
import Icon3 from '../../../../assets/icons/anxietyDisorder3.svg';
import Icon4 from '../../../../assets/icons/anxietyDisorder4.svg';
import Icon5 from '../../../../assets/icons/anxietyDisorder5.svg';
import prevWhite from '../../../../assets/icons/prevWhite.svg';
import nextWhite from '../../../../assets/icons/nextWhite.svg';

import useIsMobile from '../../../../hooks/useIsMobile'
import { DataOnboarding, OnboardingProps } from '../MentalHealth.types';
import { useState } from 'react';



const contentBodyStl = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: 10,
	marginInline: 'auto'
}
const btnPevStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '14px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29
}
const btnNextStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '15px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29
}




const OnboardingStiking = (onboardingProps: OnboardingProps) => {
	const { start } = onboardingProps
	const isMobile = useIsMobile();
	const { t } = useTranslation();
	const [currentPage, setCurrentPage] = useState(0);

	const buttonContentStl = {
		display: 'flex',
		justifyContent:currentPage==4 && isMobile?'center':currentPage==4?'right': 'space-between',
		marginLeft: isMobile ? '0 auto' : '11%',
		marginRight: isMobile ? '0 auto' : '7%',
		marginTop: 20
	}
	const btnLetsStl = {
		height: isMobile ? 41 : '',
		marginBottom: isMobile ? '15px' : '',
		marginRight: isMobile ? 20 : '',
		marginTop: isMobile ? 5 : ''
	}


	const data: DataOnboarding[] = [
		{
			id: 0,
			subTitle: t('mentalHealth.stinkingSub'),
			text: t('mentalHealth.stinkingText1'),
			icon: Icon1
		},
		{
			id: 1,
			subTitle: t('mentalHealth.stinkingSub'),
			text: t('mentalHealth.stinkingText2'),
			icon: Icon2
		},
		{
			id: 2,
			subTitle: t('mentalHealth.stinkingSub'),
			text: t('mentalHealth.stinkingText3'),
			icon: Icon3
		},
		{
			id: 3,
			subTitle: t('mentalHealth.stinkingSub'),
			text: t('mentalHealth.stinkingText4'),
			icon: Icon4
		},
		{
			id: 4,
			subTitle: t('mentalHealth.stinkingSub'),
			text: t('mentalHealth.stinkingText5'),
			icon: Icon5
		}
	];



	const handlePrevious = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
	};

	const handleNext = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, data.length - 1));
	};
	const skipOnboarding = () => {
		setCurrentPage(4);
	};


	return (
		<Row>
			<div style={{ display: 'flex', flexDirection: 'row', marginTop:isMobile ? -20 : -35,marginLeft: isMobile ? '0 auto' : '11%', marginRight: isMobile ? '0 auto' : '7%', width: '100%' }}>
				<Row >
					<Row>
						<KeraltyText align={isMobile ? "left" : "center"} type="info"
							color="#000000"
							fontWeight='400'
							style={{ fontSize: 16 }}>
							{t('mentalHealth.omboardingText')}
						</KeraltyText>
					</Row>
					<Row style={contentBodyStl}>
						<Image
							height={isMobile ? 27 : 18}
							src={data[currentPage].icon}
							alt={t('imgNicons.myAccount')}
							style={{marginTop:isMobile ? 10 : 50}}
						/>
					</Row>

					<KeraltyText type="subTitle" align="left" bold color={'blueB98'}
						fontSize = {isMobile ? "18px" : "20px"}
						style={{ marginTop: isMobile ? 20 : 15 }}>{data[currentPage].subTitle}
					</KeraltyText>

					<KeraltyText align="left" type="info"
						color={'TEXTCOLORLIGHT'}
						fontWeight='400'
						style={{ fontSize: 16, marginTop:isMobile ? 28 : 20 }}>
						{data[currentPage].text}
					</KeraltyText>

				</Row>

			</div>
			<Row style={buttonContentStl} >
				{currentPage != 4 && <div style={{ paddingTop: isMobile ? 15 : 20 }}>
					<KeraltyText type="link" bold onClick={skipOnboarding}
						style={{ textTransform: isMobile ? '' : 'uppercase' }}
						color={isMobile ? 'blueAction' : 'accent'}
					>
						{t('mentalHealth.skip')}
					</KeraltyText>
				</div>
				}
				<div style={{}}>
					{(currentPage > 0 && currentPage < 4) &&
						<KeraltyButton
							disabled={currentPage === 0}
							onClick={handlePrevious}
							style={btnPevStl}
							children={<Image
								src={prevWhite}
								size={1.5}
								alt={t('imgNicons.myAccount')}
							/>}

						/>}

					{currentPage < 4 &&
						<KeraltyButton
							disabled={currentPage === data.length - 1}
							onClick={handleNext}
							style={btnNextStl}
							children={<Image
								src={nextWhite}
								size={1.4}
								alt={t('imgNicons.myAccount')}
							/>}
						/>}
					{currentPage == 4 &&
						<KeraltyButton onClick={start}
							style={btnLetsStl}
							fontWeight={ '600' }
						>
							{t('mentalHealth.start')}
						</KeraltyButton>}
				</div>



			</Row>

		</Row>
	);
};

export default OnboardingStiking;
