import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Row, Column, IconLinkRow, Card, CenteredWrapper, SpaceWrapper, Container } from '../../templates';
import { KeraltyButton, KeraltyText, Image, KeraltyIcon } from '../../atoms';
import { KeraltyModal, SectionWidget } from 'app/components/molecules';

import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';

import calendarIcon from '../../../assets/icons/calendar-duotone.svg';
import aboutIcon from '../../../assets/icons/mh-about.svg';
import educationalIcon from '../../../assets/icons/mh-educational.svg';
import helpIcon from '../../../assets/icons/mh-help.svg';
import toolsIcon from '../../../assets/icons/mh-tools.svg';

import ROUTES from '../../../ui-core/utils/routes';

import useIsMobile from '../../../hooks/useIsMobile';

import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';

import { useMediaQuery } from '@mui/material';
import ModalOnboarding from './ModalOnboarding';
import UsersRepository from 'infrastructure/keraltyApi/repositories/users';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import theme from 'app/ui-core/utils/theme';
import { SESSION_VARIABLE } from 'app/ui-core/utils/sessionStorage';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';

const { MAIN, MENTAL_HEALTH: CURRENT } = ROUTES;

// const sizeIconBackButton: number = 1;
const sizesUserChatIcon: IconSizes = { width: 3.58, height: 2.88 };

interface IconSizes {
  width: number;
  height: number;
}

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 5vh;
    padding-inline: 10px;
  }
`;
const styleIcon = {
  margin: 20,
  flex: 1,
  align: "center",
}

const TitleContiner = styled(Column)`
  padding: 0;
  width: 100%;
  h2 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`;

const LeftColumn = styled(Column)`
  justify-content: flex-end;
  padding: 0rem;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const RightColumn = styled(Column)`
  justify-content: flex-start;
  padding: 0rem;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;
const MidleColumn = styled(Column)`
  justify-content: center;
  padding: 0rem;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  height: 13.75rem;
  width: 308px;
  margin: 1.5rem 1.455rem;
  border-radius: 10px;
  padding: 2.34rem 0.6rem !important;
  display: flex;
  align-items: flex-start;
  padding-top: 32px !important;

  h2 {
    margin: 0rem;
    font-size: 1.34rem;
    line-height: 1.34rem;
  }
  p {
    margin: 0rem;
    font-size: 1rem;
    line-height: 1.17rem;
    color: gray;
    text-transform: none;
    font-weight: 400;
  }

  @media screen and (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: 709px) {
	height: 16.8rem;
  }
`;

const MentalMainPage = () => {
  const { t } = useTranslation();
  const { goBack, push } = useHistory();
  const isMobile = useIsMobile();
  const [showOnboarding, setShowOnboarding] = useState(false)
  const { user } = useAuth();
  const state=user.state
  const email=user.email
  const [showDisclaimer, setshowDisclaimer] = useState(true)
  
  const statusDisclaimer = KeraltySWS?.swInstance?.getItem(SESSION_VARIABLE.HIDDEN_DISCLAIMER) === 'true';

  const ChilderPopup = () => {
    return (
      <Row>
        <Row noMargin style={{ paddingRight: 20, paddingLeft: 10, justifyContent: 'center' }}>
          <div>
            <KeraltyIcon
              iconClass="fas	fa-exclamation"
              color="orange"
              size="medium"
              style={styleIcon}
              rounded
            />
          </div>
          <SpaceWrapper mb={1}>
            <Container centered={'x'} noPadding noMargin style={{ paddingRigt: 25 }}>
              <KeraltyText
                type={'label'}
                align={'center'}
                style={{ fontSize: isMobile ? '20px' : '24px', paddingBottom: 25 }}
              >
                {t('mentalHealth.disclaimerTitle')}
              </KeraltyText>
              <KeraltyText
                type={'label'}
                align={'center'}
                fontWeight={'400'}
                style={{
                  fontSize: isMobile ? '16px' : '20px',
                  lineHeight: isMobile ? '18px' : '21.5px',
                }}
              >
                {t('mentalHealth.disclaimer')}
              </KeraltyText>
            </Container>
          </SpaceWrapper>
        </Row>
        <Container centered={'x'} noPadding noMargin style={{ paddingRigt: 25 }}>
          <KeraltyText
            bold
            type={'link'}
            align="center"
            color={isMobile ? "blueAction" : "#3CA70D"}
            style={{
              textTransform: 'uppercase',
              paddingTop: 25,
              paddingBottom: 10,
              fontSize: isMobile ? '18px' : '16px',
              fontWeight: 700
            }}
            onClick={() => {
              KeraltySWS?.swInstance?.setItem(SESSION_VARIABLE.HIDDEN_DISCLAIMER, 'true');
              setshowDisclaimer(false)
            }}
          >
            {t('mentalHealth.close')}
          </KeraltyText>
        </Container>
      </Row>
    );
  }

  const handleAbout = () => {
     push(`/${MAIN.mentalHealth}/${CURRENT.about}`);
  };
  const handleAppoinments = () => {
    push(`/${MAIN.mentalHealth}/${CURRENT.appointments}`);
  };
  const handleEducationalResources = () => {
    push(`/${MAIN.mentalHealth}/${CURRENT.edures}`);
  };
  const handleNeedHelp = () => {
    push(`/${MAIN.mentalHealth}/${CURRENT.help}`);
  };
  const handleSelfManagement = () => {
    push(`/${MAIN.mentalHealth}/${CURRENT.selfmanagement}`);
  };

  const isScreenMobile = useMediaQuery('(max-width:600px)');
  const getOnboardStatus = async () => {
    return await UsersRepository.isOnboardingValidation(email, state)
      .then((result) => {
        setShowOnboarding(!result)
      });

  }

  const updateOnboardStatus = async () => {
    return await UsersRepository.updateOnboardingStatus(email, state, true);
  }


  useEffect(() => {
    getOnboardStatus()
  }, [])


  return (
    <RightContent fullHeight>
      {showDisclaimer && !statusDisclaimer && <KeraltyModal style={{width:'699px'}}  ><ChilderPopup/></KeraltyModal>}
		  {showOnboarding && !showDisclaimer &&
					<ModalOnboarding
						start={()=>{updateOnboardStatus();setShowOnboarding (false)}}
						skip={()=>{updateOnboardStatus();setShowOnboarding (false)}}
					/>
				}
      <IconLinkRow
        onClick={goBack}
        color="accent"
        icon={arrowAltCircleLeftIcon}
        iconAlt={t('imgNicons.backArrow')}
        text={t('routes.back')}
      />
      <Row>
        <TitleContiner lg={12}>
          <KeraltyText type="title" style={{ textTransform: 'capitalize' }}>{t('mentalHealth.main')}</KeraltyText>
        </TitleContiner>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            width: isMobile ? '100%' : '',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: isScreenMobile ? 'column' : 'initial',
          }}
        >
          <Column lg={0} md={0} sm={12}>
            <CenteredWrapper>
              <Card onClick={handleAbout}>
                <SectionWidget
                  img={aboutIcon}
                  text={capitalizeJustFirst(t('mentalHealth.about'))}
                  description={capitalizeJustFirst(t('mentalHealth.aboutText'))}
                  alt={t('imgNicons.mhAboutIcon')}
                />
              </Card>
            </CenteredWrapper>
          </Column>
          <LeftColumn lg={4} md={4} sm={0} style={{ display: 'flex' }}>
            <GetCareNowButton color="light" squareRadius={true} onClick={handleAbout}>
              <span>
                <Image
                  style={{ marginBottom: '10px' }}
                  src={aboutIcon}
                  width={sizesUserChatIcon.width}
                  height={sizesUserChatIcon.height}
                  alt={t('imgNicons.mhAboutIcon')}
                />
                <KeraltyText
                  type="title"
                  fontWeight={'400'}
                  style={{ paddingBottom: 10, fontWeight: 'normal' }}
                >
                  {t('mentalHealth.about')}
                </KeraltyText>
                <KeraltyText type="paragraph2" fontWeight={'600'}>
                  {t('mentalHealth.aboutText')}
                </KeraltyText>
              </span>
            </GetCareNowButton>
          </LeftColumn>

          <Column lg={0} md={0} sm={12}>
            <CenteredWrapper>
              <Card onClick={handleAppoinments}>
                <SectionWidget
                  img={calendarIcon}
                  text={capitalizeJustFirst(t('mentalHealth.appointments'))}
                  description={capitalizeJustFirst(t('mentalHealth.appointmentsText'))}
                  alt={t('imgNicons.mhAppointmentsIcon')}
                />
              </Card>
            </CenteredWrapper>
          </Column>
          <MidleColumn lg={4} md={4} sm={0} style={{ display: 'flex' }}>
            <GetCareNowButton color="light" squareRadius={true} onClick={handleAppoinments} style={{ display: 'flex', alignItems: 'baseline', paddingTop: '32px !important' }}>
              <span>
                <Image
                  style={{ marginBottom: '10px' }}
                  src={calendarIcon}
                  width={sizesUserChatIcon.width}
                  height={sizesUserChatIcon.height}
                  alt={t('imgNicons.mhAppointmentsIcon')}
                />
                <KeraltyText
                  type="title"
                  fontWeight={'400'}
                  style={{ paddingBottom: 10, fontWeight: 'normal' }}
                >
                  {t('mentalHealth.appointments')}
                </KeraltyText>
                <KeraltyText type="paragraph2" fontWeight={'600'}>
                  {t('mentalHealth.appointmentsText')}
                </KeraltyText>
              </span>
            </GetCareNowButton>
          </MidleColumn>

          <Column lg={0} md={0} sm={12}>
            <CenteredWrapper>
              <Card onClick={() => handleEducationalResources()}>
                <SectionWidget
                  img={educationalIcon}
                  text={t('mentalHealth.educational')}
                  description={capitalizeJustFirst(t('mentalHealth.educationalText'))}
                  alt={t('imgNicons.mhEducationalIcon')}
                />
              </Card>
            </CenteredWrapper>
          </Column>
          <RightColumn lg={4} md={4} sm={0} style={{ display: 'flex' }}>
            <GetCareNowButton color="light" squareRadius={true} onClick={handleEducationalResources}>
              <span>
                <Image
                  style={{ marginBottom: '10px' }}
                  src={educationalIcon}
                  width={sizesUserChatIcon.width}
                  height={sizesUserChatIcon.height}
                  alt={t('imgNicons.mhEducationalIcon')}
                />
                <KeraltyText
                  type="title"
                  fontWeight={'400'}
                  style={{ paddingBottom: 10, fontWeight: 'normal' }}
                  noTextTransform
                >
                  {t('mentalHealth.educational')}
                </KeraltyText>
                <KeraltyText type="paragraph2" fontWeight={'600'}>
                  {t('mentalHealth.educationalText')}
                </KeraltyText>
              </span>
            </GetCareNowButton>
          </RightColumn>
        </div>
      </Row>

      <Row style={{ justifyContent: 'center', marginTop: '-10px', marginBottom: '30px' }}>
        <Column lg={0} md={0} sm={12}>
          <CenteredWrapper>
            <Card onClick={handleNeedHelp}>
              <SectionWidget
                img={helpIcon}
                text={t('mentalHealth.needHelp')}
                description={capitalizeJustFirst(t('mentalHealth.needHelpText'))}
                alt={t('imgNicons.mhNeedHelpIcon')}
              />
            </Card>
          </CenteredWrapper>
        </Column>
        <LeftColumn lg={4} md={4} sm={0}>
          <GetCareNowButton color="light" squareRadius={true} onClick={handleNeedHelp}>
            <span>
              <Image
                style={{ marginBottom: '10px' }}
                src={helpIcon}
                width={sizesUserChatIcon.width}
                height={sizesUserChatIcon.height}
                alt={t('imgNicons.mhNeedHelpIcon')}
              />
              <KeraltyText
                type="title"
                fontWeight={'400'}
                style={{ paddingBottom: 10, fontWeight: 'normal' }}
              >
                {t('mentalHealth.needHelp')}
              </KeraltyText>
              <KeraltyText type="paragraph2" fontWeight={'600'}>
                {t('mentalHealth.needHelpText')}
              </KeraltyText>
            </span>
          </GetCareNowButton>
        </LeftColumn>

        <Column lg={0} md={0} sm={12}>
          <CenteredWrapper>
            <Card onClick={() => handleSelfManagement()}>
              <SectionWidget
                img={toolsIcon}
                text={capitalizeJustFirst(t('mentalHealth.tools'))}
                description={capitalizeJustFirst(t('mentalHealth.toolsText'))}
                alt={t('imgNicons.mhToolsIcon')}
              />
            </Card>
          </CenteredWrapper>
        </Column>
        <RightColumn lg={4} md={4} sm={0} noPadding>
          <GetCareNowButton color="light" squareRadius={true} onClick={handleSelfManagement}>
            <span>
              <Image
                style={{ marginBottom: '10px' }}
                src={toolsIcon}
                width={sizesUserChatIcon.width}
                height={sizesUserChatIcon.height}
                alt={t('imgNicons.mhToolsIcon')}
              />
              <KeraltyText
                type="title"
                fontWeight={'400'}
                style={{ paddingBottom: 10, fontWeight: 'normal' }}
              >
                {t('mentalHealth.tools')}
              </KeraltyText>
              <KeraltyText type="paragraph2" fontWeight={'600'}>
                {t('mentalHealth.toolsText')}
              </KeraltyText>
            </span>
          </GetCareNowButton>
        </RightColumn>
      </Row>
    </RightContent>
  );
};

export default MentalMainPage;
