import { lazy, LazyExoticComponent, Suspense, useMemo } from 'react';
import { Loader } from '../../templates';

const RenderStep = ({ currentStep = 0, prev, next, ...props }) => {
  let Step: LazyExoticComponent<(props: any) => JSX.Element>;

  const steps: LazyExoticComponent<(props: any) => JSX.Element>[] = useMemo(() => {
    return [
      lazy(() => import('./RecoverPasswordForm')),
      lazy(() => import('./PasswordContactForm')),
      lazy(() => import('./VerifyOptionFirst')),
      lazy(() => import('./VerifyTwo')),
      lazy(() => import('./VerifyOptionLast')),
      lazy(() => import('./VerifyOptionLastError')),
      lazy(() => import('./VerifyOptionCodeTimer')),
      lazy(() => import('./VerifyOptionExpiredCode')),
      lazy(() => import('./VerifiedCode')),
      lazy(() => import('./NewPasswordForm')),
      lazy(() => import('./SuccessfulPassChange')),
    ];
  }, []);

  Step = steps[currentStep];

  return (
    <Suspense fallback={<Loader />}>
      <Step prev={prev} next={next} {...props} />
    </Suspense>
  );
};

export default RenderStep;
