import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
// 3RD PARTY
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from '../../../../ui-core/styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
// UI
import { SpaceWrapper, Container, FormBox, Row, Column, Loader } from '../../../templates';
import { KeraltyField, KeraltyModal, KeraltyDotChatTrigger } from '../../../molecules';
import { KeraltyButton, KeraltyText, KeraltyError, KeraltyDatePicker, KeraltyIcon } from '../../../atoms';
import { ReactComponent as SupportChatIcon2 } from '../../../../assets/icons/newChat.svg';
import { ReactComponent as SupportChatIcon } from '../../../../assets/icons/chat 2.svg';
// LOGIC
import appConfig from '../../../../../config';
import BREAKPOINTS from '../../../../ui-core/utils/breakpoints';
import FORMATS from 'app/ui-core/utils/formats';
import useCaptcha from '../../../../hooks/useCaptcha';
import useAuth from '../../../../hooks/useAuth';
// ADAPTER
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { closeSymptomChecker } from '../../../../../adapter/chat/chatSlice';
import * as sanitasAdapterMethods from '../../../../../adapter/sanitasChat/sanitasChat';
// DOMAIN
import { UserAvailitySchema } from '../../../../ui-core/schemas/userAvaility';
import { UserAvailityForm, UserAvaility } from '../../../../../domain/entities/userAvaility';
import { DefaultAvailityInfo } from '../../../../../domain/valueObjects/defaultUserAvailityInfo';
import { userActions } from '../../../../../adapter/user/userSlice';
import { shutdown, show, setUp } from 'adapter/sanitasChat/sanitasChat';
import useIsMobile from '../../../../hooks/useIsMobile';
import { EcwRepository } from '../../../../../infrastructure/keraltyApi/repositories/ecw';
import { IValidateInsuranceToChatResponse } from 'domain/entities/chatWithDocInfo';
import { InsuranceCode } from '../../../../../domain/valueObjects/insurance';
import { validateInsurance } from 'adapter/payment/paymentSlice';
import { SERVICES_TYPES } from '../../../../../domain/valueObjects/payment';
import {ModalInsuranceInactive} from '../../../molecules/ModalInsuranceInactive';
import {ModalInsuranceNotValid} from '../../../molecules/ModalInsuranceNotValid';
import {ModalInsurancePCP} from '../../../molecules/ModalInsurancePCP';
import { utcTime } from 'app/ui-core/utils/times';



const linkToTelevisit: string = appConfig.TELADOC_URL;

const FormContainer = styled(Column)`
    padding: 0rem;
    justify-content: center;
`;

const FieldFormContainer = styled(Column)`
  flex-flow: column;
`;

const TextSubtitle = styled(Column)`
	color:#5B5C5B !important;
	margin-top: -20px;
	margin-bottom: -15px;
	text-align: center;
	font-size: 16px;
	line-height: 1.58rem;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color: #5b5c5b ;
		margin-bottom: 20px !important;
		padding-left:2px;
	}
`;
const styleIcon = {
	margin: 20,
	flex: 1,
	align: "center",
}
const FormBoxStyled = styled(FormBox)`
    max-width: 630px;
    max-height: 450px;
    @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
        max-width: 295px;
    }
`;

const initialChatStatus: IValidateInsuranceToChatResponse = { code: InsuranceCode.default_code, message: "" }


const ChatWithDocForm = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const [insurersData, setInsurersData] = useState<any>([])
	const { handleValidCaptchaSubmit, captchaError } = useCaptcha('televisitData');
	const { user } = useAuth();
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
	// STATE
	const [isLoading, setIsLoading] = useState(false);
	const [modalContent, setModalContent] = useState<string>('');
	const isMobile = useIsMobile();
	const userState = user.state;
	const [serverError, setserverError] = useState(false)

	const { token, authUid, state } = useAppSelector(state => state.user)
	const [currentChatStatus, setCurrentChatStatus] = useState<IValidateInsuranceToChatResponse>(initialChatStatus);

	const closeModalChatStatus = () => goBack();

	const dataInsurers = async () => {
		const result = await EcwRepository.loadInsurances();
		return result;
	};

	const {
		register, handleSubmit, setValue, watch,
		formState: { errors }, reset
	} = useForm<UserAvailityForm>({
		mode: 'onBlur',
		resolver: yupResolver(UserAvailitySchema),
		defaultValues: DefaultAvailityInfo
	});

	const companyOptions = useMemo(() => insurersData?.map(({
		id: value,
		name: label,
		...rest
	}) => ({
		value,
		label,
		...rest
	})), [insurersData]);

	const handleTalkToDoctor = async () => {
		dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
			dispatch(shutdown());
		}
		await setTimeout(async () => {
			await dispatch(setUp({
				actionColor: undefined,
				backgroundColor: '#055293',
				name: `${user.displayName}`,
				email: `${user.email}`,
				state: `${user.state}`,
				currentTimeZone: `${utcTime()}`,
				authUid: `${authUid}`,
				sanitasAccountNumber: `${user.idEcw}`,
				gender: `${user.sex}`,
				birthday:`${user.birthdate}`,
				customAttributes: { cType: 'provider' },
			}));
			await dispatch(show());
		}, 500);
	};

	const onValidSubmit = useCallback((value: UserAvaility) => {
		dispatch(validateInsurance({
			authUid,
			state: state.toUpperCase(),
			token,
			service: SERVICES_TYPES.CHAT_WITH_DOCTOR
		}))
		.unwrap()
		.then( (response: IValidateInsuranceToChatResponse) => {
			if( response.code === InsuranceCode.valid_insurance){
				const dataToSend = {
					...value,
					birthDate: moment(value.birthDate).format(FORMATS.date2),
					state: userState,
					companyId: value.companyId,
					insuranceName: value.insuranceName,
				};

				handleValidCaptchaSubmit(async () => {
					setIsLoading(true);
					let response: any;

					try {
						response = await dispatch(userActions.tryToVerifyTelevisitUser(dataToSend)).unwrap();

						if (response?.isActive) {
							handleTalkToDoctor()
							goBack();
						}
						setserverError(false)
						setModalContent(response?.isActive ? '' : 'errors.insuranceDisabled');
					} catch (error) {
						console.log(error)
						setserverError(true)
						setModalContent('')
					}


					setIsLoading(false);
				});
				reset({ ...value });
				return
			}
			setCurrentChatStatus(response)
		})

	}, [handleValidCaptchaSubmit, reset, dispatch]);

	const handleCloseModal = useCallback(() => {
		 goBack()
		setModalContent('');
	}, [modalContent, goBack]);

	const triggerPatientSupport = useCallback(async () => {
		if (isSCOpen) dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
			await dispatch(sanitasAdapterMethods.shutdown());
		}
		await setTimeout(async () => {
			await dispatch(sanitasAdapterMethods.setUp({
				actionColor: "#7cbc98",
				backgroundColor: "#7cbc98",
				name: `${user.displayName}`,
				email: `${user.email}`,
				state: `${user.state}`,
				currentTimeZone: `${utcTime()}`,
				authUid: `${authUid}`,
				sanitasAccountNumber: `${user.idEcw}`,
				gender: `${user.sex}`,
				birthday:`${user.birthdate}`,
				customAttributes: { cType: 'patientSupport' },
			}));
			await dispatch(sanitasAdapterMethods.show());
		}, 500);
		closeModalChatStatus();
	}, [dispatch, sanitasState, user, isSCOpen]);

	const handlerOnClick = async () => {
		triggerPatientSupport();
	};

	const getInsurerList = async () => {
		setInsurersData(await dataInsurers())
	}

	useEffect(() => {
		getInsurerList()
	}, [])

	return (
		<div>
			{isLoading && <Loader />}

			{modalContent &&
				<KeraltyModal size='long' textalign='center'>


						<KeraltyIcon style={{ marginTop:15}}  iconClass="fas fa-exclamation" color={"orange"} size="medium" title={t('imgNicons.paperPlaneIcon')} rounded />

					<Row style={{marginBottom:25, marginTop:25}}>

							<KeraltyText fontWeight='400' type={'label'} >{t(modalContent)}</KeraltyText>

					</Row>
					<Row noMargin>
						<Container centered={'x'} noPadding>
							<KeraltyButton bgcolor={(window.innerWidth < 1023) ?'light':'accent'} noBorders color='accent' style={{color:(window.innerWidth < 1023) ?'#3CA70D':'#fff'}}
							 onClick={() => {
								handleCloseModal();
							}}>
								{t('button.accept')}
							</KeraltyButton>
						</Container>
					</Row>
				</KeraltyModal>
			}
			{/* Chat with a Doctor modals */}

			{
				currentChatStatus.code === InsuranceCode.modal_insurance_not_validated && (
					<ModalInsuranceNotValid
						close={closeModalChatStatus}
						triggerPatientSupport={triggerPatientSupport}
					/>
				)
			}
			{
				currentChatStatus.code === InsuranceCode.modal_insurance_inactived && (
					<ModalInsuranceInactive
						close={closeModalChatStatus}
						triggerPatientSupport={triggerPatientSupport}
					/>
				)
			}
			{
				currentChatStatus.code === InsuranceCode.modal_pcp_no_validated && (
					<ModalInsurancePCP
						close={closeModalChatStatus}
						triggerPatientSupport={triggerPatientSupport}
					/>
				)
			}

			<FormContainer style={{ marginTop: '5%' }}>
				<FormBoxStyled autoComplete={'off'} onSubmit={handleSubmit(onValidSubmit)} width={'auto'}>
					<Row>
						<FieldFormContainer lg={6} md={6} sm={12}>
							{/* FIELDS */}
							<KeraltyField
								iconClass={'fas fa-user'}
								title={t('imgNicons.userIcon')}
								label={t('getCare.televisit.televisitForm.firstName')}
								placeholder={t('getCare.televisit.televisitForm.firstName')}
								field={register('firstName')}
								infotooltip={t('tooltip.enterFirstName')}
								error={errors.firstName} />
						</FieldFormContainer>
						<FieldFormContainer lg={6} md={6} sm={12}>
							<KeraltyField
								iconClass={'fas fa-user'}
								title={t('imgNicons.userIcon')}
								label={t('getCare.televisit.televisitForm.lastName')}
								placeholder={t('getCare.televisit.televisitForm.lastName')}
								field={register('lastName')}
								infotooltip={t('tooltip.enterLastName')}
								error={errors.lastName} />
						</FieldFormContainer>

						<FieldFormContainer lg={6} md={6} sm={12}>
							<KeraltyField
								iconClass={'fas fa-calendar-alt'}
								title={t('imgNicons.calendarIcon')}
								placeholderText={t('getCare.televisit.televisitForm.birthdate')}
								label={t('getCare.televisit.televisitForm.birthdate')}
								control={KeraltyDatePicker}
								bg={false}
								onChange={(v) =>
									setValue('birthDate', v || '', { shouldValidate: true, shouldTouch: true })
								}
								selected={watch('birthDate')}
								customInput={<input {...register('birthDate')} />}
								infotooltip={t('tooltip.enterBirthDate')}
								error={errors.birthDate} />
						</FieldFormContainer>



						<FieldFormContainer lg={6} md={6} sm={12}>
							<KeraltyField
								iconClass="fas fa-building"
								title={t('imgNicons.mapMarkedIcon')}
								placeholder={t('formLabel.insuranceCompany')}
								label={t('formLabel.insuranceCompany')}
								type={'select'}
								control={Select}
								options={companyOptions}
								onChange={(v) => {
									setValue('insuranceName', v.label, { shouldValidate: true })
									setValue('companyId', v.value, { shouldValidate: true })
								}}
								value={companyOptions?.find(({ value }) => Number(value) === Number(watch('companyId')))}
								error={errors.insuranceName}
								infotooltip={t('tooltip.insuranceCardInfo')}
								mandatory
							/>
						</FieldFormContainer>

						<FieldFormContainer lg={6} md={6} sm={12}>
							<KeraltyField
								iconClass={'fas fa-id-card'}
								title={t('getCare.televisit.televisitForm.memberId')}
								label={t('getCare.televisit.televisitForm.memberId')}
								placeholder={t('getCare.televisit.televisitForm.memberId')}
								field={register('memberId')}
								infotooltip={t('tooltip.insuranceCardInfo')}
								error={errors.memberId} />
						</FieldFormContainer>
					</Row>

					{/* ERRORS */}
					{captchaError && <KeraltyError error={captchaError} />}

					{/* ACTIONS */}
					<Row>
					{isMobile ?
							<Container centered={'x'}>
							<Column txtCenter>
								<KeraltyButton type={'submit'} full smFull style={{marginTop:0}}>
									{t('button.next')}

								</KeraltyButton>
								{serverError && <div style={{paddingBottom:30}}><KeraltyError error={t('errors.insuranceDisabledError')} color='#B50303' fontWeight={600} /></div> }
								<div style={{marginTop:0, marginBottom:serverError ? 10:30}}></div>

								<TextSubtitle >{t('getCare.televisit.televisitForm.memberFieldMessage')}</TextSubtitle>
								<KeraltyDotChatTrigger
									color={'primary'}
									colordot={'primary'}
									colorlabel={'primary'}
									label={t('button.supportChat')}
									position={isMobile}
									right
									svg={ SupportChatIcon2}
									onClick={handlerOnClick} />

							</Column>
						</Container>
						:
						<Container centered={'x'}>
							<Column txtCenter>
								<TextSubtitle>{t('getCare.televisit.televisitForm.memberFieldMessage')}</TextSubtitle>
								<KeraltyDotChatTrigger
									color={'primary'}
									colordot={'primary'}
									colorlabel={'primary'}
									label={t('button.supportChat')}
									position={isMobile}
									right
									svg={SupportChatIcon}
									onClick={handlerOnClick} />
								<KeraltyButton type={'submit'} full smFull>
									{t('button.next')}
								</KeraltyButton>
								{serverError && <div style={{paddingBottom:30}}><KeraltyError error={t('errors.insuranceDisabledError')} color='#B50303' fontWeight={600} /></div> }
							</Column>
						</Container>

					}
					</Row>
				</FormBoxStyled>
			</FormContainer>
		</div>
	)
}

export default ChatWithDocForm;
