import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import appConfig from 'config';
import { Row, Column, IconLinkRow, Card, CenteredWrapper } from '../../../components/templates';
import { KeraltyButton, KeraltyText, Image } from '../../../components/atoms';
import { SectionWidget } from 'app/components/molecules';
import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import userChatIcon from '../../../assets/icons/user-md-chat.svg';
import laptopMedical from '../../../assets/icons/laptop-medical.svg';
import userCallIcon from '../../../assets/icons/user-md-call.svg';
import TelephoneIcon from '../../../assets/icons/telephone.svg';

import ROUTES from '../../../ui-core/utils/routes';

import {
	closeSymptomChecker,
	openSymptomChecker,
	showChat,
} from 'adapter/chat/chatSlice';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useAuth from '../../../hooks/useAuth';
import { shutdown, } from 'adapter/sanitasChat/sanitasChat';

import i18n from "i18next";
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import { userSelectors } from 'adapter/user/userSelectors';
import useIsMobile from 'app/hooks/useIsMobile';
import { disableToTN, ScheduleCode } from 'app/ui-core/utils/disableOptions';
import AuthRepository from 'infrastructure/keraltyApi/repositories/auth'
import { useEffect, useState, useCallback } from 'react';

import ModalTelevisitPay from 'app/components/organisms/getCare/televisitPayment/modals/ModalTelevisitPay';
import ModalInsuranceNoValidated from 'app/components/organisms/getCare/televisitPayment/modals/ModalInsuranceNoValidated';
import ModalInsuranceInactived from 'app/components/organisms/getCare/televisitPayment/modals/ModalInsuranceInactived';
import ModalPCPNoSanitas from 'app/components/organisms/getCare/televisitPayment/modals/ModalPCPNoSanitas';
import ModalPreviousTransaction from 'app/components/organisms/getCare/televisitPayment/modals/ModalPreviousTransaction';
import ModalTransactionProcess from 'app/components/organisms/getCare/televisitPayment/modals/ModalTransactionProcess';
import ModalNoNewPayment from 'app/components/organisms/getCare/televisitPayment/modals/ModalNoNewPayment';
import { IInsurancePaymentResponse, ISessionInformation, OtherValue } from 'domain/entities/paymentInfo';
import { initPayment, createNewTransactionBase, checkTransactionStatus, cancelUserTransaction } from 'adapter/payment/paymentSlice';
import { SERVICES_TYPES, PaymentCode, SESSION_STATUS, defaultValue, VARIABLE_TYPES, EXPIRATION_TIMES } from '../../../../domain/valueObjects/payment';
import { selectPaymentLoading } from 'adapter/payment/paymentSelectors';
import Loader from '../../../components/templates/loader';
import useChatProvider from 'app/hooks/useChatProvider';
import ModalInsuranceValidate from 'app/components/molecules/ModalInsuranceValidate';
import ModalNoCopayment from './televisitPayment/modals/ModalNoCopayment';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { openSafeUrl } from 'app/ui-core/utils/url';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import useGetCareNow from 'app/hooks/useGetCareNow';
import useBrowser from 'app/hooks/useBrowser';

const { MAIN, GET_CARE } = ROUTES;

// const sizeIconBackButton: number = 1;
const sizesUserChatIcon: IconSizes = { width: 3.58, height: 2.88 };
const sizesTermometerGreenIcon: IconSizes = { width: 2.88, height: 2.88 };
const sizesLaptopMedicalIcon: IconSizes = { width: 2.8, height: 2.24 };

const linkToTelevisit: string = appConfig.TELADOC_URL;


interface IconSizes {
	width: number;
	height: number;
}

interface GetCareNowOptions {
	name: "chat_with_professional" | "videocall_with_professional" | "call_with_professional",
	card: {
		web: JSX.Element,
		mobile: JSX.Element,
	},
	enabled: boolean,
}

const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 5vh;
		padding-inline: 10px;
	}
`;


const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
	}
`;

const LeftColumn = styled(Column)`
	justify-content: flex-end;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const RightColumn = styled(Column)`
	justify-content: flex-start;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;
const CenterColumn = styled(Row)`
    align-items: flex-end;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  min-height: 12.75rem;
  height: auto;
	width: 22vw;
	margin: 1.5rem 1.455rem;
	border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;

	h2 {
		margin: 0rem;
		font-size: 1.34rem;
		line-height: 1.34rem;
	}
	p {
		margin: 0rem;
		font-size: 1rem;
		line-height: 1.17rem;
		color:gray;
		text-transform: none;
		font-weight: 400;

	}

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	}
`;

const DisabledGetCareNowOptionContent = styled.div<{ backgroundImage: string }>`
	width: 280px;
	min-height: 171px;
	height: auto;
	background: rgba(255, 255, 255, 0.75);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	background-image: url(${({ backgroundImage }) => backgroundImage});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 40%;
	margin-top: 0rem;
	margin-bottom: 2.5rem;
	margin-left: 1.46rem;
	margin-right: 2.5rem;
	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 2rem;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
`;

const BlurContent = styled.div`
	width: 100%;
	min-height: 171px;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 5px;
	backdrop-filter: blur(2.5px);
	padding-top: 10px;
`;

const NoteContent = styled.div<{ language: string }>`
	width: ${({ language }) => language === 'es' ? '75%' : '50%'};
	height: 26px;
	background: #0269A7;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	border-radius: 0px 30px 28px 0px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 1px;
	color: #FFFFFF;
	justify-content: center;
`;

const Note = styled.span<{ isSafari: boolean }>`
	letter-spacing: 1px;
	margin-left: 10px;
	font-size:  ${({ isSafari }) => isSafari ? '10px' : '12px'};
`;

const DisabledGetCareNowOptionContentTN = styled.div`
	width: 288.43px;
	min-height: 171px;
	height: auto;
	background: rgba(255, 255, 255, 0.75);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 40%;
	margin-top: 0rem;
	margin-bottom: 2.5rem;
	margin-left: 2.5rem;
	margin-right: 1.46rem;
	margin-top:10px;
	display: flex;
    flex-direction: column;
    align-items: center;
	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 2rem;
		margin-left: 0;
		margin-right: 0;
	}

`;

const NoteContentTN = styled.div`
	width: 100%;
	min-height: 26px;
	background: #0269A7;
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
	border-radius: 10px 10px 0px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
`;

const NoteTN = styled.span<{ isSafari: boolean }>`
	font-style: normal;
	font-weight: 600;
	font-size: ${({ isSafari }) => isSafari ? '9px' : '11px'};
	line-height: 16px;
	text-transform: uppercase;
	@media screen and (min-width: 599px) and (max-width: 693px) {
		font-size: ${({ isSafari }) => isSafari ? '8px' : '9px'};
	}
`;

const ImgTN = styled.img`
	width: ${sizesUserChatIcon.width}rem;
	height: ${sizesUserChatIcon.height}rem;
	margin-top: 27.32px;
`;

const CardTN = styled(Card)`
	padding: 0px !important;
	cursor: none !important;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const SectionWidgetTN = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
`;


const DisabledGetCareNowOption = ({ title, subtitle, backgroundImage }) => {

	const { t } = useTranslation();
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const isMobile = useIsMobile();
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	return (
		<DisabledGetCareNowOptionContent backgroundImage={backgroundImage}>
			<BlurContent>
				<NoteContent language={language}>
					<Note isSafari={isSafari}>
						{t('button.comingSoon')}
					</Note>
				</NoteContent>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<KeraltyText
						type="title"
						fontWeight={'700'}
						color='#3F3F3F'
						fontSize='20px'
						style={{ lineHeight: '25px', marginBottom: 2, width: language === 'es' ? '100%' : isMobile ? '25rem' : '20rem' }}
					>
						{title}
					</KeraltyText>
				</div>

				<KeraltyText
					type="title"
					fontWeight={'700'}
					color='#414141'
					fontSize='14px'
					style={{ lineHeight: '17px', marginBottom: 0 }}
				>
					{t('button.beAble')}
				</KeraltyText>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

					<KeraltyText
						type="title"
						fontWeight={'400'}
						color='#414141'
						fontSize='14px'
						style={{ lineHeight: '17px', marginTop: 2, width: language === 'es' ? '100%' : isMobile ? '23rem' : '19rem' }}
					>
						{subtitle}
					</KeraltyText>
				</div>
			</BlurContent>
		</DisabledGetCareNowOptionContent>
	)
}

// Time zone card
const DisabledGetCareSchedule = ({ title, subtitle, Image }) => {

	const { t } = useTranslation();
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	return (
		<DisabledGetCareNowOptionContentTN >
			<NoteContentTN>
				<NoteTN isSafari={isSafari}>
					{t('button.schedule')}
				</NoteTN>
			</NoteContentTN>
			<ImgTN
				src={Image}
			/>

			<KeraltyText
				type="title"
				fontWeight={'600'}
				color='#002D57'
				fontSize='18px'
				style={{ lineHeight: '16px' }}
			>
				{title}
			</KeraltyText>
			<KeraltyText
				type="title"
				fontWeight={'400'}
				color='#747474'
				fontSize='12px'
				style={{ lineHeight: '14px', marginTop: -2, width: language === 'es' ? '21rem' : '16rem' }}
			>
				{subtitle}
			</KeraltyText>
		</DisabledGetCareNowOptionContentTN>
	)
}


const GetCareNowPage = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { goBack, push, replace } = useHistory();
	const { user } = useAuth();
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const chatState = useAppSelector((state) => state.chat.symptomChecker);
	const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
	const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
	const isChatOpen = chatState.isOpen;
	const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
	const isMobile = useIsMobile();
	const [isHint, setIsHint] = useState(false)
	const [sessionId, setSessionId] = useState('')
	const [paymentResponse, setPaymentResponse] = useState<IInsurancePaymentResponse>(defaultValue)
	const { token, authUid, state } = useAppSelector(state => state.user)
	const isLoading = useAppSelector(selectPaymentLoading);
	const [isAvailable, setIsAvailable] = useState<boolean>(true);

	const { enableVideoCall, isEnableVideoCall, setVideoCallText } = useGetCareNow();

	const [paymentError, setPaymentError] = useState<boolean>(false)

	const { handleChatWithDoc, currentChatStatus, closeModalChatStatus, openChat } = useChatProvider();

	const { isSafari } = useBrowser();

	const assistancePhone = appConfig.CALL_NUMBER;
	const prefix = appConfig.CALL_NUMBER_PREFIX;

	const handleSymptomChecker = () => {
		dispatch(shutdown());
		if (isVAOpen || isHLOpen) dispatch(closeSymptomChecker());
		if ((!isChatOpen && !chatState.isInitialized) || isVAOpen) {
			dispatch(openSymptomChecker({
				isOpen: true,
				isPublic: false,
				lng: language,
				userInfo: {
					dob: user.birthdate,
					gender: user.sex,
					memberid: user.idEcw,
					organization_user_id: user.idEcw,
				},
			}));
		} else {
			dispatch(showChat());
		}
	}

	const handleTelevisit = () => {

		dispatch(initPayment({
			authUid,
			state: state.toUpperCase(),
			token,
			service: SERVICES_TYPES.VIDEO_CALL,
			english: language === 'en'
		}))
			.unwrap()
			.then((response: IInsurancePaymentResponse) => {

				if (response.code === PaymentCode.transaction_completed) {
					openSafeUrl(linkToTelevisit)
					return;
				}

				setPaymentResponse(response)

			})

	};

	const closeModal = () => {
		setPaymentError(false);
		setPaymentResponse(defaultValue)
	};



	const triggerPatientSupport = useCallback(async () => {
		openChat({ queue: 'support' })
		closeModal();
		closeModalChatStatus();
	}, [dispatch]);

	const createTransactionBase = async () => {

		dispatch(createNewTransactionBase({
			authUid,
			state: state.toUpperCase(),
			token,
			service: SERVICES_TYPES.VIDEO_CALL,
			english: language === 'en'
		}))
			.unwrap()
			.then((response: IInsurancePaymentResponse) => {

				setPaymentResponse({
					...paymentResponse,
					transactionId: response.id,
					code: PaymentCode.modal_televisit_pay
				})

			})

	};
	const createTransactionBaseOnly = async () => {

		dispatch(createNewTransactionBase({
			authUid,
			state: state.toUpperCase(),
			token,
			service: SERVICES_TYPES.VIDEO_CALL,
			english: language === 'en'
		}))
			.unwrap()
			.then((response: IInsurancePaymentResponse) => {

			})

	};

	const createNewTransaction = async (newTransaction: boolean) => {

		if (paymentResponse.code === PaymentCode.transaction_in_process) {

			const sessionUrl = paymentResponse.otherValues?.find(
				(value: OtherValue) => value.variable === VARIABLE_TYPES.SESSION_URL
			);

			if (!sessionUrl?.value) {

				dispatch(cancelUserTransaction({
					authUid,
					state,
					service: SERVICES_TYPES.VIDEO_CALL
				}))
					.unwrap()
					.then((response: any) => {
						createTransactionBase();
					}).catch((error: any) => { })

				return;
			}

			const sessionId = paymentResponse.otherValues?.find(
				(value: OtherValue) => value.variable === VARIABLE_TYPES.SESSION_ID
			);

			if (!sessionId?.value) return;

			setSessionId(sessionId.value)

			validateSessionStatus(newTransaction, sessionId.value);

			return;

		}

		const finalDate = new Date(paymentResponse.transactionCreatedDate);
		finalDate.setMinutes(finalDate.getMinutes() + EXPIRATION_TIMES.PAYMENT_CREATED)

		const currentDate = new Date();

		if (currentDate > finalDate || newTransaction) closeTransaction();

		setPaymentResponse({
			...paymentResponse,
			code: PaymentCode.modal_televisit_pay
		})

	};

	const validateSessionStatus = (newTransaction: boolean, sessionId: any) => {
		dispatch(checkTransactionStatus(sessionId))
			.unwrap()
			.then((response: ISessionInformation) => {

				if (newTransaction && response.transactionInformation.status !== SESSION_STATUS.IN_PROCESS) {
					setPaymentResponse({
						...paymentResponse,
						code: PaymentCode.cant_continue_with_a_new_payment
					})
					return;
				} else if (!newTransaction && response.transactionInformation.status !== SESSION_STATUS.IN_PROCESS) {
					replace(`/${MAIN.getCare}/${GET_CARE.paymentStatus}?${VARIABLE_TYPES.SESSION}=${sessionId}`)
					return;
				}

				if (newTransaction) closeTransaction();

				setPaymentResponse({
					...paymentResponse,
					code: PaymentCode.modal_televisit_pay
				})

			})
	}

	const closeTransaction = () => {

		dispatch(cancelUserTransaction({
			authUid,
			state: state.toUpperCase(),
			service: SERVICES_TYPES.VIDEO_CALL
		}))
			.unwrap()
			.then((response: any) => {
				handleTelevisit();
			}).catch((error: any) => { })

		return;
	}
	const handleNoCopaymentValue = () => {
		createTransactionBaseOnly();
		openSafeUrl(linkToTelevisit);
		closeModal();
		return;

	}

	const isModalDisplayed = (paymentCode: PaymentCode): boolean => !isLoading && paymentResponse && paymentResponse.code === paymentCode

	useEffect(() => {
		const callTelevisit = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.HANDLE_TELEVISIT);

		const isEnable = isEnableVideoCall();

		if (callTelevisit === 'true' && isEnable) {
			KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.HANDLE_TELEVISIT);
			handleTelevisit();
		};

		KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.HANDLE_TELEVISIT);
		KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.PAYMENT_IN_PROGRESS)
	}, [])

	useEffect(() => {
		if (!isActiveCoverage) {
			const fetchValidation = async () => {
				const hintStatus = await AuthRepository.hintValidation(user?.authUid, user.state)
				setIsHint(hintStatus)
			}
			fetchValidation()
				.catch(console.error);
		}
	}, [])



	const diferenceTimeByZone = [
		{
			state: 'FL',
			diference: -4
		},
		{
			state: 'TN',
			diference: -5
		},
	];



	const options: GetCareNowOptions[] = [
		{
			name: "chat_with_professional",
			card: {
				web: (
					<GetCareNowButton color="light" squareRadius={true} onClick={handleChatWithDoc}>
						<span>
							<Image
								src={userChatIcon}
								width={sizesUserChatIcon.width}
								height={sizesUserChatIcon.height}
								alt={t('imgNicons.chatDoctorIcon')}
							/>
							<KeraltyText
								type="title"
								fontWeight={'400'}
								style={{ paddingBottom: 10, fontWeight: 'normal' }}
								noTextTransform
							>
								{t('button.chatWithaDoctor')}
							</KeraltyText>
							<KeraltyText type="paragraph2" fontWeight={'600'}>{t('button.chatWithaDoctorText')}</KeraltyText>
						</span>
					</GetCareNowButton>
				),
				mobile: (
					<CenteredWrapper style={enableVideoCall ? {} : { width: '95%' }}>
						<Card onClick={handleChatWithDoc}>
							<SectionWidget
								img={userChatIcon}
								text={t('button.chatWithaDoctor')}
								description={capitalizeJustFirst(t('button.chatWithaDoctorText'))}
								alt={t('imgNicons.chatDoctorIcon')}
							/>
						</Card>
					</CenteredWrapper>
				)
			},
			enabled: user.state !== 'TN'
		},
		{
			name: "videocall_with_professional",
			card:  {
				web: (
					<>
						{
							user?.state === "FL" || enableVideoCall
							? (
								<span onClick={() => enableVideoCall ? handleTelevisit() : {}}>
									<GetCareNowButton color="light" squareRadius={true}>
										<span>
											<Image
												src={laptopMedical}
												width={sizesLaptopMedicalIcon.width}
												height={sizesLaptopMedicalIcon.height}
											/>
											<KeraltyText
												type="title"
												fontWeight={'400'}
												style={{ paddingBottom: 10, fontWeight: '700' }}
												noTextTransform
											>
												{disableToTN(user?.state) ? t('button.televisitTN') :t('button.televisit')}
											</KeraltyText>
											<KeraltyText
												type="paragraph2"
												fontWeight={'600'}
											>
												{
													user.state !== 'TN' ?
														t('button.televisitText')
													: setVideoCallText(t('button.televisitTextV2'))
												}
											</KeraltyText>
										</span>
									</GetCareNowButton>
								</span>
							)
							: <CardDisabled />
						}
					</>
				),
				mobile: (
					<>
						{
							user?.state === "FL" || enableVideoCall
							? (
								<CenteredWrapper>
									<Card onClick={() => enableVideoCall ? handleTelevisit() : {}}>
										<SectionWidget
											img={laptopMedical}
											text={disableToTN(user?.state) ? t('button.televisitTN') :t('button.televisit')}
											description={user?.state === "FL"  ? t('button.televisitText') : (setVideoCallText(t('button.televisitTextV2')))}
											alt={'televisit'}
										/>
									</Card>
								</CenteredWrapper>
							)
							: (
								<CardDisabled />
							)
						}
					</>
				)
			},
			enabled: true
		},
		{
			name: "call_with_professional",
			card:  {
				web: (
					<div style={{ justifyContent: 'center', padding: '0px 1rem' }}>
						<a href={`tel:${prefix}${assistancePhone}`}>
							<GetCareNowButton color="light" squareRadius={true}>
								<span>
									<Image
										src={userCallIcon}
										width={sizesUserChatIcon.width}
										height={sizesUserChatIcon.height}
									/>
									<KeraltyText
										type="title"
										fontWeight={'400'}
										style={{ paddingBottom: 10, fontWeight: 'normal' }}
										noTextTransform
									>
										{t('button.callDoctor')}
									</KeraltyText>
									<KeraltyText type="paragraph2" fontWeight={'600'}>{t('button.callDoctorText')}</KeraltyText>
								</span>
							</GetCareNowButton>
						</a>
					</div>
				),
				mobile: (
					<CenteredWrapper style={enableVideoCall ? {} : { width: '95%' }}>
						<a href={`tel:${prefix}${assistancePhone}`} style={{ textDecoration: 'none', width: '100%', marginRight: 13 }}>
							<Card>
								<SectionWidget
									img={userCallIcon}
									text={t('button.callDoctor')}
									description={capitalizeJustFirst(t('button.callDoctorText'))}
									alt={'televisit'}
								/>
							</Card>
						</a>
					</CenteredWrapper>
				)
			},
			enabled: user.state !== 'TN'
		},
	];

	return (
		<>


			{isLoading && <Loader />}
			{/* Payment modals */}
			{isModalDisplayed(PaymentCode.modal_televisit_pay) && (
				<ModalTelevisitPay close={closeModal} paymentError={paymentError} setPaymentError={setPaymentError} paymentInfo={paymentResponse} />
			)}
			{isModalDisplayed(PaymentCode.modal_insurance_not_validated) && (
				<ModalInsuranceNoValidated
					close={closeModal}
					paymentInfo={paymentResponse}
					triggerPatientSupport={triggerPatientSupport}
					createTransactionBase={createTransactionBase}
				/>
			)}
			{isModalDisplayed(PaymentCode.modal_insurance_inactived) && (
				<ModalInsuranceInactived
					close={closeModal}
					paymentInfo={paymentResponse}
					triggerPatientSupport={triggerPatientSupport}
					createTransactionBase={createTransactionBase}
				/>
			)}
			{isModalDisplayed(PaymentCode.modal_pcp_no_validated) && (
				<ModalPCPNoSanitas
					close={closeModal}
					paymentInfo={paymentResponse}
					triggerPatientSupport={triggerPatientSupport}
					createTransactionBase={createTransactionBase}
				/>
			)}
			{isModalDisplayed(PaymentCode.previous_transaction) && (
				<ModalPreviousTransaction createNewTransacion={createNewTransaction} />
			)}
			{isModalDisplayed(PaymentCode.impossible_to_create_new_payment) && (
				<ModalTransactionProcess close={closeModal} />
			)}
			{isModalDisplayed(PaymentCode.transaction_in_process) && (
				<ModalPreviousTransaction createNewTransacion={createNewTransaction} />
			)}
			{isModalDisplayed(PaymentCode.cant_continue_with_a_new_payment) && (
				<ModalNoNewPayment sessionId={sessionId} />
			)}
			{isModalDisplayed(PaymentCode.unknown_value_co_insurance) && (
				<ModalNoCopayment handleNoCopaymentValue={handleNoCopaymentValue} close={closeModal} />
			)}

			{/* Chat with a Doctor modals */}

			<ModalInsuranceValidate
				currentChatStatus={currentChatStatus}
				closeModalChatStatus={closeModalChatStatus}
				externalAction={closeModal}
			/>

			<RightContent fullHeight>
				<IconLinkRow
					onClick={goBack}
					color="accent"
					icon={arrowAltCircleLeftIcon}
					iconAlt={t('imgNicons.backArrow')}
					text={t('routes.back')}
				/>
				<Row>
					<TitleContiner lg={12}>
						<KeraltyText align={isMobile ? 'left' : 'center'} type="title">
							{t('getCare.getCareNow')}
						</KeraltyText>
					</TitleContiner>
				</Row>

				{
					!isMobile
					? (
						<div style={{
							display: 'grid',
							placeContent: 'center',
							alignContent: 'normal',
							gridTemplateColumns: options.filter( option => Boolean(option.enabled) ).length > 1 ? '.1fr .1fr' : '.1fr',
							gridTemplateRows: isSafari ? 'auto auto 0fr' : 'auto auto auto'
						}}>
							{
								options.filter( option => Boolean(option.enabled) ).map((option, index) => (
									<div
										key={index}
										style={{
											gridColumn: index === 2 ? '1 / -1' : 'auto',
											textAlign: 'center'
										}}
									>
									{ option.card.web }
								</div>
							))}
						</div>
					)
					: (
						<>
							{
								options.filter( option => Boolean(option.enabled) ).map((option, index) => (
									<div
										key={index}
									>
									{ option.card.mobile }
								</div>
							))}
						</>
					)
				}

			{
				false
				&& (
					<>
						<Row style={{ padding: isMobile ? '0px' : '0px 1rem' }}>
							<Column
								lg={0}
								md={0}
								sm={12}
								style={{ display: 'flex', justifyContent: 'center', height: '11.5rem' }}
							>
								<CenteredWrapper>
									<Card onClick={handleChatWithDoc}>
										<SectionWidget
											img={userChatIcon}
											text={t('button.chatWithaDoctor')}
											description={capitalizeJustFirst(t('button.chatWithaDoctorText'))}
											alt={t('imgNicons.chatDoctorIcon')}
										/>
									</Card>
								</CenteredWrapper>

							</Column>

							<LeftColumn lg={6} md={6} sm={0}>
								<GetCareNowButton color="light" squareRadius={true} onClick={handleChatWithDoc}>
									<span>
										<Image
											src={userChatIcon}
											width={sizesUserChatIcon.width}
											height={sizesUserChatIcon.height}
											alt={t('imgNicons.chatDoctorIcon')}
										/>
										<KeraltyText
											type="title"
											fontWeight={'400'}
											style={{ paddingBottom: 10, fontWeight: 'normal' }}
											noTextTransform
										>
											{t('button.chatWithaDoctor')}
										</KeraltyText>
										<KeraltyText type="paragraph">{t('button.chatWithaDoctorText')}</KeraltyText>
									</span>
								</GetCareNowButton>
							</LeftColumn>
							<Column lg={0} md={0} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
								{!isAvailable && <CenteredWrapper>
									<CardTN>
										<NoteContentTN>
											<NoteTN isSafari={isSafari}>{t('button.schedule')}</NoteTN>
										</NoteContentTN>
										<SectionWidgetTN>
											<div
												style={{
													width: '18%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Image
													src={laptopMedical}
													width={2.8}
													height={2.3}
													alt={'televisit'}
												/>
											</div>
											<div style={{ width: '82%' }}>
												<KeraltyText
													align="left"
													type="title"
													fontWeight={'400'}
													color="#002D57"
													fontSize="15px"
													style={{ lineHeight: '19px', marginTop: 22 }}
												>
													{t('button.televisit')}
												</KeraltyText>
												<KeraltyText
													align="left"
													type="title"
													fontWeight={'600'}
													color="#747474"
													fontSize="12px"
													style={{
														lineHeight: '15px',
														marginTop: -12,
														width: language === 'es' ? '23rem' : '20rem',
													}}
												>
													{t('button.televisitText')}
												</KeraltyText>
											</div>
										</SectionWidgetTN>
									</CardTN>
								</CenteredWrapper>}
								{isAvailable && <CenteredWrapper>
									<Card onClick={() => handleTelevisit()}>
										<SectionWidget
											img={laptopMedical}
											text={disableToTN(user?.state) ? t('button.televisitTN') :t('button.televisit')}
											description={capitalizeJustFirst(t('button.televisitText'))}
											alt={'televisit'}
										/>
									</Card>
								</CenteredWrapper>}
							</Column>
							<RightColumn lg={6} md={6} sm={0} noPadding>
								{isAvailable && <span onClick={() => handleTelevisit()}>
									<GetCareNowButton color="light" squareRadius={true}>
										<span>
											<Image
												src={laptopMedical}
												width={sizesLaptopMedicalIcon.width}
												height={sizesLaptopMedicalIcon.height}
											/>
											<KeraltyText
												type="title"
												fontWeight={'400'}
												style={{ paddingBottom: 10, fontWeight: '400' }}
												noTextTransform
											>
												{disableToTN(user?.state) ? t('button.televisitTN') :t('button.televisit')}
											</KeraltyText>
											<KeraltyText type="paragraph">{t('button.televisitText')}</KeraltyText>
										</span>
									</GetCareNowButton>
								</span>}
								{/* Time zone card */}
								{!isAvailable && <DisabledGetCareSchedule
									title={t('button.televisit')}
									subtitle={t('button.televisitText')}
									Image={laptopMedical}
								/>}
							</RightColumn>
						</Row>
						{user.state != 'TN' ? (
							<Column lg={0} md={0} sm={12}>
								<CenteredWrapper>
									<a
										href={`tel:${prefix}${assistancePhone}`}
										style={{ textDecoration: 'none', width: '100%', marginRight: 13 }}
									>
										<Card>
											<SectionWidget
												img={userCallIcon}
												text={t('button.callDoctor')}
												description={capitalizeJustFirst(t('button.callDoctorText'))}
												alt={'televisit'}
											/>
										</Card>
									</a>
								</CenteredWrapper>
							</Column>
						) : null}
						{window.innerWidth >= 600 && user.state != 'TN' ? (
							<div style={{ justifyContent: 'center', padding: '0px 1rem' }}>
								<a href={`tel:${prefix}${assistancePhone}`}>
									<GetCareNowButton color="light" squareRadius={true}>
										<span>
											<Image
												src={userCallIcon}
												width={sizesUserChatIcon.width}
												height={sizesUserChatIcon.height}
											/>
											<KeraltyText
												type="title"
												fontWeight={'400'}
												style={{ paddingBottom: 10, fontWeight: 'normal' }}
												noTextTransform
											>
												{t('button.callDoctor')}
											</KeraltyText>
											<KeraltyText type="paragraph">{t('button.callDoctorText')}</KeraltyText>
										</span>
									</GetCareNowButton>
								</a>
							</div>
						) : null}
					</>
				)
			}
			</RightContent>
		</>
	);
};

export default GetCareNowPage;


const CardContainer = styled.div`
  position: relative;
  width: 350px;
  padding: 0px 20px 20px 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  min-height: 7rem;
`;

const BackgroundIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.2;
  img {
    width: 150px;
    filter: blur(8px);
  }
`;

const SubText = styled.p`
  font-size: 14px;
  color: #757575;
  text-align: start;
  strong {
    color: #757575;
    font-weight: bold;
  };
  padding-bottom: 0;
  font-weight: 700;
  font-size: 16;
`;


const ResponsiveCardContainer = styled.div`
  position: relative;
  width: 93%;
  padding: 0px 5px 5px 5px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  text-align: center;
  overflow: hidden;
`;

const ResponsiveBackgroundIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.2;

  img {
    width: 150px;
    filter: blur(8px);
  }
`;

const ResponsiveContent = styled.div`
  position: relative;
  z-index: 1;
  color: #333;
`;

const ResponsiveMainText = styled.p`
  font-size: 18px;
  color: #0056b3;
  font-weight: bold;
  margin-bottom: 0px;
`;

const SubTextContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PhoneIcon = styled.img`
  width: 20px;
  10px
`;

const CardDisabled = () => {

	const isMobile = useIsMobile();
	const { t } = useTranslation();
	const { user } = useAuth();
	const assistancePhone = appConfig.CALL_NUMBER;

	const { setVideoCallText } = useGetCareNow();

	return (
		<>
		{
			!isMobile
			? (
				<CardContainer>
					<span style={{position: 'absolute', height: 25, width: 400, left: 20, alignContent: 'center', color: "#FFFFFF" }}>{setVideoCallText(t('button.televisitTextV3'))}</span>
					<div>
						<svg width="400" height="25" viewBox="0 0 344 25" fill="none">
							<path d="M0 0H21V25V25C18.2672 25 15.7157 23.6326 14.2024 21.357L0 0Z" fill="#40A100"/>
							<path d="M21 0H339C341.761 0 344 2.23858 344 5V25H21V0Z" fill="#40A100"/>
						</svg>
					</div>
					<BackgroundIcon>
						<img src={laptopMedical} alt="computer icon" />
					</BackgroundIcon>
					<>
						<KeraltyText
							type="title"
							fontWeight={'600'}
							style={{ paddingBottom: 0, paddingTop: 0, fontWeight: '600', fontSize: 22, lineHeight: 1, marginBottom: 0 }}
							noTextTransform
						>
							{t('button.sorryTelevisit')}
						</KeraltyText>
						<SubTextContainer>
							<PhoneIcon src={TelephoneIcon} />
							<SubText
								style={{ fontWeight: '600', fontSize: 16, color: "#757575" }}
							>
								{t('button.sorryTelevisitPartTwo')} <strong>{assistancePhone}</strong>
							</SubText>
						</SubTextContainer>
					</>
				</CardContainer>
			)
			: (
				<ResponsiveCardContainer>
					<span style={{position: 'absolute', height: 25, width: 400, left: 25, alignContent: 'center', color: "#FFFFFF", fontSize: 11 }}>{setVideoCallText(t('button.televisitTextV3'))}</span>
					<div>
						<svg width="400" height="25" viewBox="-10 0 324 25" fill="none">
							<path d="M0 0H21V25V25C18.2672 25 15.7157 23.6326 14.2024 21.357L0 0Z" fill="#40A100"/>
							<path d="M21 0H339C341.761 0 344 2.23858 344 5V25H21V0Z" fill="#40A100"/>
						</svg>
					</div>
					<ResponsiveBackgroundIcon>
						<img src={laptopMedical} alt="Ícono de computador" width={90} height={90} />
					</ResponsiveBackgroundIcon>
					<ResponsiveContent>
						<ResponsiveMainText
							style={{ fontWeight: '600', fontSize: 20 }}
						>
							{t('button.sorryTelevisit')}
						</ResponsiveMainText>
						<SubTextContainer style={{ alignItems: "center", justifyContent: "center", marginRight: '20px', marginLeft: '20px' }}>
							<PhoneIcon src={TelephoneIcon} />
							<SubText
								style={{ fontWeight: '600', fontSize: 14, color: "#757575" }}
							>
								{t('button.sorryTelevisitPartTwo')} <strong>{assistancePhone}</strong>
							</SubText>
						</SubTextContainer>
					</ResponsiveContent>
				</ResponsiveCardContainer>
			)
		}
		</>
	);
};
